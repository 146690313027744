import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, useIonLoading, IonSelect, IonSelectOption, IonSpinner, IonGrid  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, mapSharp, trash } from 'ionicons/icons';
import axios from 'axios';
import Modal from './ModalDev';
import Conexio from '../services/Index';
import GeolocationButton from '../components/GeolocationButton';

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();
  let cita = window.localStorage.getItem("cita");
  const [ncita, seCita] = useState(cita);


  const [foto, setFoto] = useState("");
  const [resumen, setResumen] = useState("");
  const [CroquisI, setCroquisI] = useState("");
  const [CroquisD, setCroquisD] = useState("");
  const [CroquisS, setCroquisS] = useState("");
  const [CroquisF, setCroquisF] = useState("");
  const [CroquisA, setCroquisA] = useState("");

  const [Extintor, setExtintor] = useState("");
  const [Ukm, setUltKm] = useState("");

  const [Lista, setLista] = useState<any[]>([]);


  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;
  //let uri = 'http://127.0.0.1:8000/api/';
  //http://127.0.0.1:8000 

  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }


  async function Entregas() {
      window.location.href = '/devoluciones';
  }
  async function TomarFotos() {
    window.location.href = '/foto_dev';
}
async function VerImagenes() {
  window.location.href = '/imagenes';
}
function DetalleCita(){
  var usuario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  var cita = window.localStorage.getItem('cita');
  const datos = { cita: cita };
  axios.post(uri+'appmovil/resumen',datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        var d = res.data.lista;
        var foto = res.data.foto;
        var extintor = res.data.extintor;
        var r = res.data.resumen;
        var acta = res.data.acta;
        console.log(acta[0].obs_imagen_izquierdo_dev);
        setLista(d);
        setFoto(foto);
        setExtintor(extintor);
        setResumen(r);
        setCroquisI(acta[0].obs_imagen_izquierdo_dev)
        setCroquisA(acta[0].obs_imagen_atras_dev)
        setCroquisD(acta[0].obs_imagen_derecho_dev)
        setCroquisF(acta[0].obs_imagen_frente_dev)
        setCroquisS(acta[0].obs_imagen_superior_dev)
    
       
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
}

async function Principal() {
  window.location.href = '/home';
}

async function Entrega() {
  window.localStorage.setItem('acta','DEVOLUCION');
  window.localStorage.setItem('odo',Ukm);
  window.location.href = '/actaDev';

}
function Salir() {

  window.location.href = "/";
}
function Listaentrega() {

  window.location.href = "/citas";
}
function Listadev() {

  window.location.href = "/devoluciones";
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
function loading(){
  present({
    message: 'Enviando...',
    duration: 3000,
    cssClass: 'custom-loading'
  })
}
useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
const [present] = useIonLoading();
  return (
    <IonPage>
     
     <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           <IonImg
                className="rounded-pill"
                onClick={Entregas}
                src={require("../assets/images/success.png")}
              ></IonImg>
            RESUME DE DEVOLUCIÓN
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listadev}>
                    Lista de devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
      <GeolocationButton />
      <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/info.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Novedades:</b>
                
                </div>
                <div className="toast-body  text-dark">
                {Lista.map((item,i) => (
                 item.respuesta_entrega!=item.respuesta_devolucion?
                 <>
                 <IonGrid key={item.id}>
                 
                    <IonLabel>
                    <b>{item.nombre_parte} </b>
                    <h5>Entregado : {item.respuesta_entrega} </h5>
                    <b>Observaciones : </b>{item.obs_entrega} 
                    <h5>Devuelto : {item.respuesta_devolucion} </h5>
                    <b>Observaciones : </b>{item.obs_devolucion} 
                  </IonLabel>
                
                </IonGrid>
               
                 </>
              
          :''
       
          ))}
          <hr></hr>
          <IonGrid>
            <b>{resumen}</b>

          
            </IonGrid>
            <hr></hr>
            <IonGrid>
            <b>{Extintor}</b>
            {foto==""?
                <b>Extintor devuelto sin ninguna novedad.</b>:
                 <IonImg src={foto}></IonImg>
                }
            </IonGrid>
            <hr></hr>
            <b>Croquis</b>
            <IonGrid >
                    <IonLabel>
                    <b>Obs. Lado Izquierdo :</b> {CroquisI} 
                  </IonLabel>
                </IonGrid>
                <IonGrid >
                    <IonLabel>
                    <b>Obs. Lado Atras :</b> {CroquisA} 
                  </IonLabel>
                </IonGrid>
                <IonGrid >
                    <IonLabel>
                    <b>Obs. Lado Derecho :</b> {CroquisD} 
                  </IonLabel>
                </IonGrid>
                <IonGrid >
                    <IonLabel>
                    <b>Obs. Lado Frente :</b> {CroquisF} 
                  </IonLabel>
                </IonGrid>
                <IonGrid >
                    <IonLabel>
                    <b>Obs. Lado Superior :</b> {CroquisS} 
                  </IonLabel>
                </IonGrid>
            
                </div>
               
              </div>
            </div>
       
          </div>
          <br />
  
       
      </IonContent>
      <IonFooter className="ion-text-center">
        <IonButton className="boton" onClick={Principal}>
          <IonIcon icon={home} />
        </IonButton>
      </IonFooter>

    </IonPage>
  );
};

export default Home;
