import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer } from 'ionicons/icons';
import axios from 'axios';
import Modal from './Modal';
import Conexio from '../services/Index';

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();
  let cita = window.localStorage.getItem("cita");
  const [ncita, seCita] = useState(cita);
  const [isiniestro, setSin] = useState("");
  const [iaseguradora, setAseg] = useState("");
  const [iasegurado, setAse] = useState("");
  const [ideclarante, setDec] = useState("");
  const [iplaca, setPla] = useState("");
  const [ioficina, setOfi] = useState("");
  const [ivehiculo, setVeh] = useState("");
  const [iagendado, setAge] = useState("");
  const [idias, setDia] = useState("");
  const [isin, setIdSin] = useState("");
  const [ioperario, setOperario] = useState("");
  const [NombreOperario, setNameOperario] = useState("");
  const [showModal2, setShowModal2] = useState(false);

  const [Imagen1, setImg1] = useState("");
  const [Imagen2, setImg2] = useState("");
  const [Imagen3, setImg3] = useState("");
  const [Imagen4, setImg4] = useState("");
  const [Imagen5, setImg5] = useState("");
  const [Imagen6, setImg6] = useState("");
  const [Imagen7, setImg7] = useState("");

  const [kmi, setKmi] = useState("");
  const [tpq, setTpq] = useState("0");
  const [kmd, setKmd] = useState("0");
  const [Observaciones, setObskm] = useState("");

  const [flota, setFlota] = useState("");
  const [Ukm, setUltKm] = useState("");
  const [Obs, setObs] = useState("");
  const [Estado, setEstado] = useState("");

  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;
  //let uri = 'http://127.0.0.1:8000/api/';
  //http://127.0.0.1:8000 

  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }


  async function Entregas() {
      window.location.href = '/detalle_dev';
  }
  async function TomarFotos() {
    window.location.href = '/foto_dev';
}
async function VerImagenes() {
  window.location.href = '/foto_dev';
}
function DetalleCita(){
  var orden = window.localStorage.getItem('cita');
  var token = window.localStorage.getItem('key_t');
  axios({
    method: "get",
    url: uri + "appmovil/ver/"+orden,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
         let d = res.data.citas;
         let v = res.data.vehiculo;
         let link = res.data.url;
          console.log(res.data);
          
          setImg1(link+d.img_odo_salida_f); 
          setAse(d.asegurado_nombre); 
          setDec(d.declarante_nombre); 
          setPla(d.placa); 
          setOfi(d.oficina); 
          setVeh(d.placaasig); 
          setAge(d.agendada_por); 
          setDia(d.dias_servicio); 
          setFlota(d.flota); 
          setUltKm(v.ultimokm); 
          setObs(d.obs_devolucion); 
          setNameOperario(d.nombreoperario)
          setOperario(d.operario_domicilio)
          setEstado(d.estadocita)

          setImg1(link+d.img_odo_salida_f); 
          setImg2(link+d.img_inv_salida_f); 
          setImg3(link+d.fotovh1_f); 
          setImg4(link+d.fotovh2_f); 
          setImg5(link+d.fotovh3_f); 
          setImg6(link+d.fotovh4_f); 
          setImg7(link+d.img_contrato_f); 
          window.localStorage.setItem('placa',d.placaasig);
         // document.getElementById("obs").innerHTML = d.obs_devolucion;
          
          var asi = d.operario_domicilio;
          var est = d.estadocita;

          var btn = '<button class="btn btn-primary btn-block" onclick="asignar()">Asignar Operacio</button>';
          if(asi == 0){
            //document.getElementById("boton_asignar").innerHTML = btn;
          }else{
            var btn2 = 'La cita de entrega esta concluida <br><a href="" class="btn btn-danger btn-block">Toma de Imagenes</a>';
            if(est == 'P'){
              var btn2 = '<button class="btn btn-info btn-block"   data-toggle="modal" data-target="#modal-km" role="button">Registrar Observaciones</button>';
            }
           
            //document.getElementById("boton_asignar").innerHTML ='<div class="text-center">Operario:'+ d.nombreoperario+' <br>'+btn2+'</div>';
          }
          var dom = d.dir_domicilio;
          if(dom!=''){
            //document.getElementById('desplazamiento').style.display = 'block';
          }
          
        }
      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });
}
async function Principal() {
  window.location.href = '/home';
}
function Asignar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  axios({
    method: "get",
    url: uri + "appmovil/asignar_devolucion/"+orden+"/"+operario,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.Msj,
            buttons: ['OK'],
          })
          DetalleCita();
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function save_registro(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');

  if(kmi==''){
    alert("Debes de digitar el kilimetraje inicial");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmi)) {
     alert("Debe escribir un kilometraje inicial valido igual o mayor que el ultimo registrado. No puede ser menor ni mayor que 2 kilometros mas del ultimo registrado en la tabla de control.");
     return false;
  }
  if(Observaciones==''){
    alert("Debes de escribir alguna observacion ");
    return false;
  }
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd,tpq:tpq,observaciones:Observaciones,kilometro:Ukm };
  axios.post(uri+'appmovil/saveentrega', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Datos registrado',
          message: res.data.msj,
          buttons: ['OK'],
        })
        DetalleCita();
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}

useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className='header ion-text-center'>
        <IonButton  className='boton' onClick={Entregas} slot="start"><IonIcon icon={arrowBack} /></IonButton>
          <IonTitle>Imagenes de Entrega</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >

        <IonContent fullscreen className='ion-padding body'>
       
          <IonHeader>
          <IonToolbar className='subheader ion-text-center'>
            <IonTitle><IonIcon icon={informationCircle} /> ODOMETRO</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">

          <IonCardContent >
                   {Imagen1? <IonImg src={Imagen1}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
                
              </IonCardContent>
              </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> ACTA DE ENTREGA </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen2? <IonImg src={Imagen2}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> FRENTE VEHICULO </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen3? <IonImg src={Imagen3}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> ATRAS VEHICULO </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen4? <IonImg src={Imagen4}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> IZQUIERDA VEHICULO</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen5? <IonImg src={Imagen5}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> DERECHA VEHICULO </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen6? <IonImg src={Imagen6}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>
          <IonHeader>
            <IonToolbar className='subheader ion-text-center'>
                  <IonTitle><IonIcon icon={informationCircle} /> CONTRATO </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard color="light">
             <IonCardContent >
             {Imagen7? <IonImg src={Imagen7}></IonImg>:<IonSkeletonText animated={true} style={{ 'width': '100%', 'height': '50px'}}></IonSkeletonText>}
             </IonCardContent>
          </IonCard>

        </IonContent>

       
      </IonContent>
      <IonFooter>
        <IonToolbar className='header ion-text-center'>
        <IonButton  className='boton' onClick={Principal} ><IonIcon icon={home} /></IonButton>
        </IonToolbar>
      </IonFooter>
     

    </IonPage>
  );
};

export default Home;
