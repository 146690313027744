import { Geolocation, Geoposition } from '@ionic-native/geolocation';
import { IonButton, IonLoading, IonToast, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';

interface LocationError {
    showError: boolean;
    message?: string;
}

const GeolocationButton: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<LocationError>({ showError: false });
    const [position, setPosition] = useState<Geoposition>();

    const getLocation = async () => {
        setLoading(true);

            const position = await Geolocation.getCurrentPosition();
            console.log(position.coords.latitude);
            var lon : any = position.coords.longitude;
            var lat : any = position.coords.latitude;
            setPosition(position);
            setLoading(false);
            setError({ showError: false });
            window.localStorage.setItem('lon',lon);
            window.localStorage.setItem('lat',lat);
       
    }
    useIonViewWillEnter(() => {
      getLocation();
    });
    return (
        <>
           
            <IonToast
                isOpen={error.showError}
                onDidDismiss={() => setError({ message: "", showError: false })}
                message={error.message}
                duration={3000}
            />

        </>
    );
};

export default GeolocationButton;