import {
  IonContent,
  useIonAlert,
  IonSelectOption,
  IonHeader,
  IonPage,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonViewWillEnter,
  IonAvatar,
  IonItemSliding,
  IonList,
  IonSkeletonText,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonCol,
  IonRow,
  IonRippleEffect,
  IonSelect,
  IonSpinner,
} from "@ionic/react";
import Swal from "sweetalert2";
import "./Cita.css";
import { useState } from "react";
import {
  today,
  calendar,
  eye,
  search,
  people,
  print,
  home,
  arrowBackSharp,
  arrowForward,
  hourglass,
  time,
  car,
  locate,
  locationSharp,
  logOut,
  man,
  trailSign,
  checkboxSharp,
  pencilOutline,
  warningSharp,
  mail,
  printOutline,
} from "ionicons/icons";
import { userInfo } from "os";
import Home from "../pages/Home";
import { citas } from "../components/model/citas";
import axios from "axios";
import Conexio from "../services/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

const Cita: React.FC = () => {
  let n = window.localStorage.getItem("name_u");
  let o = window.localStorage.getItem("o");
  let ope = window.localStorage.getItem("key");
  let tk = window.localStorage.getItem("key_t");
  const [Cargando, setCargando] = useState<any>("0");

  const [presentAlert] = useIonAlert();
  const [Cita, setCita] = useState('0');
  const [operario, setOperario] = useState(ope);
  //let uri = 'https://pot.aoacolombia.com/api/';
  let uri = Conexio;
  const [listCitas, setlistCitas] = useState<citas[]>([]);
  const [listCitasDev, setlistCitasDev] = useState<citas[]>([]);
  const [Oficinas, setSucursales] = useState<any[]>([]);
  const [fechah, setFecha] = useState<string>(hoy);
  const [Placa, setPlaca] = useState<string>("");
  const [Asegurado, setAsegurado] = useState<string>("");
  const [ofice, setOficina] = useState(o);
  const [Tipo, setTipo] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [TipoCorreo, setTipoCorreo] = useState("");
  const [IdCorreo, setIdCorreo] = useState("");
  const [Correo, setCorreo] = useState("");
  const [NombreCorreo, setNombreCorreo] = useState("Cargando Datos....");

  async function Principal() {
    window.location.href = "/home";
  }
  async function funza() {
    window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
  }
  async function bogota() {
    window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function Devoluciones() {
    window.location.href = "/devoluciones";
  }
  async function Entregas() {
    window.location.href = "/citas";
  }
  async function Imprimir(acta:String, imp:String) {

    if(imp=='1'){
      const urld = "https://documentos2024-aoa.s3.bhs.io.cloud.ovh.net/"+acta;
      window.open(urld, "_blank");
    }else{
      const urld = "https://app.aoacolombia.com/Control/operativo/"+acta;
      window.open(urld, "_blank");
    }
    
    
  }
  async function getCitas() {
    let o = window.localStorage.getItem("key");
    const datos = { fecha: fechah,placa: Placa,asegurado:Asegurado, oficina: ofice, cliente: "", ope: o };
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/actas_firmadas",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        
        setLoaded(1);
        if (res.status == 200) {
          console.log('Datos cargados.');
          console.log(res.data.datos);
          var est = res.data.estado;
          if (est == 0) {
            window.location.href = "/";
          }
          setlistCitas(res.data.datos);
          setlistCitasDev(res.data.datosd);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  async function ReenviarCorreo() {
    let o = window.localStorage.getItem("o");
    const datos = { id:IdCorreo,correo:Correo,tipo:TipoCorreo,cita:Cita};
    setCargando(1);
    axios({
      method: "post",
      url: uri + "appmovil/upcorreo",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res);

        if (res.status == 200) {
          console.log(res.data.estado);
          alert(res.data.Msj);
          setShowModal(false);
            setCorreo("");
            setNombreCorreo("");
            setIdCorreo("");
            setCargando(0);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }

  async function sucursales() {
    let o = window.localStorage.getItem("o");
    const datos = { oficina: o };
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/sucursales",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res);

        if (res.status == 200) {
          console.log(res.data.estado);
          var est = res.data.estado;
          if (est == 0) {
            window.location.href = "/";
          }
          setSucursales(res.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  async function Correos(id: String, tp: String) {
    let o = window.localStorage.getItem("o");
    const datos = { oficina: o };
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "get",
      url: uri + "appmovil/correos/"+id,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res.data);
        if (res.status == 200) {
 
          var ase = res.data.asegurado;
          var aut = res.data.autorizacion;
          var con = res.data.conductor;
          if(tp=='S'){
            setCorreo(ase.correo);
            setNombreCorreo(ase.nombre);
            setIdCorreo(ase.id);
          }
          if(tp=='A'){
            setCorreo(aut.correo);
            setNombreCorreo(aut.nombre);
            setIdCorreo(aut.id);
          }
          if(tp=='C'){
            setCorreo(con.correo);
            setNombreCorreo(con.nombre);
            setIdCorreo(con.id);
          }
       
          //setSucursales(res.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  function verCita(idS: string,tipo: string) {
    if(idS=='0'){
      alert('No puedes ingresar a esta cita');
      return false;
    }
    setShowModal(true);
    setTipoCorreo(tipo);
    setCita(idS);
    Correos(idS,tipo);
    console.log(idS);

  }
  function Salir() {
    //localStorage.clear();
    window.location.href = "/";
  }
  useIonViewWillEnter(() => {
    getCitas();
    sucursales();
    if (!tk) {
      window.location.href = "/";
    }
  });
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={Principal}>
              <IonImg
                className="rounded-pill"
                style={{ width: 80 }}
                src={require("../assets/images/logomarca.png")}
              ></IonImg>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Entregas}>
                    Lista de Entregas
                  </a>
                </li>
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Devoluciones}>
                    Lista de Devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        <p>
          <div className="d-grid">
            <button
              className="btn btn-primary  btn-block"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-controls="collapseExample"
            >
              Filtrar Citas Firmadas
            </button>
          </div>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
          <IonRow>
            <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  placeholder="Nombre Asegurado"
                  name="asegurado"
                  id="asegurado"
                  value={Asegurado}
                  onIonChange={(e) => setAsegurado(e.detail.value!)}
                ></IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  placeholder="Placa"
                  name="placa"
                  id="placa"
                  value={Placa}
                  onIonChange={(e) => setPlaca(e.detail.value!)}
                ></IonInput>
              </IonCol>
              <IonCol>
                <IonInput
                  type="date"
                  className="form-control"
                  name="fecha"
                  id="fecha"
                  value={fechah}
                  onIonChange={(e) => setFecha(e.detail.value!)}
                ></IonInput>
              </IonCol>

              <IonCol>
                <IonSelect
                  className="form-control"
                  value={ofice}
                  onIonChange={(e) => setOficina(e.detail.value!)}
                  placeholder="Seleccione la Sucursal"
                >
                  <IonSelectOption value={""}>
                    Seleccione la sucursal
                  </IonSelectOption>
                  {Oficinas.map((item, i) => (
                    <IonSelectOption key={i} value={item.id}>
                      {item.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
              
            </IonRow>
            <IonRow>
            <IonCol>
                <IonSelect
                  className="form-control"
                  defaultValue={Tipo}
                  onIonChange={(e) => setTipo(e.detail.value!)}
                  placeholder="Tipo de entrega"
                >
                  <IonSelectOption value={0}>
                    Todas
                  </IonSelectOption>
                  <IonSelectOption value={1}>
                    Entregas
                  </IonSelectOption>
                  <IonSelectOption value={2}>
                    Devoluciones
                  </IonSelectOption>
                  
                </IonSelect>
              </IonCol>
            </IonRow>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={getCitas}
              >
                Buscar Cita
              </button>
            </div>
          </div>
        </div>
        {Tipo == 1 || Tipo == 0 ?
          <>
        <h3>ENTREGAS</h3>
          <IonList>
         
            {listCitas.map((citas, i) => (
          
                <IonItem  className={citas.acta_entrega=="" ? "FondoItemNo":"FondoItem"} key={i}>
                  <IonLabel>
                    <div className="ion-activatable ripple-parent">
                      <h3>
                        <b className="label">{i + 1}</b> Conductor:{" "}
                        {citas.conductor}{" "}
                      </h3>
                      <p>
                        {" "}
                        <b>
                          <IonLabel color="primary">
                            <IonIcon icon={car} /> Vehiculo: {citas.placaasig} | Siniestro: {citas.sini}
                          </IonLabel>
                        </b>{" "}
                        
                      </p>
                  
                      <p>
                        <IonIcon icon={today} /> Fecha: <b>{citas.fecha}</b>
                      </p>
                      <p>
                        <IonIcon icon={time} /> Hora: <b>{citas.hora}</b>
                      </p>
                       {citas.oper!=""?
                      <p>
                        <IonIcon icon={man} />  Operario: <b>{citas.oper}</b>
                      </p>
                      :""} 
                      <p>
                        <IonIcon icon={today} /> Devolucion: <b>{citas.fec_devolucion}</b> 
                      </p>
                      <p>
                         <IonIcon icon={time} /> Hora Devolucion: <b>{citas.hora_devol}</b>
                      </p>
                      {citas.dir_domicilio ? (
                        <IonLabel color="success">
                          Domicilio:{citas.dir_domicilio}
                        </IonLabel>
                      ) : (
                        ""
                      )}
                      
                       <div>
                       
                        <b>============ENTREGA**=================</b>
                        {citas.acta == '1' ? <>
                        <p  onClick={() => verCita(citas.cita,'S')}> 
                          <IonIcon icon={pencilOutline}/>Firma Asegurado: <IonIcon icon={citas.firma_entrega_asegurado==null?warningSharp:checkboxSharp} color={citas.firma_entrega_asegurado==null?"danger":"success"}/> {citas.firma_entrega_asegurado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}/></>:''}
                        </p>
                        <p onClick={() => verCita(citas.cita,'A')}> 
                          <IonIcon icon={pencilOutline}/>Firma Autorizado: <IonIcon icon={citas.firma_entrega_autorizado==null?warningSharp:checkboxSharp}  color={citas.firma_entrega_autorizado==null?"danger":"success"}/> {citas.firma_entrega_autorizado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"} onClick={() => verCita(citas.cita,'A')}/></>:''}
                        </p>
                        <p  onClick={() => verCita(citas.cita,'C')}> 
                          <IonIcon icon={pencilOutline}/>Firma Conductor.: <IonIcon icon={citas.firma_entrega==null?warningSharp:checkboxSharp}  color={citas.firma_entrega==null?"danger":"success"}/> {citas.firma_entrega==null? <> Validar Correo <IonIcon icon={mail} color={"warning"} onClick={() => verCita(citas.cita,'C')}/></>:''}
                        </p>
                        </>
                        :"<< No tiene acta digital >>"}
                        {citas.acta_entrega ? 
                        <p  onClick={() => Imprimir(citas.acta_entrega,citas.acta)} 
                        style={{
                          display: 'flex',
                          alignItems: 'center', // Centra verticalmente
                          justifyContent: 'center', // Centra horizontalmente
                          textAlign: 'center', // Alinea el texto dentro del contenedor
                          cursor: 'pointer', // Cambia el cursor al pasar sobre el texto
                        }}> 
                          <IonIcon icon={print}  style={{ width: '30px', height: '30px' }} />Imprimir Acta 
                        </p>:"" }
                        </div>
                        
                        {citas.estadod=='C'?
                      <>
                        <b>===========DEVOLUCION=================</b>
                        {citas.acta == '1' ? <>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Asegurado: <IonIcon icon={citas.firma_devolucion_asegurado==null?warningSharp:checkboxSharp} color={citas.firma_devolucion_asegurado==null?"danger":"success"}/>{citas.firma_devolucion_asegurado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'S')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Autorizado: <IonIcon icon={citas.firma_devolucion_autorizado==null?warningSharp:checkboxSharp}  color={citas.firma_devolucion_autorizado==null?"danger":"success"}/>{citas.firma_devolucion_autorizado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'A')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Conductor.: <IonIcon icon={citas.firma_devolucion==null?warningSharp:checkboxSharp}  color={citas.firma_devolucion==null?"danger":"success"}/>{citas.firma_devolucion==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'C')}/></>:''}
                        </p>
                        </>
                        :"<< No tiene acta digital >>"}
                        {citas.acta_devolucion ? 
                        <p  onClick={() => Imprimir(citas.acta_devolucion,citas.acta)}
                        style={{
                          display: 'flex',
                          alignItems: 'center', // Centra verticalmente
                          justifyContent: 'center', // Centra horizontalmente
                          textAlign: 'center', // Alinea el texto dentro del contenedor
                          cursor: 'pointer', // Cambia el cursor al pasar sobre el texto
                        }}> 
                          <IonIcon icon={print}  style={{ width: '30px', height: '30px' }} />Imprimir Acta 
                        </p>:"" }
                     
                      </>
                      :''}
                      <IonRippleEffect className="custom-ripple"></IonRippleEffect>
                      <IonRippleEffect className="custom-ripple"></IonRippleEffect>
                    </div>
                  </IonLabel>
                </IonItem>
              
            
            ))}
            
          </IonList>
          </>
            :''}
            {Tipo == 2 || Tipo == 0 ?
             <>
          <h3>DEVOLUCIONES</h3>
          <IonList>
            {listCitasDev.map((citas, i) => (
              <IonItemSliding>
                <IonItem className={citas.acta_devolucion=="" ? "FondoItemNo":"FondoItem"} key={citas.id}>
                  <IonLabel>
                    <div className="ion-activatable ripple-parent">
                      <h3>
                        <b className="label">{i + 1}</b> Conductor:{" "}
                        {citas.conductor}{" "}
                      </h3>
                      <p>
                        {" "}
                        <b>
                          <IonLabel color="primary">
                            <IonIcon icon={car} /> Placa: {citas.placaasig}
                          </IonLabel>
                        </b>{" "}
                        | Siniestro: {citas.sini}
                      </p>
                      <p>
                        <IonIcon icon={today} /> Devolucion: <b>{citas.fec_devolucion}</b> 
                      </p>
                      <p>
                         <IonIcon icon={time} /> Hora Devolucion: <b>{citas.hora_devol}</b>
                      </p>
                      <p>
                        <IonIcon icon={today} /> Entregado: <b>{citas.fecha}</b>
                      </p>
                      <p>
                        <IonIcon icon={time} /> Hora: <b>{citas.hora}</b>
                      </p>
                      
                       {citas.oper!=""?
                      <p>
                        <IonIcon icon={man} />  Operario: <b>{citas.oper}</b>
                      </p>
                      :""} 
                      {citas.dir_domicilio ? (
                        <IonLabel color="success">
                          Domicilio:{citas.dir_domicilio}
                        </IonLabel>
                      ) : (
                        ""
                      )}
                      
                        <b>============ENTREGA================</b>
                        {citas.acta == '1' ? <>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Asegurado: <IonIcon icon={citas.firma_entrega_asegurado==null?warningSharp:checkboxSharp} color={citas.firma_entrega_asegurado==null?"danger":"success"}/> {citas.firma_entrega_asegurado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"} onClick={() => verCita(citas.cita,'S')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Autorizado: <IonIcon icon={citas.firma_entrega_autorizado==null?warningSharp:checkboxSharp}  color={citas.firma_entrega_autorizado==null?"danger":"success"}/> {citas.firma_entrega_autorizado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"} onClick={() => verCita(citas.cita,'A')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Conductor.: <IonIcon icon={citas.firma_entrega==null?warningSharp:checkboxSharp}  color={citas.firma_entrega==null?"danger":"success"}/> {citas.firma_entrega==null? <> Validar Correo <IonIcon icon={mail} color={"warning"} onClick={() => verCita(citas.cita,'C')}/></>:''}
                        </p>
                        </>
                        :"<< No tiene acta digital >>"}
                        {citas.acta_entrega ? 
                        <p  onClick={() => Imprimir(citas.acta_entrega,citas.acta)}
                        style={{
                          display: 'flex',
                          alignItems: 'center', // Centra verticalmente
                          justifyContent: 'center', // Centra horizontalmente
                          textAlign: 'center', // Alinea el texto dentro del contenedor
                          cursor: 'pointer', // Cambia el cursor al pasar sobre el texto
                        }}> 
                          <IonIcon icon={print}  style={{ width: '30px', height: '30px' }} />Imprimir Acta 
                        </p>:"" }
                        {citas.estadod=='C'?
                      <div color={'danger'}>
                        <b>===========DEVOLUCION=================</b>
                        {citas.acta == '1' ? <>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Asegurado: <IonIcon icon={citas.firma_devolucion_asegurado==null?warningSharp:checkboxSharp} color={citas.firma_devolucion_asegurado==null?"danger":"success"}/>{citas.firma_devolucion_asegurado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'S')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Autorizado: <IonIcon icon={citas.firma_devolucion_autorizado==null?warningSharp:checkboxSharp}  color={citas.firma_devolucion_autorizado==null?"danger":"success"}/>{citas.firma_devolucion_autorizado==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'A')}/></>:''}
                        </p>
                        <p> 
                          <IonIcon icon={pencilOutline}/>Firma Conductor.: <IonIcon icon={citas.firma_devolucion==null?warningSharp:checkboxSharp}  color={citas.firma_devolucion==null?"danger":"success"}/>{citas.firma_devolucion==null? <> Validar Correo <IonIcon icon={mail} color={"warning"}  onClick={() => verCita(citas.cita,'C')}/></>:''}
                        </p>
                        </>
                        :"<< No tiene acta digital >>"}
                        {citas.acta_devolucion ? 
                        <p  onClick={() => Imprimir(citas.acta_devolucion,citas.acta)}
                        style={{
                          display: 'flex',
                          alignItems: 'center', // Centra verticalmente
                          justifyContent: 'center', // Centra horizontalmente
                          textAlign: 'center', // Alinea el texto dentro del contenedor
                          cursor: 'pointer', // Cambia el cursor al pasar sobre el texto
                        }}> 
                          <IonIcon icon={print}  style={{ width: '30px', height: '30px' }} />Imprimir Acta 
                        </p>:"" }
                      </div>
                      :''}
                      <IonRippleEffect className="custom-ripple"></IonRippleEffect>
                    </div>
                  </IonLabel>
                </IonItem>
              </IonItemSliding>
            ))}
          </IonList>
          </>
            :''}
       
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}  className="ion-padding fondohome">
      <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/driver.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> {TipoCorreo =='S'?'ASEGURADO':''}
                {TipoCorreo =='A'?'AUTORIZADO':''} 
                {TipoCorreo =='C'?'CONDUCTOR':''}  </b>
                
                </div>
                <div className="toast-body  text-dark">
                
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonLabel><b> {NombreCorreo} </b></IonLabel>
                   
                    </div>
                  </IonRow>
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonInput className='form-control'    placeholder="Correo Electronico" id='km' name='km' value={Correo}  onIonChange={(e) => setCorreo(e.detail.value!)}/>
                    </div>
                  </IonRow>

                  <IonRow>
                    <IonLabel className="p-2">
                      <b> Nota: Solo puedes editar el email del conductor, si el correo del asegurado o del autorizado esta mal escrito o no es debes de comunicarte con el call center para que lo puedan actualizar. </b>
                    </IonLabel>
                  </IonRow>
                
                
                  <IonButton id="savekm" expand="block" className='header'  onClick={() => ReenviarCorreo()} >
                              {Cargando==0?' Reenviar Correo':<IonSpinner color="light"></IonSpinner> }
                          </IonButton>

                          <IonButton id="savekm" expand="block" className='btn-danger' onClick={() => setShowModal(false)}>
                              CANCELAR
                          </IonButton>
                </div>
              </div>
            </div>
       
          </div>
 
</IonModal>
      </IonContent>

      <IonFooter className="ion-text-center">
        <IonButton className="boton" onClick={Principal}>
          <IonIcon icon={home} />
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Cita;
