import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Foto from './pages/Home';
import FotoDev from './pages/HomeDev';
import Login from './login/Login';
import Login2 from './login/Loginv2';
import Pagina from './home/Homev2';
import Cita from './citas/Cita';
import Citad from './citasd/Devolucion';
import Detalle from './detalle/Detalle'; 
import DetalleDev from './detalle/DetalleDev'; 
import Imagenes from './detalle/Imagenes'; 
import Perfil from './detalle/Perfil'; 
import Acta from './detalle/Acta'; 
import ActaEnt from './detalle/ActaEntrega'; 
import ActaDev from './detalle/ActaDev'; 
import ActaDevolucion from './detalle/ActaDevolucion'; 
import ActaDevBk from './detalle/ActaDevBk'; 
import Resumen from './detalle/Resumen'; 
import Conductor from './detalle/Conductor';
import Verimagen from './detalle/Zoom'; 
import Firmas from './citas/Firmas'; 
import Alistamiento from './citas/Alistamiento'; 
import AlistamientoDev from './citas/AlistamientoDev';
import Checkeo from './citas/CheckeoEntrega'; 
import DetalleChequeo from './citas/CheckeoDetalle'; 
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/foto">
          <Foto />
        </Route>
        <Route exact path="/alistar">
          <Checkeo />
        </Route>
        <Route exact path="/foto_dev">
          <FotoDev />
        </Route>
        <Route exact path="/alistamiento">
          <Alistamiento />
        </Route>
        <Route exact path="/alistamientod">
          <AlistamientoDev />
        </Route>
        <Route exact path="/resumen">
          <Resumen />
        </Route>
        <Route exact path="/home">
          <Pagina />
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login">
            <Login2 />
        </Route>
        <Route exact path="/citas">
            <Cita />
        </Route>
        <Route exact path="/conductor">
            <Conductor />
        </Route>
        <Route exact path="/devoluciones">
            <Citad />
        </Route>
       {/*  <Route exact path="/verimagen">
            <Verimagen />
        </Route> */}
        <Route exact path="/detalle">
          <Detalle />
        </Route>
        <Route exact path="/detalle_dev">
          <DetalleDev />
        </Route>
        <Route exact path="/imagenes">
          <Imagenes />
        </Route>
        <Route exact path="/perfil">
          <Perfil />
        </Route>
        <Route exact path="/acta">
          <ActaEnt />
        </Route>
        <Route exact path="/acta_test">
          <Acta />
        </Route>
        {/* <Route exact path="/actaDev">
          <ActaDev />
          </Route> */}
          <Route exact path="/actaDev">
          <ActaDevolucion />
          </Route>
          <Route exact path="/actaDevBk">
          <ActaDevBk />
          </Route>
        <Route exact path="/firmadas">
          <Firmas />
        </Route>
        <Route exact path="/detallechequeo">
          <DetalleChequeo />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
