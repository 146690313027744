import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { IonButton, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonNote, IonSpinner, IonText, IonTextarea, IonThumbnail } from '@ionic/react';
import { camera, checkmark, colorFill, imageOutline, logoApple, reload, save, trash, warning } from 'ionicons/icons';
import axios from 'axios';
import Conexio from '../services/Index';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import ImageZoomModal from './Zoom';

interface TomarFotoProps {
  ladito: string;
  cita:string;
  foto:string;
  side:string;
  icono:string;
}

const TomarFoto: React.FC<TomarFotoProps> = ({ ladito,cita,foto,side,icono}) => {
  const [CargarImagen, setCargarImagen] = useState<any>("0");
  const [SubirImagen, setSubirImagen] = useState<any>("0");
  const [ImgFoto, setImgFoto] = useState<any>(foto);
  const [showModal, setShowModal] = useState(false);
  const [ImagenEntrega, setImagenEntrega] = useState("");
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  let uri = Conexio;
  const takePictureo = async (lado: string) => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera
    });

    var imageUrl: any = image.webPath;

    setImgFoto(imageUrl);

    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        //setOdometro(base64data); 
        resolve(base64data);
        //console.log(base64data);
        var base: any = base64data;

        switch (lado) {
          case 'INTERNO':
            UploadFoto('odometro', base);
            break;
          case 'FRENTE':
            UploadFoto('frente', base);
            break;
          case 'IZQUIERDO':
            UploadFoto('izquierdo', base);
            break;
          case 'ATRAS':
            UploadFoto('atras', base);
            break;
          case 'DERECHO':
            UploadFoto('derecho', base);
            break;
          case 'ADC1':
            UploadFoto('ad1', base);
            break;
          case 'ADC2':
            UploadFoto('ad2', base);
            break;
          case 'MOTOR':
            UploadFoto('Adicional2', base);
            break;
          case 'ACCESORIOS':
            UploadFoto('Adicional1', base);
            break;
            case 'EXTINTOR':
              UploadFoto('extintor',base);
            break;
            case 'CEDULA':
              UploadFoto('cedula',base);
            break;
            case 'CEDULA2':
              UploadFoto('cedular',base);
            break;
            case 'LICENCIA':
              UploadFoto('licencia',base);
            break;
            case 'LICENCIA2':
              UploadFoto('licenciar',base);
            break;
            case 'CEDULA-FRENTE':
              UploadFoto('Adicional3',base);
            break;
            case 'CEDULA-ATRAS':
              UploadFoto('Adicional4',base);
            break;
            case 'AUTORIZACION':
              UploadFoto('Autorizacion',base);
            break;
            case 'POLIZA':
              UploadFoto('Poliza',base);
            break;
            case 'CAMARA':
              UploadFoto('Comercio',base);
            break;
            case 'ORDEN':
              UploadFoto('Taller',base);
            break;
        }

        // window.localStorage.setItem('odo_'+cita,base);
        //window.localStorage.setItem('ho1_' + cita, hora1);
      }
    });

  };
  function UploadFoto(campo: string, imagen: string) {
    setSubirImagen(1);
    var orden = window.localStorage.getItem('cita');
    var Acta = window.localStorage.getItem('acta');
    const datos = {
      campo: campo,
      imagen: imagen,
      Cita: orden,
      tipo: Acta,
    };
    var ur = 'imagenes';
    
    axios.post(uri + 'appmovil/' + ur, datos)
      .then(function (res) {

        setSubirImagen(0);
        console.log(res.data);
        //setNext(false);
      })
      .catch(function (err) {
        console.log(err);
        setSubirImagen(3);
        //setNext(false);
      })
      .then(function () {
        //loading.style.display = 'none';
        //setNext(false);
      });

  }
  function VerEntregaImagen(lado:any){
    //setShowModal(true)
    
    VerImagenIndividual(lado);
  }
  function VerImagenIndividual(lado:any){
    setCargarImagen(1);
    var cita = window.localStorage.getItem('cita');
    var acta = window.localStorage.getItem('acta');
    const datos = { lado: lado, cita:cita, acta:acta };
    axios.post(uri+'appmovil/verimagenindividual',datos)
        .then(function(res) {
          //alert(res.data.msj);
          console.log(res.data);
          setCargarImagen(0);
          setImagenEntrega(res.data.imagen);
          toggleModal();
          //window.open(res.data.imagen, '_blank');
        })
        .catch(function(err) {
          //Swal.fire('Error');
          console.log(err);
        })
        .then(function() {
          //loading.style.display = 'none'
        });
    
    } 
      useEffect(() => {
        setImgFoto(foto);
      }, []);
  return (
    <div>
<IonItem  style={{ fontSize: '12px',colorFill:'info',backgroundColor: '#f0f0f0' }}>
            <IonThumbnail slot="start">
                <IonImg src={ImgFoto!=""?ImgFoto:icono}></IonImg>
            </IonThumbnail>
            <IonLabel>{ladito}
            <br />
            {SubirImagen==1?
            <IonText color="success"><sub>{'Subiendo..'}</sub></IonText>
            :SubirImagen==3?<IonText color="danger"><sub>{'No se subio'}</sub></IonText>:side
            }
            </IonLabel>

            <IonButton size="default" color={ImgFoto!=""?'success':'danger'} slot='end' onClick={()=>takePictureo(ladito)}><IonIcon icon={camera} /></IonButton>
           
              {showModal && (
              <ImageZoomModal
          imageUrl={ImagenEntrega}
          onClose={() => setShowModal(false)}
        />)}
          </IonItem>
          
    </div>
    
  );
}

export default TomarFoto;