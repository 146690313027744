import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,useIonViewWillEnter, IonAvatar, IonItemSliding, IonList, IonSkeletonText, IonDatetime, IonDatetimeButton, IonModal, IonCol, IonRow, IonSelect, IonSelectOption, IonFab, IonFabButton } from '@ionic/react';
import './Devolucion.css';
import { useState } from 'react';
import { man, calendar, eye, search,people,document, home,arrowBackSharp, arrowForward, hourglass, time, car, locate, locationSharp, logOut, reload, list } from 'ionicons/icons';
import { userInfo } from 'os';
import Home from '../pages/Home';
import {citas} from '../components/model/citas';
import axios from 'axios';
import Conexio from '../services/Index';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

const Devolucion: React.FC = () => {
 
  let n = window.localStorage.getItem("name_u");
  let o = window.localStorage.getItem("o");
  let ope = window.localStorage.getItem("key");
  let tk = window.localStorage.getItem("key_t");

  const [presentAlert] = useIonAlert();
  const [nombre, seNombre] = useState(n);
  const [clave, setClave] = useState("");
  let uri = Conexio;
; 
  //let uri = 'http://127.0.0.1:8000/api/'; 
  const [listCitas, setlistCitas] = useState<citas[]>([]);
  const [listCitasDom, setlistCitasDom] = useState<citas[]>([]);
  const [Oficinas, setSucursales] = useState<any[]>([]);
  const [fechah, setFecha] = useState<string>(hoy);
  const [Asegurado, setAsegurado] = useState<string>("");
  const [Placa, setPlaca] = useState<string>("");
  const [ofice, setOficina] = useState(o);
  const [loaded, setLoaded] = useState(0);

  async function Principal() {
      window.location.href = '/home';
  }
  async function funza() {
    window.location.href = 'https://emtra.com.co/tarifas-transito-funza/';
  }
  async function bogota() {
    window.location.href = 'https://www.ventanillamovilidad.com.co/tarifas';
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function Entregas() {
    window.location.href = "/citas";
  }
  async function getCitas() {
    setLoaded(0);
    // console.log(loaded);
    const response = await fetch(
      uri + "citaservicio?token=" + tk + "&fecha=" + fechah + "&oficina="+ofice
    );
    const result = await response.json();
    //console.log(result.data);
    setLoaded(0);
    setlistCitas(result.data);
    //console.log(o);
    
  }

  async function devoluciones() {
    const datos = { fecha: fechah, oficina : ofice,cliente:Asegurado,placa:Placa };
    console.log(loaded);
    setLoaded(0);
    axios({
      method: "post",
      url: uri + "appmovil/devolucion_new",
      data: datos,
      headers: { Authorization: tk }
    })
        .then(function(res) {
          console.log(res);
          if(res.status==200) {
            setLoaded(1);
            console.log(res.data);
            var est = res.data.estado;
            if(est==0){
              window.location.href = "/";
            }
            setlistCitas(res.data.cita);
            setlistCitasDom(res.data.cita_dom);
            
          }
        })
        .catch(function(err) {
          console.log(err);
        })
        .then(function() {
          //loading.style.display = 'none';
        });
  }
  async function sucursales() {
    let o = window.localStorage.getItem("o");
    const datos = { oficina: o};
    setLoaded(0);
     console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/sucursales",
      data: datos,
      headers: { Authorization: tk }
    })
        .then(function(res) {
          console.log(res);
      
          if(res.status==200) {
            console.log(res.data.estado);
            var est = res.data.estado;
            if(est==0){
              window.location.href = "/";
            }
            setSucursales(res.data);
         
          }
        })
        .catch(function(err) {
          console.log(err);
        })
        .then(function() {
          //loading.style.display = 'none';
        });
  }
  function verCita(idS: string){
    console.log(idS);
    window.localStorage.setItem('cita',idS);
    window.location.href = "/detalle_dev";
  }
  function verCitaNo(idS: string){
    console.log(idS);
    alert("Esta devolucion no tiene acta de entrega, debes de realizarla con el app anterior");
  }
  function Salir(){
    //localStorage.clear();
    window.location.href = "/";
}
  useIonViewWillEnter(() => {
    devoluciones();
    sucursales();
    if (!tk) {
      window.location.href = "/";
    }
    
  });
  return (
    <IonPage>
           <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="javascript:void(0)#" onClick={Principal}>
              <IonImg
                className="rounded-pill"
                style={{ width: 80 }}
                src={require("../assets/images/logomarca.png")}
              ></IonImg>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Entregas}>
                    Citas de Entrega
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        <p>
          <div className="d-grid">
            <button
              className="btn btn-primary  btn-block"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-controls="collapseExample"
            >
              Filtrar Devoluciones
            </button>
          </div>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
          <IonRow>
          <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="asegurado"
                  id="asegurado"
                  value={Asegurado}
                  placeholder="Nombre del asegurado"
                  onIonChange={(e) => setAsegurado(e.detail.value!)}
                ></IonInput>
              </IonCol>
          </IonRow>
            <IonRow>

            <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="placa"
                  id="placa"
                  placeholder="Placa"
                  value={Placa}
                  onIonChange={(e) => setPlaca(e.detail.value!)}
                ></IonInput>
              </IonCol>
              <IonCol>
                <IonInput
                  type="date"
                  className="form-control"
                  name="fecha"
                  id="fecha"
                  value={fechah}
                  onIonChange={(e) => setFecha(e.detail.value!)}
                ></IonInput>
              </IonCol>

              <IonCol>
                <IonSelect
                  className="form-control"
                  value={ofice}
                  onIonChange={(e) => setOficina(e.detail.value!)}
                  placeholder="Seleccione la Sucursal"
                >
                  <IonSelectOption value={""}>
                    Seleccione la sucursal
                  </IonSelectOption>
                  {Oficinas.map((item, i) => (
                    <IonSelectOption key={i} value={item.id}>
                      {item.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={devoluciones}
              >
                Buscar Cita
              </button>
            </div>
          </div>
        </div>
     
        
       
        
        {loaded==1? 
        <IonList>
          {listCitas.map((citas,i) => (
            <IonItemSliding key={citas.id}>
              <IonItem  onClick={citas.acta=='0'?() => verCitaNo(citas.cita):() => verCita(citas.cita)}>
            
            <IonLabel color={citas.acta=='0'?'danger':'primary'}>
              <h3><b className='label'>{i+1}</b> Conductor: {citas.conductor} </h3>
              <p><IonIcon icon={car} /> Placa Siniestro: <b>{citas.placa}</b></p>
              <p> <b><IonLabel color="warning"><IonIcon icon={car} />  Vehiculo Asignado: {citas.placaasig}</IonLabel></b> |  Siniestro: {citas.numerosiniestro}</p>
              <p><IonIcon icon={time} /> Arribo: <b>{citas.arribo}</b></p>
              <p><IonIcon icon={time} /> Hora: <b>{citas.hora}</b></p>
              <p><IonIcon icon={man} /> Operario: <b>{citas.oper}</b></p>
              {citas.dir_domiciliod?<IonLabel color="success">Domicilio:{citas.dir_domiciliod}</IonLabel>:''}
              <p><IonIcon color='danger' icon={list} /> Devolucion con acta: <b>{citas.acta=='0'?'No':'Si'}</b></p>
            </IonLabel>
          </IonItem>
            
            </IonItemSliding>
          ))}
          <IonItemSliding>
              <IonItem>
            
            <IonLabel>
              <h3><b className='label'>==========Cierre de Domicilio============</b></h3>
     
            </IonLabel>
          </IonItem>
            
            </IonItemSliding>
          {listCitasDom.map((citas,i) => (
            <IonItemSliding key={citas.id}>
              <IonItem  onClick={() => verCita(citas.cita)}>
            
            <IonLabel>
              <h3><b className='label'>{i+1}</b> Conductor: {citas.conductor} </h3>
              <p> <b><IonLabel color="warning"><IonIcon icon={car} />  Placa: {citas.placaasig}</IonLabel></b> |  Siniestro: {citas.numerosiniestro}</p>
              <p><IonIcon icon={time} /> Hora: <b>{citas.hora}</b></p>
              {citas.dir_domiciliod?<IonLabel color="success">Domicilio:{citas.dir_domiciliod}</IonLabel>:''}
            </IonLabel>
          </IonItem>
            
            </IonItemSliding>
          ))}
        </IonList>:''
         }
{loaded==0?
        <IonList>
         
          <IonItem>
          
            <IonLabel>
              <h3>
                <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
              </h3>
              <p>
                <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
              </p>
              <p>
                <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
              </p>
            </IonLabel>
           
          </IonItem>
          <IonItem>
          
          <IonLabel>
            <h3>
              <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
            </h3>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
            </p>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
            </p>
          </IonLabel>
         
        </IonItem>
        <IonItem>
          
          <IonLabel>
            <h3>
              <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
            </h3>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
            </p>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
            </p>
          </IonLabel>
         
        </IonItem>
        <IonItem>
          
          <IonLabel>
            <h3>
              <IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>
            </h3>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
            </p>
            <p>
              <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
            </p>
          </IonLabel>
         
        </IonItem>
        </IonList>:''
      }


<IonFab slot="fixed" vertical="bottom" horizontal="end" onClick={devoluciones}>
          <IonFabButton>
            <IonIcon icon={reload}></IonIcon>
          </IonFabButton>
          
        </IonFab>
      </IonContent>
      <IonFooter  className="ion-text-center">

        <IonButton  className='boton' onClick={Principal} ><IonIcon icon={home} /></IonButton>

      </IonFooter>
    </IonPage>
  );
};

export default Devolucion;
