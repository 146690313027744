import {
  IonContent,
  useIonAlert,
  IonSelectOption,
  IonHeader,
  IonPage,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonViewWillEnter,
  IonAvatar,
  IonItemSliding,
  IonList,
  IonSkeletonText,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonCol,
  IonRow,
  IonRippleEffect,
  IonSelect,
  IonFab,
  IonFabButton,
  IonFabList,
} from "@ionic/react";
import Swal from "sweetalert2";
import "./Cita.css";
import { useState } from "react";
import {
  add,
  calendar,
  eye,
  search,
  people,
  document,
  home,
  arrowBackSharp,
  arrowForward,
  hourglass,
  time,
  car,
  locate,
  locationSharp,
  logOut,
  man,
  chevronUpCircle,
  colorPalette,
  globe,
  reload,
} from "ionicons/icons";
import { userInfo } from "os";
import Home from "../pages/Home";
import { citas } from "../components/model/citas";
import axios from "axios";
import Conexio from "../services/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

const Cita: React.FC = () => {
  let n = window.localStorage.getItem("name_u");
  let o = window.localStorage.getItem("o");
  let ope = window.localStorage.getItem("key");
  let tk = window.localStorage.getItem("key_t");

  const [presentAlert] = useIonAlert();
  const [nombre, seNombre] = useState(n);
  const [operario, setOperario] = useState(ope);
  //let uri = 'https://pot.aoacolombia.com/api/';
  let uri = Conexio;
  const [listCitas, setlistCitas] = useState<citas[]>([]);
  const [Oficinas, setSucursales] = useState<any[]>([]);
  const [fechah, setFecha] = useState<string>(hoy);
  const [ofice, setOficina] = useState(o);
  const [Asegurado, setAsegurado] = useState<string>("");
  const [Placa, setPlaca] = useState<string>("");
  const [loaded, setLoaded] = useState(0);

  async function Principal() {
    window.location.href = "/home";
  }
  async function funza() {
    window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
  }
  async function bogota() {
    window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function Devoluciones() {
    window.location.href = "/devoluciones";
  }
  async function getCitasbk() {
    setLoaded(0);
    console.log(loaded);
    const response = await fetch(
      uri +
        "citaservicio?token=" +
        tk +
        "&fecha=" +
        fechah +
        "&oficina=" +
        ofice
    );
    const result = await response.json();
    console.log(result.data);
    setLoaded(0);
    setlistCitas(result.data);
    //console.log(o);
  }
  async function getCitas() {
    let o = window.localStorage.getItem("key");
    const datos = { fecha: fechah, oficina: ofice, cliente: Asegurado, ope: o,placa:Placa  };
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/entrega_alistamiento",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        
        setLoaded(1);
        if (res.status == 200) {
          console.log('Datos cargados.');
          console.log(res.data.datos);
          var est = res.data.estado;
          if (est == 0) {
            window.location.href = "/";
          }
          setlistCitas(res.data.datos);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }

  async function sucursales() {
    let o = window.localStorage.getItem("o");
    const datos = { oficina: o };
    setLoaded(0);
    //console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/sucursales",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res);

        if (res.status == 200) {
          console.log(res.data.estado);
          var est = res.data.estado;
          if (est == 0) {
            window.location.href = "/";
          }
          setSucursales(res.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  function verCita(idS: string, placa: string) {
    console.log(idS);
    window.localStorage.setItem("cita", idS);
    window.localStorage.setItem("placa", placa);
    window.localStorage.setItem("tipo", "ENTREGA");
    
    axios({
      method: "get",
      url: uri + "appmovil/validarplaca/"+placa+"/"+idS,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res);

        if (res.status == 200) {
          console.log(res.data.estado);
          var est = res.data.success;
          var id = res.data.chequeo;
          if (est == false) {
             window.location.href = "/alistar";
          }else{
            var resultado = window.confirm(res.data.message+", quieres ver el detalle del alistamiento para copiarlo?");
            if (resultado) {
              window.localStorage.setItem("chequeo", id);
              window.localStorage.setItem("placa", placa);
              window.localStorage.setItem("tipo", "ENTREGA");
              window.location.href = "/detallechequeo";
            } else {
              window.location.href = "/alistar";
            }
            
          }
     
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  function Salir() {
    localStorage.clear();
    window.location.href = "/";
  }
  useIonViewWillEnter(() => {
    getCitas();
    sucursales();
    if (!tk) {
      window.location.href = "/";
    }
  });
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={Principal}>
              <IonImg
                className="rounded-pill"
                style={{ width: 80 }}
                src={require("../assets/images/logomarca.png")}
              ></IonImg>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Devoluciones}>
                    Lista de Devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        <p>
          <div className="d-grid">
            <button
              className="btn btn-primary  btn-block"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-controls="collapseExample"
            >
              Filtrar Alistamiento
            </button>
          </div>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
          <IonRow>
          <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="asegurado"
                  id="asegurado"
                  value={Asegurado}
                  placeholder="Nombre del asegurado"
                  onIonChange={(e) => setAsegurado(e.detail.value!)}
                ></IonInput>
              </IonCol>
          </IonRow>
            <IonRow>
            
              <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="placa"
                  id="placa"
                  placeholder="Placa Siniestro"
                  value={Placa}
                  onIonChange={(e) => setPlaca(e.detail.value!)}
                ></IonInput>
              </IonCol>
              <IonCol>
                
                <IonInput
                  type="date"
                  className="form-control"
                  name="fecha"
                  id="fecha"
                  value={fechah}
                  onIonChange={(e) => setFecha(e.detail.value!)}
                ></IonInput>
              </IonCol>

              <IonCol>
                <IonSelect
                  className="form-control"
                  value={ofice}
                  onIonChange={(e) => setOficina(e.detail.value!)}
                  placeholder="Seleccione la Sucursal"
                >
                  <IonSelectOption value={""}>
                    Seleccione la sucursal
                  </IonSelectOption>
                  {Oficinas.map((item, i) => (
                    <IonSelectOption key={i} value={item.id}>
                      {item.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={getCitas}
              >
                Buscar Cita
              </button>
            </div>
          </div>
        </div>

        {loaded == 1 ? (
          <IonList>
            {listCitas.map((citas, i) => (
              <IonItemSliding key={i}>
                <IonItem onClick={() => verCita(citas.cita,citas.placaasig)}>
                  <IonLabel>
                    <div className="ion-activatable ripple-parent">
                      <h3>
                        <b className="label">{i + 1}</b> Conductor:{" "}
                        {citas.conductor}{" "}
                      </h3>
                      <p>
                        {" "}
                        
                        <b>
                          <IonLabel >
                            <IonIcon icon={car} /> Revisar Vehiculo: {citas.placaasig}
                          </IonLabel>
                        </b>{" "}
                        | Siniestro: {citas.sini}
                      </p>
                      <p>
                        <IonIcon icon={time} /> Hora: <b>{citas.hora}</b>
                      </p>
                      
                       
                      {citas.dir_domicilio ? (
                        <IonLabel color="success">
                          Domicilio:{citas.dir_domicilio}
                        </IonLabel>
                      ) : (
                        ""
                      )}
                      {citas.operario_domicilio != "0" ? (
                        <IonLabel color="success">En Proceso</IonLabel>
                      ) : (
                        ""
                      )}
                      <IonRippleEffect className="custom-ripple"></IonRippleEffect>
                    </div>
                  </IonLabel>
                </IonItem>
              </IonItemSliding>
            ))}
          </IonList>
        ) : (
          ""
        )}
        {loaded == 0 ? (
          <IonList>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )}
         <IonFab slot="fixed" vertical="bottom" horizontal="end" onClick={getCitas}>
          <IonFabButton>
            <IonIcon icon={reload}></IonIcon>
          </IonFabButton>
          
        </IonFab>
      </IonContent>

      <IonFooter className="ion-text-center">
        <IonButton className="boton" onClick={Principal}>
          <IonIcon icon={home} />
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Cita;
