import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, IonSpinner  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { Camera, CameraResultType,CameraSource } from '@capacitor/camera';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, save, cellular, mail, keySharp, checkmark, camera } from 'ionicons/icons';
import axios from 'axios';
import Modal from './Modal';
import Conexio from '../services/Index';
import Swal from 'sweetalert2';

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();

  const [Nombre, setNombre] = useState("");
  const [Email, setEmail] = useState("");
  const [Celular, setCelular] = useState("");
  const [Usuario, setUsuario] = useState("");
  const [Clave, setClave] = useState("");
  const [Clave2, setClave2] = useState("");
  const [imageno, setImagen] = useState<any>("");
  const [Foto, setFoto] = useState<any>("");
  const [Nombreboton, setBoton] = useState<any>("0");
  const [Cambio, setCambio] = useState<any>("0");
  const [roleMessage, setRoleMessage] = useState('');
  const [Cargando, setCargando] = useState<any>("0");
  const [Cargandof, setCargandof] = useState<any>("0");
  let ofi = window.localStorage.getItem("oficina");
  const [oficina, setOficina] = useState(ofi);
  const takePictureo = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera
    });

    var imageUrl = image.webPath;
    setImagen(imageUrl);
    setCambio(1);
    console.log(imageUrl);
  };
  const Base1 = async (i:string) => {
    const data = await fetch(i);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setFoto(base64data); 
        resolve(base64data);
        var f:any = base64data;
        save_foto(f);
      }
    });
  }


  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;

  const modal = useRef<HTMLIonModalElement>(null);

  
  function ValidarFoto() {
    
     Base1(imageno).then();
     setBoton(1)
   
   }

  async function Entregas() {
      window.location.href = '/home';
  }
  async function TomarFotos() {
    window.location.href = '/foto';
}
function DetalleCita(){
  var usuario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  const datos = { id: usuario, token : token };
  axios.post(uri+'appmovil/perfil', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        if(res.data=='Inicia Session'){
           window.location.href = '/';
        }
        var d = res.data.datos;
        setNombre(d.nombre+' '+d.apellido);
        setEmail(d.email);
        setCelular(d.celular);
        setUsuario(d.usuario);
        setImagen(res.data.foto);
        window.localStorage.setItem('foto',res.data.foto);
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
}
async function Principal() {
  window.location.href = '/home';
}

function save_registro(){

 
  var token = window.localStorage.getItem('key_t');
  var ope = window.localStorage.getItem('key');
  if(Clave==''){
    alert("Debes de digitar la contraseña");
    return false;
 }
 if(Clave2==''){
    alert("Debes de confirmar la contraseña");
    return false;
  }
  console.log(Clave.length);
  if(Clave!=Clave2){
       alert("las contraseñas no coinciden");
       return false;
  }
  var l = Clave.length;
  if(l <= 8){
      alert("la contraseña debe de contener minimo 8 caracteres");
      return false;
  }
  setCargando(1);
  setBoton(1);

  const datos = { id: ope, Clave : Clave,token:token};
  axios.post(uri+'appmovil/savepass', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Datos registrado',
          message: res.data,
          buttons: ['OK'],
        })
        DetalleCita();
        setCargando(0);
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
function save_foto(fot:any){

  setCargandof(1);
  var token = window.localStorage.getItem('key_t');
  var ope = window.localStorage.getItem('key');
  console.log(Foto)
  const datos = { id: ope, foto:fot,token:token};
  axios.post(uri+'appmovil/savefoto', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Datos registrado',
          message: res.data,
          buttons: ['OK'],
        })
        DetalleCita();
        setCargandof(0);
        setBoton(0);
        setCambio(0);
        //setImagen(Foto);
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
function Salir() {
  localStorage.clear();
  window.location.href = "/";
}
function Listaentrega() {

  window.location.href = "/citas";
}
function Listadev() {

  window.location.href = "/devoluciones";
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
  return (
    <IonPage>
        <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           
              <IonImg
                className="rounded-pill"
                onClick={Principal}
                src={require("../assets/images/backwhite.png")}
              ></IonImg>
          
           
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listadev}>
                    Lista de devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
       
          <IonHeader>
          <IonToolbar className='header ion-text-center'>
            <IonTitle><IonIcon icon={informationCircle} /> Información Basica</IonTitle>
            <IonButton size="default" color={imageno?'success':'danger'} slot="end"  onClick={()=>takePictureo()}><IonIcon icon={imageno?camera:camera} /></IonButton>
           
            </IonToolbar>
          </IonHeader>
          <IonCard>
          
                 {imageno?
                  <IonImg src={imageno}></IonImg>:
                  <IonImg src={"https://ionicframework.com/docs/img/demos/avatar.svg"}></IonImg>
                  }
               {Cambio==0?'':
               Nombreboton==0?
                 <IonButton type='button'  className='header' expand="block" onClick={ValidarFoto}> <IonIcon icon={save} /> Quieres cambiar la Foto?</IonButton>:
                 Cargandof==0?
                 <IonButton expand="block" className='color' onClick={save_foto}> <IonIcon icon={save} /> Guarda Foto</IonButton>:
                 <IonButton expand="block" className='color' ><IonSpinner color="light"></IonSpinner> Cambiando Foto.. </IonButton>

              }
      <IonCardHeader>
        <IonCardTitle>{Nombre?Nombre:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCardTitle>
        <IonCardSubtitle>{Email?Email:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
    
              <IonRow>
                 <IonCol><IonIcon icon={person} /> <b>Usuario</b></IonCol>
                 <IonCol>{Usuario?Usuario:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={person} /> <b>Ciudad</b></IonCol>
                 <IonCol>{Usuario?oficina:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={keySharp} /><b> Digite Contraseña</b></IonCol>
                 <IonCol>{Usuario? <IonInput className='form-control' placeholder="************"  onIonChange={(e) => setClave(e.detail.value!)}/>:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={keySharp} /><b> Confirme Contraseña</b></IonCol>
                 <IonCol>{Usuario? <IonInput className='form-control' placeholder="************"  onIonChange={(e) => setClave2(e.detail.value!)}/>:<IonSkeletonText animated={true} style={{ 'width': '100%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              {
                 Cargando=="0"?<IonButton expand="block" className='color' onClick={save_registro}> <IonIcon icon={save} /> Editar Contraseña</IonButton>:

                 <IonButton expand="block" className='color' ><IonSpinner color="light"></IonSpinner> Editando Contraseña.. </IonButton>

              }
      </IonCardContent>
    </IonCard>
        </IonContent>
        <IonFooter className="ion-text-center">
       
       <IonButton  className='boton' onClick={Principal} ><IonIcon icon={home} /></IonButton>
    
     </IonFooter>
     

    </IonPage>
  );
};

export default Home;
