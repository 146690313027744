import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, useIonLoading, IonSpinner, IonSelect, IonSelectOption, IonAlert  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, trash } from 'ionicons/icons';
import axios from 'axios';
import Modal from './Modal';
import Conexio from '../services/Index';

const Home: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentAlert] = useIonAlert();
  let cita = window.localStorage.getItem("cita");
  let acta = window.localStorage.getItem("acta");
  const [ncita, seCita] = useState(cita);
  const [Acta, setActa] = useState(acta);
  const [isiniestro, setSin] = useState("");
  const [iaseguradora, setAseg] = useState("");
  const [iasegurado, setAse] = useState("");
  const [ideclarante, setDec] = useState("");
  const [iplaca, setPla] = useState("");
  const [ioficina, setOfi] = useState("");
  const [ivehiculo, setVeh] = useState("");
  const [iagendado, setAge] = useState("");
  const [idias, setDia] = useState("");
  const [isin, setIdSin] = useState("");
  const [ioperario, setOperario] = useState("");
  const [NombreOperario, setNameOperario] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [Cargando, setCargando] = useState<any>("0");
  const [Conductor, setConductor] = useState<any>("");

  const [kmi, setKmi] = useState("");
  const [tpq, setTpq] = useState("0");
  const [kmd, setKmd] = useState("0");
  const [Observaciones, setObskm] = useState("");

  const [flota, setFlota] = useState("");
  const [Ukm, setUltKm] = useState("");
  const [Obs, setObs] = useState("");
  const [Estado, setEstado] = useState("");
  const [Domicilio, setDomicilio] = useState("");
  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  const [Ubicacion, setUbicacion] = useState('');
  const [UbicacionKi, setUbicacionKi] = useState('');
  const [UbicacionKf, setUbicacionKf] = useState('');

  const [Tipo, setTipo] = useState('1');
  const [Nombre, setNombre] = useState('');
  const [Apellido, setApellido] = useState('');
  const [Cedula, setCedula] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Email, setEmail] = useState('');
  const [TipoLicencia, setTipoLicencia] = useState('No');
  const [EstadoEntrega, setEstadoEntrega] = useState('0');
  const [EstadoDevolucion, setEstadoDevolucion] = useState('0');

  const [TipoD, setTipoD] = useState('1');
  const [NombreD, setNombreD] = useState('');
  const [ApellidoD, setApellidoD] = useState('');
  const [CedulaD, setCedulaD] = useState('');
  const [TelefonoD, setTelefonoD] = useState('');
  const [EmailD, setEmailD] = useState('');
  const [Licencia, setLicencia] = useState('');

  const [kmd1, setKmd1] = useState("0");
  const [kmd2, setKmd2] = useState("0");


  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;
  //let uri = 'http://127.0.0.1:8000/api/';
  //http://127.0.0.1:8000 

  
  function dismiss() {
    modal.current?.dismiss();
  }


  async function Entregas() {
      window.location.href = '/detalle';
  }
  async function TomarFotos() {
    window.location.href = '/foto';
}
function DetalleCita(){
  var orden = window.localStorage.getItem('cita');
  var token = window.localStorage.getItem('key_t');
  axios({
    method: "get",
    url: uri + "appmovil/ver/"+orden,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
         let d = res.data.citas;
         let v = res.data.vehiculo;
         let u = res.data.ubicacion;
         let con = res.data.conductor;
          //console.log(res.data);
          setUbicacion(u.estado);
          setUbicacionKi(u.odometro_final);
          setUbicacionKf(u.odometro_inicial);
          if(con){
            setConductor(con.nombres);
            setCedulaD(con.identificacion);
            setNombreD(con.nombres);
            setApellidoD(con.apellidos);
            setTelefonoD(con.telefono);
            setEmailD(con.correo);
            setEstadoEntrega(con.entrega);
            setEstadoDevolucion(con.devolucion);
            setLicencia(con.licencia);
          }
         

          setSin(d.sini); 
          setAse(d.asegurado_nombre); 
          setDec(d.declarante_nombre); 
          setPla(d.placa); 
          setOfi(d.oficina); 
          setVeh(d.placaasig); 
          setAge(d.agendada_por); 
          setDia(d.dias_servicio); 
          setFlota(d.flota); 
          setUltKm(v.ultimokm); 
          setObs(d.obs_devolucion); 
          setNameOperario(d.nombreoperario)
          setOperario(d.operario_domicilio)
          setEstado(d.estadocita)
          setDomicilio(d.dir_domicilio)
          window.localStorage.setItem('placa',d.placaasig);
         // document.getElementById("obs").innerHTML = d.obs_devolucion;
          
          var asi = d.operario_domicilio;
          var est = d.estadocita;

          var btn = '<button class="btn btn-primary btn-block" onclick="asignar()">Asignar Operacio</button>';
          if(asi == 0){
            //document.getElementById("boton_asignar").innerHTML = btn;
          }else{
            var btn2 = 'La cita de entrega esta concluida <br><a href="" class="btn btn-danger btn-block">Toma de Imagenes</a>';
            if(est == 'P'){
              var btn2 = '<button class="btn btn-info btn-block"   data-toggle="modal" data-target="#modal-km" role="button">Registrar Observaciones</button>';
            }
           
            //document.getElementById("boton_asignar").innerHTML ='<div class="text-center">Operario:'+ d.nombreoperario+' <br>'+btn2+'</div>';
          }
          var dom = d.dir_domicilio;
          if(dom!=''){
            //document.getElementById('desplazamiento').style.display = 'block';
          }
          
        }
      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });
}
async function Principal() {
  window.location.href = '/home';
}
async function Entrega() {
  if(Acta=='ENTREGA'){
    window.location.href = '/acta';
  }else{
    window.location.href = '/actaDev';
  }
  

}
function CancelarConductor(){
  setCargando('0');
}
function AddConductor(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  var acta = window.localStorage.getItem('acta');
  if(Cedula==""){
     alert("Debes de digitar la cedula")
    return false;
  }
  if(Nombre==""){
    alert("Debes de digitar el nombre")
   return false;
 }
 if(Apellido==""){
  alert("Debes de digitar el apellido")
 return false;
}
if(Telefono==""){
  alert("Debes de digitar el telefono")
 return false;
}
if(Email==""){
  alert("Debes de digitar el email")
 return false;
}
if(TipoLicencia=="No"){
  alert("Debes de verificar la licencia")
 return false;
}
  loading();
  var datos = {cita:orden, tipo:Tipo, cedula:Cedula, nombre:Nombre, apellido:Apellido, telefono:Telefono, email:Email, asegurado:'No',acta:acta, tipolicencia:TipoLicencia}
  axios({
    method: "post",
    url: uri + "appmovil/AgregarConductor",
    data:datos,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.msj,
            buttons: ['OK'],
          })
          if(Acta=='ENTREGA'){
            window.location.href = '/acta';
          }else{
            window.location.href = '/actaDev';
          }
         
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function Asignar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  var acta = window.localStorage.getItem('acta');
  var datos = {cita:orden, asegurado:'Si',acta:acta}
  loading();
  axios({
    method: "post",
    url: uri + "appmovil/AgregarConductor",
    data: datos,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.msj,
            buttons: ['OK'],
          })
        
            window.location.href = '/conductor';
          
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function Verificar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  var acta = window.localStorage.getItem('acta');
  var datos = {cedula:CedulaD,acta:acta,orden:orden}
  loading();
  axios({
    method: "post",
    url: uri + "appmovil/verificarlicencia",
    data: datos,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.msj,
            buttons: ['OK'],
          })
          setLicencia('Si');
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}


function Salir() {
  localStorage.clear();
  window.location.href = "/";
}
function Listaentrega() {
  window.location.href = "/citas";
}
async function consultar() {
  //window.location.href = "https://www.runt.com.co/consultaCiudadana/#/consultaPersona";
  const url = "https://www.runt.com.co/consultaCiudadana/#/consultaPersona";
    window.open(url, "_blank");
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
function loading(){
  present({
    message: 'Enviando...',
    duration: 5000,
    cssClass: 'custom-loading'
  })
}
useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
const [present] = useIonLoading();
  return (
    <IonPage>
     
     <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           
              <IonImg
                className="rounded-pill"
                onClick={Entregas}
                src={require("../assets/images/backwhite.png")}
              ></IonImg>
              
           
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        {EstadoEntrega=='0' && EstadoDevolucion=='0'?
        <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/driver.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Asegurado</b>
                
                </div>
                <div className="toast-body  text-dark">
                  <IonRow >
                    <IonCol><IonIcon icon={informationCircle} /><b> Siniestro Numero</b></IonCol>
                    <IonCol>{isiniestro?isiniestro:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={homeOutline} /> <b> Aseguradora</b></IonCol>
                    <IonCol>{flota?flota:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={person} /> <b>Nombre Asegurado</b></IonCol>
                    <IonCol>{iasegurado?iasegurado:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={person} /> <b>Nombre Declarante</b></IonCol>
                    <IonCol>{ideclarante?ideclarante:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={carOutline} /><b> Placa Siniestrada</b></IonCol>
                    <IonCol>{iplaca?iplaca:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  {Conductor?'':
                  <IonButton expand='block' className='header' onClick={() =>
          presentAlert({
            header: 'El asegurado es el conductor?',
            buttons: [
              {
                text: 'Si',
                role: 'cancel',
                cssClass: 'alert-button-success',
                handler: () => {
                  setHandlerMessage('Proceso cancelado');
                  setCargando('0');
                  Asignar();
                  
                },
              },
              {
                text: 'No, agregar otro conductor. ',
                role: 'confirm',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  setHandlerMessage('Formulario Agregado');
                  setCargando('1');
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
          })} ><IonIcon icon={person} />  Asignar Conductor </IonButton>
        }
                </div>
              </div>
            </div>
       
          </div>
          
          :''}
          <br></br>
          {Conductor?
          <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/driver.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Conductor de {Acta}</b>
                
                </div>
                <div className="toast-body  text-dark">
                <IonRow>
                    <IonCol><IonIcon icon={informationCircle} /><b> Cedula</b></IonCol>
                    <IonCol>{iplaca?CedulaD:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow >
                    <IonCol><IonIcon icon={informationCircle} /><b> Nombre</b></IonCol>
                    <IonCol>{isiniestro?NombreD+' '+ApellidoD:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={informationCircle} /> <b> Telefono</b></IonCol>
                    <IonCol>{flota?TelefonoD:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={informationCircle} /> <b>Correo</b></IonCol>
                    <IonCol>{iasegurado?EmailD:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={informationCircle} /> <b>Licencia Verificada?</b></IonCol>
                    <IonCol>{iasegurado?
                     <IonButton  className='header' onClick={() =>
                      presentAlert({
                        header: 'Confirmas la licencia activa?',
                        buttons: [
                          {
                            text: 'Si',
                            role: 'cancel',
                            cssClass: 'alert-button-success',
                            handler: () => {
                            
                              setLicencia('Verificada');
                              Verificar();
                              
                            },
                          },
                          {
                            text: 'No ',
                            role: 'confirm',
                            cssClass: 'alert-button-confirm',
                            handler: () => {
                              setLicencia('Sin verificar');
                              //setCargando('1');
                            },
                          },
                        ],
                        onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
                      })} >{Licencia} </IonButton>:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                 
                  {EstadoEntrega=='0' && EstadoDevolucion=='0'?
                  <IonButton id="savekm" expand="block" className='header' onClick={Entrega}>
                              {Acta}
                          </IonButton>:''}
                          <br />
                          {EstadoDevolucion=='0' && Acta=='DEVOLUCION'?
                           <IonButton expand='block' className='header' onClick={() =>
                            presentAlert({
                              header: 'El conductor de entrega es el mismo de devolucion?',
                              buttons: [
                                {
                                  text: 'Si',
                                  role: 'cancel',
                                  cssClass: 'alert-button-success',
                                  handler: () => {
                                    setHandlerMessage('Proceso cancelado');
                                    setCargando('0');
                                    Asignar();
                                    
                                  },
                                },
                                {
                                  text: 'No, agregar otro conductor. ',
                                  role: 'confirm',
                                  cssClass: 'alert-button-confirm',
                                  handler: () => {
                                    setHandlerMessage('Formulario Agregado');
                                    setCargando('1');
                                  },
                                },
                              ],
                              onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
                            })} >
                              <IonIcon icon={person} />  Cambiar Conductor </IonButton>:
                              Licencia=='Si'?
                            <IonButton id="savekm" expand="block" className='header' onClick={Entrega}>
                              {Acta}
                          </IonButton>:''
                          }
                </div>
              </div>
            </div>
       
          </div>
          :''}
          {Cargando=='1'?
          <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/driver.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Conductor </b>
                
                </div>
                <div className="toast-body  text-dark">
                
          <IonRow >
                    <div className='col-4 p-2'>
                    <IonSelect value={Tipo} onIonChange={(e) => setTipo(e.detail.value!)}>
     
                    <IonSelectOption value="1">CC</IonSelectOption>
                    <IonSelectOption value="2">CE</IonSelectOption>
                    <IonSelectOption value="4">PASS</IonSelectOption>

                  </IonSelect>
                    </div>
                    <div className='col-8 p-2'>
                    <IonInput className='form-control'  placeholder="Cedula del conductor" id='km' name='km' value={Cedula}  onIonChange={(e) => setCedula(e.detail.value!)}/>
                    </div>
                  </IonRow>
               
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonInput className='form-control'  placeholder="Nombre del conductor" id='km' name='km'  value={Nombre}  onIonChange={(e) => setNombre(e.detail.value!)}/>
                    </div>
                  </IonRow>
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonInput className='form-control'  placeholder="Apellido del conductor" id='km' name='km'  value={Apellido}  onIonChange={(e) => setApellido(e.detail.value!)}/>
                    </div>
                  </IonRow>
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonInput className='form-control'  placeholder="Telefono del conductor" id='km' name='km'  value={Telefono}  onIonChange={(e) => setTelefono(e.detail.value!)}/>
                    </div>
                  </IonRow>
                  <IonRow >
                    <div className='col-12 p-2'>
                    <IonInput className='form-control'  placeholder="Email del conductor" id='km' name='km'  value={Email}  onIonChange={(e) => setEmail(e.detail.value!)}/>
                    </div>
                  </IonRow>
                  <IonRow >
                  <div className='col-8 p-2'>
                        Licencia Verificada
                    </div>
                    <div className='col-4 p-2'>
                    <IonSelect value={TipoLicencia} onIonChange={(e) => setTipoLicencia(e.detail.value!)}>
     
                    <IonSelectOption value="No">No</IonSelectOption>
                    <IonSelectOption value="Si">Si</IonSelectOption>

                  </IonSelect>
                    </div>
                    
                  </IonRow>
                  
                
                  <IonButton id="savekm" expand="block" className='header' onClick={AddConductor}>
                              GUARDAR
                          </IonButton>

                          <IonButton id="savekm" expand="block" className='btn-danger' onClick={CancelarConductor} >
                              CANCELAR
                          </IonButton>
                </div>
              </div>
            </div>
       
          </div>
          :''}
          <br />
          <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/driver.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Consultar Licencia</b>
                
                </div>
                <div className="toast-body  text-dark">
                <IonButton id="savekm" expand="block" className='header' onClick={consultar}>
                              Consultar Licencia
                          </IonButton>
                </div>
              </div>
            </div>
       
          </div>             
      </IonContent>
       <IonFooter className="ion-text-center">
       
        <IonButton  className='boton' onClick={Principal} ><IonIcon icon={home} /></IonButton>
     
      </IonFooter>
     

    </IonPage>
  );
};

export default Home;
