import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useIonViewWillEnter } from '@ionic/react';
import Conexio from '../services/Index';

const MyCanvasComponent : React.FC = () => {
  let uri = Conexio;
  
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isDrawing = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);
  const [imageDataURL, setImageDataURL] = useState<string | null>(null);
  const [Imagen1, setImagen1] = useState<any>("");
  const [Ladito, setLado] = useState<string>("");


  useEffect(() => {
    
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        
        var acta = window.localStorage.getItem('acta');
        var cita = window.localStorage.getItem('cita');
     
        var lado ='izquierdo';
        axios.get(uri+'appmovil/linea/'+cita+'/'+acta+'/'+lado)
            .then(function(res) {
              //alert(res.data.msj);
             console.log('iamge:'+res.data);
              var d = res.data;
    
        setImagen1(d.izquierdo);
        const img = new Image();
        img.src = d.izquierdo;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 10, 0);
        }; 
             
            })
            .catch(function(err) {
              //Swal.fire('Error');
              console.log(err);
            })
            .then(function() {
              //loading.style.display = 'none'
            });
         
        // Ajustamos el tamaño del canvas al tamaño de la pantalla
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // Agregamos eventos de ratón y eventos táctiles
        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', stopDrawing);
        canvas.addEventListener('mouseout', stopDrawing);

        canvas.addEventListener('touchstart', startDrawing);
        canvas.addEventListener('touchmove', draw);
        canvas.addEventListener('touchend', stopDrawing);
      }
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mousedown', startDrawing);
        canvas.removeEventListener('mousemove', draw);
        canvas.removeEventListener('mouseup', stopDrawing);
        canvas.removeEventListener('mouseout', stopDrawing);

        canvas.removeEventListener('touchstart', startDrawing);
        canvas.removeEventListener('touchmove', draw);
        canvas.removeEventListener('touchend', stopDrawing);
      }
    
    };
   
  }, []);

  const startDrawing = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    isDrawing.current = true;

    const [x, y] = getCoordinates(e);
    console.log(e);
    [lastX.current, lastY.current] = [x, y];
  };

  const draw = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    if (!isDrawing.current) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.strokeStyle = 'blue'; // Color de la línea
    ctx.lineWidth = 3;       // Grosor de la línea
    ctx.lineCap = 'round';   // Extremo redondeado de la línea

    const [x, y] = getCoordinates(e);

    ctx.beginPath();
    ctx.moveTo(lastX.current, lastY.current);
    ctx.lineTo(x, y);
    ctx.stroke();

    [lastX.current, lastY.current] = [x, y];
  };

  const stopDrawing = () => {
    isDrawing.current = false;
    // Obtener la imagen en base64 cuando se detiene el dibujo
    const canvas = canvasRef.current;
    if (canvas) {
      const imageDataURL = canvas.toDataURL('image/png');
      setImageDataURL(imageDataURL);
      //console.log(imageDataURL);
    }
  };

  const getCoordinates = (e: MouseEvent | TouchEvent): [number, number] => {
    let x = 0;
    let y = 0;

    const canvas = canvasRef.current;
    if (!canvas) return [x, y];

    const rect = canvas.getBoundingClientRect();

    if (e instanceof MouseEvent) {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    } else if (e instanceof TouchEvent) {
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    }

    return [x, y];
  };
 
  return (
    <div>
      <label>{Ladito}</label>
      <canvas ref={canvasRef}></canvas>
   
    </div>
  );
};

export default MyCanvasComponent;




