import {
  IonContent,
  useIonAlert,
  IonSelectOption,
  IonHeader,
  IonPage,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonViewWillEnter,
  IonAvatar,
  IonItemSliding,
  IonList,
  IonSkeletonText,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonCol,
  IonRow,
  IonRippleEffect,
  IonSelect,
  IonFab,
  IonFabButton,
  IonFabList,
  IonCheckbox,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import Swal from "sweetalert2";
import "./Cita.css";
import { useState } from "react";
import {
  add,
  calendar,
  eye,
  search,
  people,
  document,
  home,
  arrowBackSharp,
  arrowForward,
  hourglass,
  time,
  car,
  locate,
  locationSharp,
  logOut,
  man,
  chevronUpCircle,
  colorPalette,
  globe,
  reload,
} from "ionicons/icons";
import { userInfo } from "os";
import Home from "../pages/Home";
import { preguntas } from "../components/model/preguntas";
import axios from "axios";
import Conexio from "../services/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

const Cita: React.FC = () => {
  let n = window.localStorage.getItem("name_u");
  let o = window.localStorage.getItem("o");
  let ope = window.localStorage.getItem("key");
  let tk = window.localStorage.getItem("key_t");
  let placa = window.localStorage.getItem("placa");
  let cita = window.localStorage.getItem("cita");
  let tipo = window.localStorage.getItem("tipo");
  const [presentAlert] = useIonAlert();
  const [nombre, seNombre] = useState(n);
  const [tipoali, setTipo] = useState(tipo);

  const [idcita, setCita] = useState(cita);
  //let uri = 'https://pot.aoacolombia.com/api/';
  let uri = Conexio;
  const [listCitas, setlistCitas] = useState<preguntas[]>([]);
  const [Oficinas, setSucursales] = useState<any[]>([]);
  const [fechah, setFecha] = useState<string>(hoy);
  const [Estado, setEstado] = useState("");
  const [Observaciones, setObservaciones] = useState<string>("");
  const [Placa, setPlaca] = useState(placa);
  const [loaded, setLoaded] = useState(0);
  const [checkboxValues, setCheckboxValues] = useState<any[]>([]);
  const [observations, setObservations] = useState<any[]>([]);
  const [Cargando, setCargando] = useState<any>("0");
  async function Principal() {
    window.location.href = "/home";
  }
  async function funza() {
    window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
  }
  async function bogota() {
    window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function Devoluciones() {
    window.location.href = "/devoluciones";
  }
 
  const  getCitas = () => {
    let o = window.localStorage.getItem("key");
    
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "get",
      url: uri + "appmovil/listacheckeo",
      headers: { Authorization: tk },
    })
      .then(function (res) {
        
        setLoaded(1);
        if (res.status == 200) {
          console.log(res.data);
          
          setlistCitas(res.data.lista);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }

  async function sucursales() {
    let o = window.localStorage.getItem("o");
    const datos = { oficina: o };
    setLoaded(0);
    console.log(loaded);
    axios({
      method: "post",
      url: uri + "appmovil/sucursales",
      data: datos,
      headers: { Authorization: tk },
    })
      .then(function (res) {
        console.log(res);

        if (res.status == 200) {
          console.log(res.data.estado);
          var est = res.data.estado;
          if (est == 0) {
            window.location.href = "/";
          }
          setSucursales(res.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  const validateCheckboxes = () => {
    // Verifica si alguno de los checkboxes no está marcado
    const selectedCheckboxes = checkboxValues.filter(value => value === 'SI' || value === 'NO');
    return selectedCheckboxes.length;
  };
  function GuardarAlistamiento() {
    //console.log(checkboxValues);
    //console.log(observations);
    if(Estado==''){
      alert("Debes de seleccionar si el vehiculo se encuentra en buenas condiciones");
      setCargando(0);
      return false;
    }
    let da = {cita:idcita,placa:Placa,usuario:nombre, checbox:checkboxValues, obs:observations,tipo:tipoali,estado:Estado,observaciones:Observaciones}
    console.log(da);
    setCargando(1);
    const selectedCount = validateCheckboxes();
    
    if(selectedCount!=47){
      alert("Falta por seleccionar uno o mas items");
      setCargando(0);
      return false;
    }
    axios({
      method: "post",
      url: uri + "appmovil/savechecked",
      headers: { Authorization: tk },
      data: da
    })
      .then(function (res) {
        
        setLoaded(1);
        setCargando(0);
        if (res.status == 200) {
          console.log(res.data);
           
           alert(res.data.message);
           if(res.data.success == true){
              if(tipoali=='ENTREGA'){
                window.location.href = "/alistamiento";
              }else{
                window.location.href = "/alistamientod";
              }
              
           }
        }
      })
      .catch(function (err) {
        console.log(err);
        setCargando(0);
      })
      .then(function () {
        //loading.style.display = 'none';
      });
  }
  function Salir() {
    localStorage.clear();
    window.location.href = "/";
  }


  // Función para manejar el cambio de estado de los checkboxes
  const handleCheckboxChange = (index: number, value: string) => {
    // Copia del array de valores
    let updatedValues = [...checkboxValues];
    // Actualiza el valor en la posición del índice
    updatedValues[index] = value;
    // Actualiza el estado handleObservation
    setCheckboxValues(updatedValues);
    console.log(updatedValues);
    console.log(observations);
    if(value!=''){
      var d = {id:index,valor:value}
      console.log(d);
    }
    
  };
  const handleObservation = (index: number, value: string) => {
    // Copia del array de valores
    let updatedValueso = [...observations];
    // Actualiza el valor en la posición del índice
    updatedValueso[index] = value;
    // Actualiza el estado handleObservation
    setObservations(updatedValueso);
    console.log(updatedValueso);
    if(value!=''){
      var d = {id:index,valor:value}
      console.log(d);
    }
    
  };
  useIonViewWillEnter(() => {
    getCitas();

    if (!tk) {
      window.location.href = "/";
    }
  });
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={Principal}>
              <IonImg
                className="rounded-pill"
                style={{ width: 80 }}
                src={require("../assets/images/logomarca.png")}
              ></IonImg>
            </a>
            ALISTAMIENTO
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Devoluciones}>
                    Lista de Devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
     
        <div className="" id="collapseExample">
          <div className="card card-body">
          <IonRow>
          <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="asegurado"
                  id="asegurado"
                  value={nombre}
                  placeholder="Nombre del operario"
                
                ></IonInput>
              </IonCol>
          </IonRow>
            <IonRow>
            
              <IonCol>
                <IonInput
                  type="text"
                  className="form-control"
                  name="placa"
                  id="placa"
                  placeholder="Placa Siniestro"
                  value={Placa}
                 
                ></IonInput>
              </IonCol>
              <IonCol>
                
                <IonInput
                  type="date"
                  className="form-control"
                  name="fecha"
                  id="fecha"
                  value={fechah}
                  onIonChange={(e) => setFecha(e.detail.value!)}
                ></IonInput>
              </IonCol>

            </IonRow>
           
          </div>
        </div>

        {loaded == 1 ? (
          <IonList>
            {listCitas.map((citas, i) => (
              <IonItemSliding key={i}>
                <IonItem>
                  
                    <div className="ion-activatable ripple-parent ion-padding">
                      
                      <p>
                       
                        <b>
                        {citas.categoria}{" "}
                        <br></br>
                           {i + 1} <IonIcon icon={car} /> {citas.nombre}
                         
                        </b>{" "}
                        
                      </p>
                      <p>
                         {citas.criterio} {citas.id}
                      </p>
                      
                       
                      <IonItem>
            <IonCheckbox
              slot="start"
              checked={checkboxValues[i] === 'SI'}
              onIonChange={(e) => handleCheckboxChange(i, e.target.checked ? 'SI' : '')}
            />
            <IonLabel>SI</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              checked={checkboxValues[i] === 'NO'}
              onIonChange={(e) => handleCheckboxChange(i, e.target.checked ? 'NO' : '')}
            />
            <IonLabel>NO</IonLabel>
          </IonItem>
                    <br></br>
                    {checkboxValues[i] === 'NO' ? (
            <IonInput
              type="text"
              className="form-control"
              name="observacion"
              id={"observacion" + i}
              placeholder="Digite su observacion"
              value={observations[i]}
              onIonChange={(e) => handleObservation(i, e.detail.value!)}
            />
          ) : null}
                   
                    </div>
               
                 
                </IonItem>
              </IonItemSliding>
            ))}
          </IonList>
        ) : (
          ""
        )}
        {loaded == 0 ? (
          <IonList>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )}
        <p>
       
          
          <IonItem>
          <IonLabel>El carro se encuentra en buenas condiciones :</IonLabel>

          <IonSelect value={Estado} onIonChange={(e) => setEstado(e.detail.value!)}>
          
            <>
            <IonSelectOption value="Si">Si</IonSelectOption>
            <IonSelectOption value="No">No</IonSelectOption>
            </>
          
          </IonSelect>
        </IonItem>
        {Estado=='No'?
           <IonItem>
            <IonLabel position="stacked">observaciones:</IonLabel>
            <IonTextarea
                placeholder="Digite alguna observacion"
                autoGrow={true}
                value={Observaciones} onIonChange={(e) => setObservaciones(e.detail.value!)}
          ></IonTextarea>
          </IonItem>:''}
      
          <br></br>
          <div className="d-grid">
            <button
              className="btn btn-primary  btn-block"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-controls="collapseExample"
              onClick={GuardarAlistamiento}
            >
             { Cargando==0?'Guardar Alistamiento':<><IonSpinner color="light"></IonSpinner>Cargando ...</>}
            </button>
          </div>
        </p>
    
      </IonContent>

      <IonFooter className="ion-text-center">
        <IonButton className="boton" onClick={Principal}>
          <IonIcon icon={home} />
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Cita;
