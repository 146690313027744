import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,IonImg, IonItem, IonLabel, IonList, IonThumbnail, IonIcon, IonText,IonSpinner,useIonLoading  } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import '../theme/variables.css';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useState } from 'react';
import { arrowBack, bagCheck, camera, checkmark, homeOutline, save, warning } from 'ionicons/icons';
import frontal from '../assets/images/frontal.png';
import derecha from '../assets/images/derecha.png';
import izquierda from '../assets/images/izquierda.png';
import posterior from '../assets/images/posterior.png';
import tarjeta from '../assets/images/acta_mini.jpg';
import licencia from '../assets/images/veh.jpg';
import odometro from '../assets/images/odometro.png';
import cedula from '../assets/images/doc.png';
import axios from 'axios';
import Conexio from '../services/Index';
import GeolocationButton from '../components/GeolocationButton';

const HomeDev: React.FC = () => {
  let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let h = fechaactual[1].split("/");
let hora1 = h[0];

var cita = window.localStorage.getItem('cita');




let hoy =f[2].padStart(2, "0") +"-"+f[1].padStart(2, "0")+"-"+f[0].padStart(2, "0");
console.log(h[0]);
  var placa = window.localStorage.getItem('placa');
  const [imageno, setImageno] = useState<any>('');
  const [imagenf, setImagenf] = useState<any>('');
  const [imagend, setImagend] = useState<any>('');
  const [imageni, setImageni] = useState<any>('');
  const [imagenp, setImagenp] = useState<any>('');
  const [imagencf, setImagencf] = useState<any>('');
  const [imagencr, setImagencr] = useState<any>('');
  const [imagenlf, setImagenlf] = useState<any>('');
  const [imagenlr, setImagenlr] = useState<any>('');
  const [imagenadi, setImagenadi] = useState<any>('');
  const [Placa, setPlaca] = useState<any>(placa);

  var loc_h1 = window.localStorage.getItem('ho1_'+cita);
  var loc_h2 = window.localStorage.getItem('ho2_'+cita);
  var loc_h3 = window.localStorage.getItem('ho3_'+cita);
  var loc_h4 = window.localStorage.getItem('ho4_'+cita);
  var loc_h5 = window.localStorage.getItem('ho5_'+cita);
  var loc_h6 = window.localStorage.getItem('ho6_'+cita);
  var loc_h7 = window.localStorage.getItem('ho7_'+cita);
  var loc_h8 = window.localStorage.getItem('ho8_'+cita);
  var loc_h9 = window.localStorage.getItem('ho9_'+cita);
  var loc_h10 = window.localStorage.getItem('ho10_'+cita);


  const [Ho1, setHo1] = useState<any>(loc_h1?loc_h1:"");
  const [Ho2, setHo2] = useState<any>(loc_h2?loc_h2:"");
  const [Ho3, setHo3] = useState<any>(loc_h3?loc_h3:"");
  const [Ho4, setHo4] = useState<any>(loc_h4?loc_h4:"");
  const [Ho5, setHo5] = useState<any>(loc_h5?loc_h5:"");
  const [Ho6, setHo6] = useState<any>(loc_h6?loc_h6:"");
  const [Ho7, setHo7] = useState<any>(loc_h7?loc_h7:"");
  const [Ho8, setHo8] = useState<any>(loc_h8?loc_h8:"");
  const [Ho9, setHo9] = useState<any>(loc_h9?loc_h9:"");
  const [Ho10, setHo10] = useState<any>(loc_h10?loc_h10:"");
 
  const [Imagen1, setImagen1] = useState<any>("");
  const [Imagen2, setImagen2] = useState<any>("");
  const [Imagen3, setImagen3] = useState<any>("");
  const [Imagen4, setImagen4] = useState<any>("");
  const [Imagen5, setImagen5] = useState<any>("");
  const [Imagen6, setImagen6] = useState<any>("");
  const [Imagen7, setImagen7] = useState<any>("");
  const [Imagen8, setImagen8] = useState<any>("");
  const [Imagen9, setImagen9] = useState<any>("");
  const [Imagen10, setImagen10] = useState<any>("");


  const [Odometro, setOdometro] = useState<any>("");
  const [Frente, setFrente] = useState<any>("");
  const [Atras, setAtras] = useState<any>("");
  const [Izquierdo, setIzquierdo] = useState<any>("");
  const [Derecho, setDerecho] = useState<any>("");
  const [Licencia, setLicencia] = useState<any>("");
  const [LicenciaR, setLicenciaR] = useState<any>("");
  const [Cedula, setCedula] = useState<any>("");
  const [CedulaR, setCedulaR] = useState<any>("");
  const [Adicional, setAdicional] = useState<any>("");

  const [Nombreboton, setBoton] = useState<any>("0");
  const [Cargando, setCargando] = useState<any>("0");
  const [Resultado, setResultado] = useState<any>("0");

  let uri = Conexio;
  //let uri = 'https://pot.aoacolombia.com/api/';
  async function Entregas() {
    window.location.href = '/detalle_dev';
}
const Base1 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setOdometro(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base2 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setFrente(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base3 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setIzquierdo(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base4 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setAtras(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base5 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setDerecho(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base6 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setCedula(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base7 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setCedulaR(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base8 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setLicencia(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base9 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setLicenciaR(base64data); 
      resolve(base64data);
      
    }
  });
}

function UploadFoto(campo:string,imagen:string){
  //  setCargando(1);
  if(campo == 'odometro'){
    setHo1('Subiendo');
  }
  if(campo == 'frente'){
    setHo2('Subiendo');
  }
  if(campo == 'izquierdo'){
    setHo3('Subiendo');
  }
  if(campo == 'atras'){
    setHo4('Subiendo');
  }
  if(campo == 'derecho'){
    setHo5('Subiendo');
  }
  if(campo == 'acta'){
    setHo6('Subiendo');
  }
  if(campo == 'ad1'){
    setHo7('Subiendo');
  }
  if(campo == 'ad2'){
    setHo8('Subiendo');
  }
  if(campo == 'vehiculo'){
    setHo9('Subiendo');
  }
  if(campo == 'vehiculo2'){
    setHo10(hora1);
  }
    var orden = window.localStorage.getItem('cita');
    const datos = {
      campo: campo,
      imagen:imagen,
       Cita:orden,
       tipo:'Devolucion',
      };
    axios.post(uri+'appmovil/imagenes_dev', datos)
        .then(function(res) {
          setResultado(res.data);
   
          if(campo == 'odometro'){
            setImagen1(1);
            setHo1(hora1);
          }
          if(campo == 'frente'){
            setImagen2(1);
            setHo2(hora1);
          }
          if(campo == 'izquierdo'){
            setImagen3(1);
            setHo3(hora1);
          }
          if(campo == 'atras'){
            setImagen4(1);
            setHo4(hora1);
          }
          if(campo == 'derecho'){
            setImagen5(1);
            setHo5(hora1);
          }
          if(campo == 'acta'){
            setImagen6(1);
            setHo6(hora1);
          }
          if(campo == 'ad1'){
            setImagen7(1);
            setHo7(hora1);
          }
          if(campo == 'ad2'){
            setImagen8(1);
            setHo8(hora1);
          }
          if(campo == 'vehiculo'){
            setImagen9(1);
            setHo9(hora1);
          }
          if(campo == 'vehiculo2'){
            setImagen10(1);
            setHo10(hora1);
          }
          console.log(res.data);
        })
        .catch(function(err) {
          console.log('sin conexion james');
          if(campo == 'odometro'){
            setImagen1(2);
            setHo1('Intentalo Nuevamente');
          }
          if(campo == 'frente'){
            setImagen2(2);
            setHo2('Intentalo Nuevamente');
          }
          if(campo == 'izquierdo'){
            setImagen3(2);
            setHo3('Intentalo Nuevamente');
          }
          if(campo == 'atras'){
            setImagen4(2);
            setHo4('Intentalo Nuevamente');
          }
          if(campo == 'derecho'){
            setImagen5(2);
            setHo5('Intentalo Nuevamente');
          }
          if(campo == 'acta'){
            setImagen6(2);
            setHo6('Intentalo Nuevamente');
          }
          if(campo == 'ad1'){
            setImagen7(2);
            setHo7('Intentalo Nuevamente');
          }
          if(campo == 'ad2'){
            setImagen8(2);
            setHo8('Intentalo Nuevamente');
          }
          if(campo == 'vehiculo'){
            setImagen9(2);
            setHo9('Intentalo Nuevamente');
          }
          if(campo == 'vehiculo2'){
            setImagen10(2);
            setHo10('Intentalo Nuevamente');
          }
        })
        .then(function() {
          //loading.style.display = 'none';
        });
  
  }

  const takePictureo = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });

    var imageUrl: any = image.webPath;
    
    setImageno(imageUrl);
    
    console.log('Odometro');
    console.log(imageUrl);
    const data = await fetch(imageUrl);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setOdometro(base64data); 
      resolve(base64data);
      console.log(base64data);
      var base : any = base64data;
      //window.localStorage.setItem('odo_'+cita,base);
      UploadFoto('odometro',base);
      setHo1(hora1);
      window.localStorage.setItem('h1_'+cita,hora1);
    }
  });
    
  };
  const takePicturef = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    
    setImagenf(imageUrl);
    
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setFrente(base64data); 
        resolve(base64data);
        setHo2(hora1);
        window.localStorage.setItem('h2_'+cita,hora1);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('frente',base);
        //window.localStorage.setItem('fre_'+cita,base);
        
      }
    });

  };
  const takePictured = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;

    setImagend(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setDerecho(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        //window.localStorage.setItem('der_'+cita,base);
        setHo5(hora1);
        UploadFoto('derecho',base);
        window.localStorage.setItem('h5_'+cita,hora1);
      }
    });

  };
  const takePicturei = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;

    setImageni(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setIzquierdo(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('izquierdo',base);
       // window.localStorage.setItem('izq_'+cita,base);
        setHo3(hora1);
        window.localStorage.setItem('h3_'+cita,hora1);
      }
    });

  };
  const takePicturep = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    setImagenp(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setAtras(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('atras',base);
        //window.localStorage.setItem('atr_'+cita,base);
        setHo4(hora1);
        window.localStorage.setItem('h4_'+cita,hora1);
      }
    });

  };
  const takePicturecf = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    setImagencf(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setCedula(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('acta',base);
        //window.localStorage.setItem('act_'+cita,base);
        setHo6(hora1);
        window.localStorage.setItem('h6_'+cita,hora1);
      }
    });

  };
  const takePicturecr = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    setImagencr(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setCedulaR(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        //window.localStorage.setItem('ad1_'+cita,base);
        UploadFoto('ad1',base);
        setHo7(hora1);
        window.localStorage.setItem('h7_'+cita,hora1);
      }
    });

  };
  const takePicturelf = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    window.localStorage.setItem('ad2_'+cita,imageUrl);
    setImagenlf(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setLicencia(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('ad2',base);
        //window.localStorage.setItem('ad2_'+cita,base);
        setHo8(hora1);
        window.localStorage.setItem('h8_'+cita,hora1);
      }
    });

  };
  const takePicturelr = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    window.localStorage.setItem('veh_'+cita,imageUrl);
    setImagenlr(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setLicenciaR(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('vehiculo',base);
        //window.localStorage.setItem('veh_'+cita,base);
        setHo9(hora1);
        window.localStorage.setItem('h9_'+cita,hora1);
      }
    });

  };
  const takePictureadi = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl: any = image.webPath;
    window.localStorage.setItem('veh_'+cita,imageUrl);
    setImagenadi(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setAdicional(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
        UploadFoto('vehiculo2',base);
        //window.localStorage.setItem('veh_'+cita,base);
        setHo9(hora1);
        window.localStorage.setItem('h9_'+cita,hora1);
      }
    });

  };

  function Validar(){
    var orden = window.localStorage.getItem('cita');
    present({
      message: 'Validando...',
      duration: 4000,
      cssClass: 'custom-loading'
    })
   axios.get(uri+'appmovil/validarimagendev/'+orden)
       .then(function(res) {
        if(res.data.estado == 'success'){
          setBoton("1");
        }
        
         alert(res.data.msj);
         
        
       })
       .catch(function(err) {
         //Swal.fire('Error');
         console.log(err);
       })
       .then(function() {
         //loading.style.display = 'none';
       });
  }

  function ValidarBk(){
    if (imageno==''){
       alert("Debes de tomar la foto del odometro");
       return false;
    }
    //Base1(imageno).then();

     if (imagenf==''){
        alert("Debes de tomar la foto del frente vehiculo");
          return false;
      }
      //Base2(imagenf).then();

      if (imageni==''){
        alert("Debes de tomar la foto de lado izquierdo del vehiculo");
        return false;
      }
      //Base3(imageni).then();

      if (imagenp==''){
        alert("Debes de tomar la foto del lado atras del vehiculo");
        return false;
      }
      //Base4(imagenp).then();

      if (imagend==''){
        alert("Debes de tomar la foto del lado derecho del vehiculo");
        return false;
      }
      //Base5(imagend).then();
 
/*       if (imagencf==''){
        alert("Debes de tomar la foto del acta");
        return false;
      } */
      //Base6(imagencr).then();
/* 
      if (imagencr==''){
        alert("Debes de tomar la foto del documento adicional 1");
        return false;
      } */
     // Base7(imagenlf).then();
      
   /*    if (imagenlf==''){
        alert("Debes de tomar la foto del documento adicional 2 ");
        return false;
      } */
      //Base8(imagenlf).then();

     /*  if (imagenlr==''){
        alert("Debes de tomar la foto vehiculo adicional");
        return false;
      } */
      //Base9(imagenlr).then(); 

    setBoton("1");
  }
  function savefoto(){
    setCargando(1);
    var orden = window.localStorage.getItem('cita');
    var nick = window.localStorage.getItem('name_u');
    var ope = window.localStorage.getItem('key');
    var lat = window.localStorage.getItem('lat');
    var lon = window.localStorage.getItem('lon');
    console.log(Odometro);

    const datos = { Cita:orden,
                    lat:lat,
                    lon:lon,
                    Nusuario:nick,
};
    axios.post(uri+'appmovil/GuardarImagenDev', datos)
        .then(function(res) {
          //alert(res.data.msj);
          setResultado(res.data);
          alert(res.data.msj);
          window.localStorage.removeItem('cita');
          LimpiarStorage();
          window.location.href = "/devoluciones";

          console.log(res.data);
        })
        .catch(function(err) {
          //Swal.fire('Error');
          console.log(err);
        })
        .then(function() {
          //loading.style.display = 'none';
        });
  
  }
  function LimpiarStorage(){

    var cita = window.localStorage.getItem('cita');
    localStorage.removeItem('odo_'+cita);
    localStorage.removeItem('fre_'+cita);
    localStorage.removeItem('atr_'+cita);
    localStorage.removeItem('der_'+cita);
    localStorage.removeItem('izq_'+cita);
    localStorage.removeItem('act_'+cita);
    localStorage.removeItem('ad1_'+cita);
    localStorage.removeItem('ad2_'+cita);
    localStorage.removeItem('veh_'+cita);

    localStorage.removeItem('h1_'+cita);
    localStorage.removeItem('h2_'+cita);
    localStorage.removeItem('h3_'+cita);
    localStorage.removeItem('h4_'+cita);
    localStorage.removeItem('h5_'+cita);
    localStorage.removeItem('h6_'+cita);
    localStorage.removeItem('h7_'+cita);
    localStorage.removeItem('h8_'+cita);
    localStorage.removeItem('h9_'+cita);
  
  }
  const [present] = useIonLoading();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className='header'>
        <IonButton  className='boton' onClick={Entregas} slot="start"><IonIcon icon={arrowBack} /></IonButton>
          <IonTitle>Fotos de Devolucion {Placa}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >
      <GeolocationButton />
        <IonContent fullscreen className='ion-padding body'>
          <form id='formulario'>
     
        <IonList>
          <IonItem>
            <IonThumbnail slot="start">
            {imageno?
            <IonImg src={Imagen1==2?odometro:imageno}></IonImg>:
            <IonImg src={odometro}></IonImg>
            }
            
              
            </IonThumbnail>
            <IonLabel>
              ODOMETRO
              <br />
              {imageno?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen1?Ho1:'Subiendo..'}</sub></IonText>:''}
              
            </IonLabel>
            
            <IonButton size="default" color={Imagen1?Imagen1==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo()}><IonIcon icon={Imagen1?Imagen1==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagenf?
            <IonImg src={Imagen2==2?frontal:imagenf}></IonImg>:
            <IonImg src={frontal}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>FRENTE
            <br />
              {imagenf?<IonText color={Imagen2==2?'danger':'success'}><sub>{Imagen2?Ho2:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen2?Imagen2==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturef()}><IonIcon icon={Imagen2?Imagen2==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imageni?
            <IonImg src={Imagen3==2?izquierda:imageni}></IonImg>:
            <IonImg src={izquierda}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>IZQUIERDA
            <br />
              {imageni?<IonText color={Imagen3==2?'danger':'success'}><sub>{Imagen3?Ho3:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen3?Imagen3==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturei()}><IonIcon icon={Imagen3?Imagen3==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagenp?
            <IonImg src={Imagen4==2?posterior:imagenp}></IonImg>:
            <IonImg src={posterior}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>ATRAS
            <br />
              {imagenp?<IonText color={Imagen4==2?'danger':'success'}><sub>{Imagen4?Ho4:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen4?Imagen4==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturep()}><IonIcon icon={Imagen4?Imagen4==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagend?
            <IonImg src={Imagen5==2?derecha:imagend}></IonImg>:
            <IonImg src={derecha}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>DERECHA
            <br />
              {imagend?<IonText color={Imagen5==2?'danger':'success'}><sub>{Imagen5?Ho5:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen5?Imagen5==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictured()}><IonIcon icon={Imagen5?Imagen5==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagencf?
            <IonImg src={Imagen6==2?tarjeta:imagencf}></IonImg>:
            <IonImg src={tarjeta}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Acta Devolucion
            <br />
              {imagencf?<IonText color={Imagen6==2?'danger':'success'}><sub>{Imagen6?Ho6:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen6?Imagen6==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturecf()}><IonIcon icon={Imagen6?Imagen6==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagencr?
            <IonImg src={Imagen7==2?cedula:imagencr}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Documento Adicional 1
            <br />
              {imagencr?<IonText color={Imagen7==2?'danger':'success'}><sub>{Imagen7?Ho7:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen7?Imagen7==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturecr()}><IonIcon icon={Imagen7?Imagen7==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagenlf?
            <IonImg src={Imagen8==2?cedula:imagenlf}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Documento Adicional 2
            <br />
              {imagenlf?<IonText color={Imagen8==2?'danger':'success'}><sub>{Imagen8?Ho8:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen8?Imagen8==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturelf()}><IonIcon icon={Imagen8?Imagen8==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenlr?
            <IonImg src={Imagen9==2?licencia:imagenlr}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Vehiculo Adicional 1
            <br />
              {imagenlr?<IonText color={Imagen9==2?'danger':'success'}><sub>{Imagen9?Ho9:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen9?Imagen9==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturelr()}><IonIcon icon={Imagen9?Imagen9==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenadi?
            <IonImg src={Imagen10==2?licencia:imagenadi}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Vehiculo Adicional 2
            <br />
              {imagenadi?<IonText color={Imagen10==2?'danger':'success'}><sub>{Imagen10?Ho10:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen10?Imagen10==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureadi()}><IonIcon icon={Imagen10?Imagen10==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
        </IonList>    
        {Nombreboton=="0"?
        <IonButton type='button'  className='header' expand="block" onClick={Validar}><IonIcon icon={checkmark} /> Validar Imagenes</IonButton>:
        Cargando=="0"?
          <IonButton type='button'  className='boton' expand="block" onClick={savefoto}><IonIcon icon={save} /> Concluir Servicio</IonButton>:
          <IonButton type='button'  className='boton' expand="block" ><IonSpinner color="light"></IonSpinner> Cargando ...</IonButton>
        }
      
        </form>      

         
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default HomeDev;
