import React, { useState } from 'react';
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonImg } from '@ionic/react';
import './Style.css';

interface ImageZoomModalProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageZoomModal: React.FC<ImageZoomModalProps> = ({ imageUrl, onClose }) => {
  const [zoomed, setZoomed] = useState(false);

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  return (
    <IonModal isOpen={true} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>Cerrar</IonButton>
          </IonButtons>
          <IonTitle>Ver Imagen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={`${zoomed ? 'zoomed' : ''}`} onClick={toggleZoom}>
          <IonImg src={imageUrl} alt="Imagen" />
        </div> 
        <iframe 
          title="Mi Iframe"
          width="100%"
          height="400px"
          src={imageUrl}
          frameBorder="0"
        ></iframe>
      </IonContent>
    </IonModal>
  );
};

export default ImageZoomModal;