import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, IonSpinner, IonItemSliding, IonRadio, IonRadioGroup, IonItemDivider, IonCheckbox, IonFab, IonFabButton, IonText, IonThumbnail, IonSelect, IonSelectOption, IonGrid  } from '@ionic/react';
import './Style.css';
import { useState,useRef,useEffect } from 'react';
import { Camera, CameraResultType,CameraSource } from '@capacitor/camera';
import { warning, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, save, cellular, mail, keySharp, checkmark, camera, eyedrop } from 'ionicons/icons';
import axios from 'axios';
import Modal from './Zoom';
import Conexio from '../services/Index';



import frontal from '../assets/images/frontal.png';
import derecha from '../assets/images/derecha.png';
import izquierda from '../assets/images/izquierda.png';
import posterior from '../assets/images/posterior.png';
import tarjeta from '../assets/images/tarjeta.png';
import licencia from '../assets/images/licencia.png';
import odometro from '../assets/images/odometro.png';
import cedula from '../assets/images/cedula.png';
import adicional from '../assets/images/veh.jpg';
import extintor from '../assets/images/extintor.png';
import "bootstrap/dist/css/bootstrap.min.css";
const Actas: React.FC = () => {
  let fechaactual = new Date().toLocaleString().split(",");
  let f = fechaactual[0].split("/");
  let h = fechaactual[1].split("/");
  let hora1 = h[0];
  const [Loading, setLoading] = useState<any>(0);
  const [LoadingCanvas, setLoadingCanvas] = useState<any>(0);
  //Obseraciones por item
  const [ObsNombre, setObsNombre] = useState<any>("");
  const [ObsId, setObsId] = useState<any>("");
  const [ObsSel, setObsSel] = useState<any>("");
  const [Obs, setObs] = useState<any>("");
  const [showModal2, setShowModal2] = useState(false);
  const [Next, setNext] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [presentAlert] = useIonAlert();
  var cita = window.localStorage.getItem('cita');
  const [Lista, setLista] = useState<any[]>([]);
  const [ListaObservaciones, setObservaciones] = useState<any[]>([]);

  const [Gasolina, setGasolina] = useState(0);
  const [Celular, setCelular] = useState("");
  const [Usuario, setUsuario] = useState("");
  const [Clave, setClave] = useState("");
  const [Clave2, setClave2] = useState("");
  const [Observaciones, setObskm] = useState("");
  const [Estado, setEstado] = useState("");
  const [checkboxValues, setCheckboxValues] = useState([false, false, false]);
  // carga la imagen
  const [imageno, setImagen] = useState<any>("");
  const [imageno_izq, setImagen_izq] = useState<any>("");
  const [imageno_atr, setImagen_atr] = useState<any>("");
  const [imageno_der, setImagen_der] = useState<any>("");
  const [imageno_fre, setImagen_fre] = useState<any>("");
  const [imagencf, setImagencf] = useState<any>("");
  const [imagencr, setImagencr] = useState<any>("");
  const [imagenlf, setImagenlf] = useState<any>("");
  const [imagenlr, setImagenlr] = useState<any>("");

  const [imagenad1, setImagenad1] = useState<any>("");
  const [imagenad2, setImagenad2] = useState<any>("");
  const [imagenaext, setImagenext] = useState<any>("");

  const [imagenadi3, setImagenAdi3] = useState<any>("");
  const [imagenadi4, setImagenAdi4] = useState<any>("");
  const [imagenaut, setImagenAut] = useState<any>("");
  const [imagenpol, setImagenPol] = useState<any>("");
  const [imagencom, setImagenCom] = useState<any>("");
  const [imagental, setImagenTal] = useState<any>("");
  
  //fin  del almcenamiento de imagenes
  const [Foto, setFoto] = useState<any>("");
  const [Nombreboton, setBoton] = useState<any>("0");
  const [Cambio, setCambio] = useState<any>("0");
  const [roleMessage, setRoleMessage] = useState('');
  const [Cargando, setCargando] = useState<any>("0");
  const [Cargandof, setCargandof] = useState<any>("0");
  const [Lado, setLado] = useState<any>("INTERNO");
  const [Cita, setCita] = useState<any>(cita);
  const [Nivel, setNivel] = useState<any>("0");
  const [Km, setKm] = useState<any>("");
  const [Acta, setActa] = useState<any>(window.localStorage.getItem('acta'));
  const [UltKm, setUltkm] = useState<any>(window.localStorage.getItem('odo'));
  const [Imagen1, setImagen1] = useState<any>("");
  const [Imagen2, setImagen2] = useState<any>("");
  const [Imagen3, setImagen3] = useState<any>("");
  const [Imagen4, setImagen4] = useState<any>("");
  const [Imagen5, setImagen5] = useState<any>("");
  const [Imagen6, setImagen6] = useState<any>("");
  const [Imagen7, setImagen7] = useState<any>("");
  const [Imagen8, setImagen8] = useState<any>("");
  const [Imagen9, setImagen9] = useState<any>("");
  const [Imagen10, setImagen10] = useState<any>("");
  const [Imagen11, setImagen11] = useState<any>("");
  const [Imagen12, setImagen12] = useState<any>("");
  //Imagenes extras 
 
  const [Imagen13, setImagen13] = useState<any>("");
  const [Imagen14, setImagen14] = useState<any>("");
  const [Imagen15, setImagen15] = useState<any>("");
  const [Imagen16, setImagen16] = useState<any>("");
  const [Imagen17, setImagen17] = useState<any>("");
  const [Imagen18, setImagen18] = useState<any>("");
  const [Imagen19, setImagen19] = useState<any>("");
  const [Imagen20, setImagen20] = useState<any>("");

  const [Img1, setImg1] = useState("");
  const [Img2, setImg2] = useState("");
  const [Img3, setImg3] = useState("");
  const [Img4, setImg4] = useState("");
  const [Img5, setImg5] = useState("");
  const [Img6, setImg6] = useState("");
  const [Img7, setImg7] = useState("");

  var loc_h1 = window.localStorage.getItem('ho1_'+cita);
  var loc_h2 = window.localStorage.getItem('ho2_'+cita);
  var loc_h3 = window.localStorage.getItem('ho3_'+cita);
  var loc_h4 = window.localStorage.getItem('ho4_'+cita);
  var loc_h5 = window.localStorage.getItem('ho5_'+cita);
  var loc_h6 = window.localStorage.getItem('ho6_'+cita);
  var loc_h7 = window.localStorage.getItem('ho7_'+cita);
  var loc_h8 = window.localStorage.getItem('ho8_'+cita);
  var loc_h9 = window.localStorage.getItem('ho9_'+cita);
  var loc_h10 = window.localStorage.getItem('ho10_'+cita);
  var loc_h11 = window.localStorage.getItem('ho11_'+cita);
  var loc_h12 = window.localStorage.getItem('ho12_'+cita);

  var loc_h13 = window.localStorage.getItem('ho13_'+cita);
  var loc_h14 = window.localStorage.getItem('ho14_'+cita);
  var loc_h15 = window.localStorage.getItem('ho15_'+cita);
  var loc_h16 = window.localStorage.getItem('ho16_'+cita);
  var loc_h17 = window.localStorage.getItem('ho17_'+cita);
  var loc_h18 = window.localStorage.getItem('ho18_'+cita);
  var loc_h19 = window.localStorage.getItem('ho19_'+cita);
  var loc_h20 = window.localStorage.getItem('ho20_'+cita);

  const [Ho1, setHo1] = useState<any>(loc_h1?loc_h1:"");
  const [Ho2, setHo2] = useState<any>(loc_h2?loc_h2:"");
  const [Ho3, setHo3] = useState<any>(loc_h3?loc_h3:"");
  const [Ho4, setHo4] = useState<any>(loc_h4?loc_h4:"");
  const [Ho5, setHo5] = useState<any>(loc_h5?loc_h5:"");
  const [Ho6, setHo6] = useState<any>(loc_h6?loc_h6:"");
  const [Ho7, setHo7] = useState<any>(loc_h7?loc_h7:"");
  const [Ho8, setHo8] = useState<any>(loc_h8?loc_h8:"");
  const [Ho9, setHo9] = useState<any>(loc_h9?loc_h9:"");
  const [Ho10, setHo10] = useState<any>(loc_h10?loc_h10:"");
  const [Ho11, setHo11] = useState<any>(loc_h11?loc_h11:"");
  const [Ho12, setHo12] = useState<any>(loc_h12?loc_h12:"");

  const [Ho13, setHo13] = useState<any>(loc_h13?loc_h13:"");
  const [Ho14, setHo14] = useState<any>(loc_h14?loc_h14:"");
  const [Ho15, setHo15] = useState<any>(loc_h15?loc_h15:"");
  const [Ho16, setHo16] = useState<any>(loc_h16?loc_h16:"");
  const [Ho17, setHo17] = useState<any>(loc_h17?loc_h17:"");
  const [Ho18, setHo18] = useState<any>(loc_h18?loc_h18:"");
  const [Ho19, setHo19] = useState<any>(loc_h19?loc_h19:"");
  const [Ho20, setHo20] = useState<any>(loc_h20?loc_h20:"");

  const [Odometro, setOdometro] = useState<any>("");

  const [inputValues, setInputValues] = useState([]);
  const [textareaValue, setTextareaValue] = useState('');
  const [PasarId, setPasarId] = useState('');
  const handleCheckboxChange = (index: number, isChecked: boolean, nombre: string, id:number) => {
    const newCheckboxValues = [...checkboxValues];
    newCheckboxValues[index] = isChecked;
    setCheckboxValues(newCheckboxValues);
    console.log(newCheckboxValues[index]);
    let selecionado = newCheckboxValues[index];
    var o = '';
    if(selecionado == true){
      setObsNombre(nombre);
      setObsId(id);
      setObsSel(selecionado);
      //setShowModal2(true)    mostrar modal de observaciones.
    }else{
      setObs("");
     
      
    }
    setNext(true);
    Marcar(nombre, id, selecionado,o);
  };
  const handleCheckboxChange_up = (index: number, isChecked: boolean, nombre: string, id:number) => {
    const newCheckboxValues = [...checkboxValues];
    newCheckboxValues[index] = isChecked;
    setCheckboxValues(newCheckboxValues);
    console.log('seleccionado:'+newCheckboxValues[index]);
    let selecionado = newCheckboxValues[index];
    var o = '';
    if(selecionado == true){
      setObsNombre(nombre);
      setObsId(id);
      setObsSel(selecionado);
      //setShowModal2(true)    mostrar modal de observaciones.
    }else{
      setObs("");
     
      
    }
    setNext(true);
    Marcar(nombre, id, selecionado,o);
  };

  async function PasarMarcar(){
    
    //Marcar(ObsNombre, ObsId, ObsSel,Obs);
   /*  if(textareaValue==''){
      alert("Debes de escribir alguna observacion");
      return false;
    } */
    setShowModal2(false);
  }
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleRadioChange = (event: CustomEvent) => {
    setSelectedValue(event.detail.value);
    MarcarGasolina(event.detail.value);
  };


  const takePictureo = async (lado:string) => {
    const image = await Camera.getPhoto({
      quality: 80,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera
    });

    var imageUrl:any = image.webPath;
    
    switch(lado){
      case 'INTERNO':
        setImagen(imageUrl);
      break;
      case 'FRENTE':
        setImagen_fre(imageUrl);
      break;
      case 'IZQUIERDO':
        setImagen_izq(imageUrl);
      break;
      case 'ATRAS':
        setImagen_atr(imageUrl);
      break;
      case 'DERECHO':
        setImagen_der(imageUrl);
      break;
      case 'CEDULA':
        setImagencf(imageUrl);
      break;
      case 'CEDULA2':
        setImagencr(imageUrl);
      break;
      case 'LICENCIA':
        setImagenlf(imageUrl);
      break;
      case 'LICENCIA2':
        setImagenlr(imageUrl);
      break;
      case 'ADC1':
        setImagenad1(imageUrl);
      break;
      case 'ADC2':
        setImagenad2(imageUrl);
      break;
      case 'EXTINTOR':
        setImagenext(imageUrl);
      break;

      case 'LICENCIA3':
        setImagenAdi3(imageUrl);
      break;
      case 'LICENCIA4':
        setImagenAdi4(imageUrl);
      break;
      case 'AUTORIZACION':
        setImagenAut(imageUrl);
      break;
      case 'POLIZA':
        setImagenPol(imageUrl);
      break;
      case 'CAMARA':
        setImagenCom(imageUrl);
      break;
      case 'ORDEN':
        setImagenTal(imageUrl);
      break;


    }
    const data = await fetch(imageUrl);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      //setOdometro(base64data); 
      resolve(base64data);
      //console.log(base64data);
      var base : any = base64data;

      switch(lado){
        case 'INTERNO':
          UploadFoto('odometro',base);
        break;
        case 'FRENTE':
          UploadFoto('frente',base);
        break;
        case 'IZQUIERDO':
          UploadFoto('izquierdo',base);
        break;
        case 'ATRAS':
          UploadFoto('atras',base);
        break;
        case 'DERECHO':
          UploadFoto('derecho',base);
        break;
        case 'CEDULA':
          UploadFoto('cedula',base);
        break;
        case 'CEDULA2':
          UploadFoto('cedular',base);
        break;
        case 'LICENCIA':
          UploadFoto('licencia',base);
        break;
        case 'LICENCIA2':
          UploadFoto('licenciar',base);
        break;
        case 'ADC1':
          UploadFoto('Adicional1',base);
        break;
        case 'ADC2':
          UploadFoto('Adicional2',base);
        break;
        case 'EXTINTOR':
          UploadFoto('extintor',base);
        break;

        case 'LICENCIA3':
          UploadFoto('Adicional3',base);
        break;
        case 'LICENCIA4':
          UploadFoto('Adicional4',base);
        break;
        case 'AUTORIZACION':
          UploadFoto('Autorizacion',base);
        break;
        case 'POLIZA':
          UploadFoto('Poliza',base);
        break;
        case 'CAMARA':
          UploadFoto('Comercio',base);
        break;
        case 'ORDEN':
          UploadFoto('Taller',base);
        break;
      

      }
     
     // window.localStorage.setItem('odo_'+cita,base);
      window.localStorage.setItem('ho1_'+cita,hora1);
    }
  });
    
  };

  function UploadFoto(campo:string,imagen:string){
    //  setCargando(1);
    if(campo == 'odometro'){
   
      setHo1('Subiendo');
    }
    if(campo == 'izquierdo'){
   
      setHo2('Subiendo');
    }
    if(campo == 'atras'){
    
      setHo3('Subiendo');
    }
    if(campo == 'derecho'){
   
      setHo4('Subiendo');
    }
    if(campo == 'frente'){
      
      setHo5('Subiendo');
    }
    if(campo == 'cedula'){
      
      setHo6('Subiendo');
    }
    if(campo == 'cedular'){
      
      setHo7('Subiendo');
    }
    if(campo == 'licencia'){
      
      setHo8('Subiendo');
    }
    if(campo == 'licenciar'){
      
      setHo9('Subiendo');
    }
    if(campo == 'Adicional1'){
      
      setHo10('Subiendo');
    }
    if(campo == 'Adicional2'){
      
      setHo11('Subiendo');
    }
    if(campo == 'extintor'){
      setHo12('Subiendo');
    }
    if(campo == 'Adicional3'){
      setHo13('Subiendo');
    }
    if(campo == 'Adicional4'){
      setHo14('Subiendo');
    }
    if(campo == 'Autorizacion'){
      setHo15('Subiendo');
    }
    if(campo == 'Poliza'){
      setHo16('Subiendo');
    }
    if(campo == 'Comercio'){
      setHo17('Subiendo');
    }
    if(campo == 'Taller'){
      setHo18('Subiendo');
    }
    setNext(true);
      var orden = window.localStorage.getItem('cita');
      const datos = {
        campo: campo,
        imagen:imagen,
         Cita:orden,
         tipo:Acta,
        };
        var ur = 'imagenes';
        if(Acta == 'DEVOLUCION'){
          ur = 'imagenes_dev';
        }
      axios.post(uri+'appmovil/'+ur, datos)
          .then(function(res) {
           
            if(campo == 'odometro'){
              setImagen1(1);
              setHo1(hora1);
            }
            if(campo == 'izquierdo'){
              setImagen2(1);
              setHo2(hora1);
            }
            if(campo == 'atras'){
              setImagen3(1);
              setHo3(hora1);
            }
            if(campo == 'derecho'){
              setImagen4(1);
              setHo4(hora1);
            }
            if(campo == 'frente'){
              setImagen5(1);
              setHo5(hora1);
            }
            if(campo == 'cedula'){
              setImagen6(1);
              setHo6(hora1);
            }
            if(campo == 'cedular'){
              setImagen7(1);
              setHo7(hora1);
            }
            if(campo == 'licencia'){
              setImagen8(1);
              setHo8(hora1);
            }
            if(campo == 'licenciar'){
              setImagen9(1);
              setHo9(hora1);
            }
            if(campo == 'Adicional1'){
              setImagen10(1);
              setHo10(hora1);
            }
            if(campo == 'Adicional2'){
              setImagen11(1);
              setHo11(hora1);
            }
            if(campo == 'extintor'){
              setImagen12(1);
              setHo12(hora1);
            }

            if(campo == 'Adicional3'){
              setImagen13(1);
              setHo13(hora1);
            }
            if(campo == 'Adicional4'){
              setImagen14(1);
              setHo14(hora1);
            }
            if(campo == 'Autorizacion'){
              setImagen15(1);
              setHo15(hora1);
            }
            if(campo == 'Poliza'){
              setImagen16(1);
              setHo16(hora1);
            }
            if(campo == 'Comercio'){
              setImagen17(1);
              setHo17(hora1);
            }
            if(campo == 'Taller'){
              setImagen18(1);
              setHo18(hora1);
            }
            console.log(res.data);
            setNext(false);
          })
          .catch(function(err) {
            if(campo == 'odometro'){
              setImagen1(2);
              setHo1('Intentalo Nuevamente');
            }
            if(campo == 'izquierdo'){
              setImagen2(2);
              setHo2('Intentalo Nuevamente');
            }
            if(campo == 'atras'){
              setImagen3(2);
              setHo3('Intentalo Nuevamente');
            }
            if(campo == 'derecho'){
              setImagen4(2);
              setHo4('Intentalo Nuevamente');
            }
            if(campo == 'frente'){
              setImagen5(2);
              setHo5('Intentalo Nuevamente');
            }
            if(campo == 'cedula'){
              setImagen6(2);
              setHo6('Intentalo Nuevamente');
            }
            if(campo == 'cedular'){
              setImagen7(2);
              setHo7('Intentalo Nuevamente');
            }
            if(campo == 'licencia'){
              setImagen8(2);
              setHo8('Intentalo Nuevamente');
            }
            if(campo == 'licenciar'){
              setImagen9(2);
              setHo9('Intentalo Nuevamente');
            }
            if(campo == 'Adicional1'){
              setImagen10(2);
              setHo10('Intentalo Nuevamente');
            }
            if(campo == 'Adicional2'){
              setImagen11(2);
              setHo11('Intentalo Nuevamente');
            }
            if(campo == 'extintor'){
              setImagen12(2);
              setHo12('Intentalo Nuevamente');
            }

            if(campo == 'Adicional3'){
              setImagen13(2);
              setHo13('Intentalo Nuevamente');
            }
            if(campo == 'Adicional4'){
              setImagen14(2);
              setHo14('Intentalo Nuevamente');
            }
            if(campo == 'Autorizacion'){
              setImagen15(2);
              setHo15('Intentalo Nuevamente');
            }
            if(campo == 'Poliza'){
              setImagen16(2);
              setHo16('Intentalo Nuevamente');
            }
            if(campo == 'Comercio'){
              setImagen17(2);
              setHo17('Intentalo Nuevamente');
            }
            if(campo == 'Taller'){
              setImagen18(2);
              setHo18('Intentalo Nuevamente');
            }
            setNext(false);
          })
          .then(function() {
            //loading.style.display = 'none';
            setNext(false);
          });
    
    }

  const Marcar = async (n:string, id:number, selecionado:boolean,obs:string) =>{
    var cita = window.localStorage.getItem('cita');
     //var pre = window.document.getElementById("pre"+id)! as HTMLInputElement;
     //const campoValor = pre.value;
     // Imprimir el valor en la consola
     //console.log(campoValor);
     if(selecionado==true){
/*       if(obs==''){
        alert("Debes de escribir una observacion");
        return false;
      } */
   }
   setShowModal2(false);
     const datos = { cita: cita, nombre: n, id: id, acta:Acta,selecionado:selecionado,obs:obs};
  axios.post(uri+'appmovil/respuestas',datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        setNext(false);
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        setNext(false);
        //loading.style.display = 'none'
      });
  }
  const ValidarObservaciones = async (LADITO:string) =>{
    var cita = window.localStorage.getItem('cita');
 
     const datos = { cita: cita, acta:Acta,lado:Lado};
  axios.post(uri+'appmovil/validacionobs',datos)
      .then(function(res) {
        //alert(res.data.msj);
        var cc = res.data.cantidad;
        if(cc>0){
          setShowModal2(true);
          setObservaciones(res.data.validacion);
        }else{
          //setTextareaValue('');
        }
        setLado(LADITO);
        DetalleCita();
        PintarCanvas(LADITO);
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
  }
  const MarcarGasolina = async (id:number) =>{
    var cita = window.localStorage.getItem('cita');
     setNivel(id);
     const datos = { cita: cita, nivel: id, acta:Acta};
  axios.post(uri+'appmovil/respuestasGas',datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
  }

  const Base1 = async (i:string) => {
    const data = await fetch(i);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setFoto(base64data); 
        resolve(base64data);
        
      }
    });
  }


  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;

  const modal = useRef<HTMLIonModalElement>(null);


  async function Entregas() {
    if(Acta=='ENTREGA'){
      window.location.href = '/detalle';
    }else{
      window.location.href = '/detalle_dev';
    }
     
  }

function DetalleCita(){
  var usuario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  var cita = window.localStorage.getItem('cita');
  const datos = { cita: cita };
  axios.post(uri+'appmovil/preguntas',datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        var d = res.data.lista;
        var s = res.data.siniestro;
        var a = res.data.acta;
       /*  if(a.odometro_inicial!=''){
          setKm(a.odometro_inicial);
        } */
        setLoading(1);
        cargarimagen(s);
        setLista(d);
        const resultado = d.map((elemento:any,i:number) => {
          setCheckboxValues((prevCheckboxValues) => {
            const updatedCheckboxValues = [...prevCheckboxValues];
            updatedCheckboxValues[i] = elemento.respuesta_entrega=='No'?false:true;
            return updatedCheckboxValues;
          });
        });
       
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
}
function cargarimagen(s:any){
  let aws = 'https://app.aoacolombia.com/Control/operativo/img/fotocargada.png';
  if(s.img_odo_salida_f!=""){
    setImagen1(1);
    //setImagen(aws+s.img_odo_salida_f);
    setImagen(aws);
    setHo1('ok');
  }
  if(s.fotovh2_f!=""){
    setImagen2(1);
    setImagen_izq(aws);
    setHo2('ok');
  }
  if(s.fotovh3_f!=""){
    setImagen3(1);
    setImagen_der(aws);//+s.fotovh3_f
    setHo3('ok');
  }
  if(s.fotovh4_f!=""){
    setImagen4(1);
    setImagen_atr(aws);//+s.fotovh4_f
    setHo4('ok');
  }
  if(s.fotovh1_f!=""){
    setImagen5(1);
    setImagen_fre(aws);//+s.fotovh1_f
    setHo5('ok');
  }
  
if(s.img_cedula_f!=""){
  setImagen6(1);
  setImagencf(aws);
  setHo6('ok');
}
if(s.img_pase_f!=""){
  setImagen7(1);
  setImagencr(aws);
  setHo7('ok');
}

if(s.adicional1_f!=""){
  setImagen8(1);
  setImagenlf(aws);
  setHo8('ok');
}
if(s.adicional2_f!=""){
  setImagen9(1);
  setImagenlr(aws);
  setHo9('ok');
}

  if(s.img_extintor_ent_f!=""){
    setImagen12(1);
    setImagenext(aws);//+s.img_extintor_ent_f
    setHo12('ok');
  }

  
  if(s.adicional3_f!=""){
    setImagen13(1);
    setImagenAdi3(aws);
    setHo13('ok');
  }
  if(s.adicional4_f!=""){
    setImagen14(1);
    setImagenAdi4(aws);
    setHo14('ok');
  }
  if(s.img_carta_autorizacion_f!=""){
    setImagen15(1);
    setImagenAut(aws);
    setHo15('ok');
  }
  if(s.img_fotocopia_poliza_f!=""){
    setImagen16(1);
    setImagenPol(aws);
    setHo16('ok');
  }
  if(s.img_camara_comercio_f!=""){
    setImagen17(1);
    setImagenCom(aws);
    setHo17('ok');
  }
  if(s.img_orden_ingreso_taller_f!=""){
    setImagen18(1);
    setImagenTal(aws);
    setHo18('ok');
  }

}
function VerImagen(){
  var orden = window.localStorage.getItem('cita');
  var token = window.localStorage.getItem('key_t');
  axios({
    method: "get",
    url: uri + "appmovil/ver/"+orden,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
         let d = res.data.citas;
         let v = res.data.vehiculo;
         let link = res.data.url;
          console.log(res.data);
          
   

          setImg1(link+d.img_odo_salida_f); 
          setImg2(link+d.img_inv_salida_f); 
          setImg3(link+d.fotovh1_f); 
          setImg4(link+d.fotovh2_f); 
          setImg5(link+d.fotovh3_f); 
          setImg6(link+d.fotovh4_f); 
          setImg7(link+d.img_contrato_f); 
          window.localStorage.setItem('placa',d.placaasig);
         // document.getElementById("obs").innerHTML = d.obs_devolucion;
          
          var asi = d.operario_domicilio;
          var est = d.estadocita;

          var btn = '<button class="btn btn-primary btn-block" onclick="asignar()">Asignar Operacio</button>';
          if(asi == 0){
            //document.getElementById("boton_asignar").innerHTML = btn;
          }else{
            var btn2 = 'La cita de entrega esta concluida <br><a href="" class="btn btn-danger btn-block">Toma de Imagenes</a>';
            if(est == 'P'){
              var btn2 = '<button class="btn btn-info btn-block"   data-toggle="modal" data-target="#modal-km" role="button">Registrar Observaciones</button>';
            }
           
            //document.getElementById("boton_asignar").innerHTML ='<div class="text-center">Operario:'+ d.nombreoperario+' <br>'+btn2+'</div>';
          }
          var dom = d.dir_domicilio;
          if(dom!=''){
            //document.getElementById('desplazamiento').style.display = 'block';
          }
          
        }
      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });
}
async function Principal() {
  window.location.href = '/home';
}

function MarcarOdometro(){

  //setCargandof(1);
  var token = window.localStorage.getItem('key_t');
  var ope = window.localStorage.getItem('key');
console.log(Km)
var cita = window.localStorage.getItem('cita');
const datos = { cita: cita, km: Km, acta:Acta };
axios.post(uri+'appmovil/saveodometro',datos)
    .then(function(res) {
      //alert(res.data.msj);
      console.log(res.data);

    })
    .catch(function(err) {
      //Swal.fire('Error');
      console.log(err);
    })
    .then(function() {
      //loading.style.display = 'none'
    });

}
function validar(){
  if(imageDataURL!=null){
    GuardarCroquis();
  }
  var nick = window.localStorage.getItem('name_u');
  var token = window.localStorage.getItem('key_t');
  var ope = window.localStorage.getItem('key');
  var lat = window.localStorage.getItem('lat');
  var lon = window.localStorage.getItem('lon');

console.log(Km)
var cita = window.localStorage.getItem('cita');

if(Km==0 || Km==''){
  alert("Debes de digitar el valor del odometro");
  return false;
}
if(parseInt(UltKm) > parseInt(Km)) {
  alert("Debes escribir un kilometraje  valido igual o mayor a "+UltKm+". ");
  return false;
}
if(Observaciones==''){
  alert("Debes de digitar alguna observacion");
  return false;
}
if(Acta == 'DEVOLUCION'){
  if(Estado==''){
    alert("Debes de seleccionar el estado");
    return false;
  }
}
setCargando(1);
const datos = { cita: cita, km: Km, acta:Acta,usuario:nick,observaciones:Observaciones,lat:lat,lon:lon,Estado:Estado};
axios.post(uri+'appmovil/saveodometro',datos)
    .then(function(res) {
      //alert(res.data.msj);
      alert(res.data.msj);
      if(res.data.estado == 'ok'){
        if(Acta == 'ENTREGA'){
          window.location.href = '/citas';
        }else{
          window.location.href = '/devoluciones';
        }
         
      }
      

      console.log(res.data);
      setCargando(0);
    })
    .catch(function(err) {
      //Swal.fire('Error');
      console.log(err);
    })
    .then(function() {
      //loading.style.display = 'none'
    });

}
async function pasar(){
  if(imageDataURL!=null){
    GuardarCroquis();
  }
  //console.log(Lado);
    switch(Lado){
      case 'INTERNO':
        if(imageno==''){
          alert("Debes de tomar la foto del odometro");
          return false;
        }
        if(selectedValue==''){
          alert("Debes de seleccionar el nivel de gasolina");
          return false;
        }
        
        if(Km==''){
          alert("Debes de digitar el kilometraje");
          return false;
        } 
        setLado("IZQUIERDO");
        ValidarObservaciones("IZQUIERDO");
        setObs_croquis(Croquis1_obs);
        
       //MarcarOdometro();
      break;
      case 'IZQUIERDO':
        if(imageno_izq==''){
          alert("Debes de tomar la foto del lado izquierdo");
          return false;
        }
        setObs_croquis(Croquis2_obs);
        ValidarObservaciones("ATRAS");
     
      break;
      case 'ATRAS':
        if(imageno_atr==''){
          alert("Debes de tomar la foto del lado atras");
          return false;
        }
        if(imagenaext==''){
          alert("Debes de tomar la foto del extintor");
          return false;
        }
        setObs_croquis(Croquis3_obs);
        ValidarObservaciones("DERECHO");

      break;
      case 'DERECHO':
        if(imageno_der==''){
          alert("Debes de tomar la foto del lado derecho");
          return false;
        }
        setObs_croquis(Croquis4_obs);
        ValidarObservaciones("FRENTE");
     
      break;
      case 'FRENTE':
        if(imageno_fre==''){
          alert("Debes de tomar la foto del frente del vehiculo");
          return false;
        }
        setObs_croquis(Croquis5_obs);
        ValidarObservaciones("SUPERIOR");
        
      break;
    }
    
}
async function PasarObservaciones(obs:string,id:string){

    //console.log("obs "+id+":"+obs);
    setTextareaValue(obs);
    setPasarId(id);
}

async function handleTextareaBlur(){

  console.log(PasarId+'Guardando:', textareaValue);
  GuardarObs();
  
}
async function ValidarOdometro(){

  console.log('Guardando km:', Km + 'ultimo'+UltKm);
  if(Km==''){
    return false;
  }
  if(parseInt(Km) < parseInt(UltKm)){
    alert("El kilometraje digitado "+Km+" es menor al actual "+UltKm);
    setKm('');
    return false;
  }
  var diferencia = parseInt(Km) - parseInt(UltKm);
  var limite = 100;
  if(diferencia > 800){
    alert("¡¡ADVERTENCIA!!\n El kilometraje digitado supera al ultimo registrado "+UltKm);
  }
  //GuardarObs();
  
}
// codigo para pintar e canvas:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
const canvasRef = useRef<HTMLCanvasElement | null>(null);
const isDrawing = useRef(false);
const lastX = useRef(0);
const lastY = useRef(0);
const [imageDataURL, setImageDataURL] = useState<string | null>(null);
const [Croquis11, setCroquis1] = useState<any>("");
const [Croquis2, setCroquis2] = useState<any>("");
const [Croquis3, setCroquis3] = useState<any>("");
const [Croquis4, setCroquis4] = useState<any>("");
const [Croquis5, setCroquis5] = useState<any>("");
// si se guarda el croquis guarde la temporal 
const [Croquis1_temp, setCroquis1_temp] = useState<any>("");
const [Croquis2_temp, setCroquis2_temp] = useState<any>("");
const [Croquis3_temp, setCroquis3_temp] = useState<any>("");
const [Croquis4_temp, setCroquis4_temp] = useState<any>("");
const [Croquis5_temp, setCroquis5_temp] = useState<any>("");
//observaciones del croquis
  // guardar las observaciones del croqui
  const [Obs_croquis, setObs_croquis] = useState<any>("");
  const [Croquis1_obs, setCroquis1_obs] = useState<any>("");
  const [Croquis2_obs, setCroquis2_obs] = useState<any>("");
  const [Croquis3_obs, setCroquis3_obs] = useState<any>("");
  const [Croquis4_obs, setCroquis4_obs] = useState<any>("");
  const [Croquis5_obs, setCroquis5_obs] = useState<any>("");
useEffect(() => {
   
}, []);

const PintarCanvas = (LL:String) =>{
  const canvas = canvasRef.current;
  console.log(':::::::::::::::::'+LL+'::::::::::::::::::::::');
  //console.log(Croquis1);
  if (canvas) {
    const ctx = canvas.getContext('2d');

    if (ctx) {
        
      // Ajustamos el tamaño del canvas al tamaño de la pantalla
      const img = new Image();
      switch(LL){
        
        case 'IZQUIERDO':
          img.src = Croquis1_temp==""?Croquis11:Croquis1_temp;
        break;
        case 'ATRAS':
          img.src = Croquis2_temp==""?Croquis2:Croquis2_temp;
        break;
        case 'DERECHO':
          img.src = Croquis3_temp==""?Croquis3:Croquis3_temp;
        break; 
        case 'FRENTE':
          img.src = Croquis4_temp==""?Croquis4:Croquis4_temp;
        break;
        case 'SUPERIOR':
          img.src = Croquis5_temp==""?Croquis5:Croquis5_temp;
        break;
        default:
          img.src = Croquis11;
        break;
      }
     
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      }; 
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // Agregamos eventos de ratón y eventos táctiles
      canvas.addEventListener('mousedown', startDrawing);
      canvas.addEventListener('mousemove', draw);
      canvas.addEventListener('mouseup', stopDrawing);
      canvas.addEventListener('mouseout', stopDrawing);

      canvas.addEventListener('touchstart', startDrawing);
      canvas.addEventListener('touchmove', draw);
      canvas.addEventListener('touchend', stopDrawing);
    }
  }

  return () => {
    if (canvas) {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseout', stopDrawing);

      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchmove', draw);
      canvas.removeEventListener('touchend', stopDrawing);
    }
  
  };
}

const startDrawing = (e: MouseEvent | TouchEvent) => {
  e.preventDefault();
  isDrawing.current = true;

  const [x, y] = getCoordinates(e);
  
  [lastX.current, lastY.current] = [x, y];
};

const draw = (e: MouseEvent | TouchEvent) => {
  e.preventDefault();
  if (!isDrawing.current) return;

  const canvas = canvasRef.current;
  if (!canvas) return;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.strokeStyle = 'blue'; // Color de la línea
  ctx.lineWidth = 3;       // Grosor de la línea
  ctx.lineCap = 'round';   // Extremo redondeado de la línea

  const [x, y] = getCoordinates(e);

  ctx.beginPath();
  ctx.moveTo(lastX.current, lastY.current);
  ctx.lineTo(x, y);
  ctx.stroke();

  [lastX.current, lastY.current] = [x, y];
};

const stopDrawing = () => {
  isDrawing.current = false;
  // Obtener la imagen en base64 cuando se detiene el dibujo
  const canvas = canvasRef.current;
  if (canvas) {
    const imageDataURL = canvas.toDataURL('image/png');
    setImageDataURL(imageDataURL);
    //console.log(imageDataURL);
  }
};

const getCoordinates = (e: MouseEvent | TouchEvent): [number, number] => {
  let x = 0;
  let y = 0;

  const canvas = canvasRef.current;
  if (!canvas) return [x, y];

  const rect = canvas.getBoundingClientRect();

  if (e instanceof MouseEvent) {
    x = e.clientX - rect.left;
    y = e.clientY - rect.top;
  } else if (e instanceof TouchEvent) {
    x = e.touches[0].clientX - rect.left;
    y = e.touches[0].clientY - rect.top;
  }

  return [x, y];
};
const clearCanvas = () => {
  const canvas = canvasRef.current;
  if (!canvas) return;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height); // Borra todo el canvas
};
//fin del codigo canvas::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
const CargarCroquis = () => {
  var acta = window.localStorage.getItem('acta');
  var cita = window.localStorage.getItem('cita');
  setNext(true);
  var lado = Lado;
  axios.get(uri+'appmovil/linea/'+cita+'/'+acta+'/'+lado)
      .then(function(res) {
        //alert(res.data.msj);
       //console.log('iamge:'+res.data.izquierdo);
        var d = res.data;
        setLoadingCanvas(1);
        setCroquis1(d.izquierdo);
        setCroquis2(d.atras);
        setCroquis3(d.derecho);
        setCroquis4(d.frente);
        setCroquis5(d.superior);
        setNext(false);
       // PintarCanvas(Lado);
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none'
      });
}

const GuardarCroquis = () => {
  var acta = window.localStorage.getItem('acta');
  var side = '';
  switch(Lado){
    case 'IZQUIERDO':
        side = acta=='ENTREGA'?'imagen_izquierdo_ent':'imagen_izquierdo_dev';
        setCroquis1_temp(imageDataURL);
        setCroquis1_obs(Obs_croquis);
      break;
      case 'ATRAS':
        side = acta=='ENTREGA'?'imagen_atras_ent':'imagen_atras_dev';
        setCroquis2_temp(imageDataURL);
        setCroquis2_obs(Obs_croquis);
      break;
      case 'DERECHO':
        side = acta=='ENTREGA'?'imagen_derecho_ent':'imagen_derecho_dev';
        setCroquis3_temp(imageDataURL);
        setCroquis3_obs(Obs_croquis);
      break;
      case 'FRENTE':
        side = acta=='ENTREGA'?'imagen_frente_ent':'imagen_frente_dev';
        setCroquis4_temp(imageDataURL);
        setCroquis4_obs(Obs_croquis);
      break;
      case 'SUPERIOR':
        side = acta=='ENTREGA'?'imagen_superior_ent':'imagen_superior_dev';
        setCroquis5_temp(imageDataURL);
        setCroquis5_obs(Obs_croquis);
      break;
   }
  const datos = { cita: cita, campo : side, imagen:imageDataURL,acta:acta, obs:Obs_croquis };
  console.log(datos);
  axios.post(uri+'appmovil/GuardarImagenActa', datos)
      .then(function(res) {
        setImageDataURL("");
        
        //alert(res.data.msj);
        //$("#cargado").html('Cargado con exito');
        //document.getElementById("boton").className = "button save btn btn-success btn-sm";
      })
      .catch(function(err) {
       // Swal.fire('Error');
       // $("#cargado").html('Sin subir');
        //document.getElementById("boton").className = "button save btn btn-danger btn-sm";
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
        //Swal.close();
      }); 
}
function GuardarObs(){

var cita = window.localStorage.getItem('cita');
const datos = { id: PasarId, obs: textareaValue, acta:Acta };
axios.post(uri+'appmovil/guardarobs',datos)
    .then(function(res) {
      //alert(res.data.msj);
      console.log(res.data);
      setTextareaValue('');
      setPasarId('');
    })
    .catch(function(err) {
      //Swal.fire('Error');
      console.log(err);
    })
    .then(function() {
      //loading.style.display = 'none'
    });

}
/* async function retroceder(){
  switch(Lado){
    case 'FRENTE':
      setLado("DERECHO");
    break;
    case 'DERECHO':
      setLado("ATRAS");
    break;
    case 'ATRAS':
      setLado("IZQUIERDO");
    break;
    case 'IZQUIERDO':
      setLado("INTERNO");
    break;
    case 'SUPERIOR':
      setLado("FRENTE");
    break;
  }
  setCargando(0);
  DetalleCita();
} */
async function retroceder(){
  switch(Lado){
    case 'FRENTE':
      setLado("DERECHO");
      PintarCanvas("DERECHO");
      setObs_croquis(Croquis3_obs);
    break;
    case 'DERECHO':
      setLado("ATRAS");
      PintarCanvas("ATRAS");
      setObs_croquis(Croquis2_obs);
    break;
    case 'ATRAS':
      setLado("IZQUIERDO");
      PintarCanvas("IZQUIERDO");
      setObs_croquis(Croquis1_obs);
    break;
    case 'IZQUIERDO':
      setLado("INTERNO");
      PintarCanvas("INTERNO");
      setObs_croquis(Croquis1_obs);
    break;
    case 'SUPERIOR':
      setLado("FRENTE");
      PintarCanvas("FRENTE");
      setObs_croquis(Croquis4_obs);
    break;
  }
  setCargando(0);
  DetalleCita();
}
function VerEntrega(){
  setShowModal(true)
  VerImagen();
}
function Salir() {
  //localStorage.clear();
  window.location.href = "/";
}
function Listaentrega() {
  window.location.href = "/citas";
}
function Listadev() {
  window.location.href = "/devoluciones";
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
const LimpiarCanvas = () => {

  switch(Lado){
        
    case 'IZQUIERDO':
      setCroquis1_temp(Croquis11);
    break;
    case 'ATRAS':
      setCroquis2_temp(Croquis2);
    break;
    case 'DERECHO':
      setCroquis3_temp(Croquis3);
    break; 
    case 'FRENTE':
      setCroquis4_temp(Croquis4);
    break;
    case 'SUPERIOR':
      setCroquis5_temp(Croquis5);
    break;
   
  }
  PintarCanvas(Lado);
 }
useIonViewWillEnter(() => {
  CargarCroquis();
  DetalleCita();


  if (!tk) {
    window.location.href = "/";
  }
  
});
  return (
    <IonPage>
         <IonContent fullscreen className="ion-padding fondohome ">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           
              <IonImg
                className="rounded-pill"
                onClick={Entregas}
                src={require("../assets/images/backwhite.png")}
              ></IonImg>
              ACTA DE {Acta}
           
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listadev}>
                    Lista de devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
  
          {Lado!='SUPERIOR'?
        <IonItem>
            <IonThumbnail slot="start">
            {
            <IonImg src={
              Lado=='INTERNO'?imageno?Imagen1==2?odometro:imageno:odometro:
              Lado=='FRENTE'?imageno_fre?Imagen2==2?frontal:imageno_fre:frontal:
              Lado=='IZQUIERDO'?imageno_izq?Imagen3==2?izquierda:imageno_izq:izquierda:
              Lado=='ATRAS'?imageno_atr?Imagen4==2?posterior:imageno_atr:posterior:
              Lado=='DERECHO'?imageno_der?Imagen5==2?derecha:imageno_der:derecha:''
            }></IonImg>
            }
            
              
            </IonThumbnail>
            <IonLabel>
              {Lado}
              <br />
              {Lado=='INTERNO'?imageno?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen1?Ho1:'Subiendo..' }</sub></IonText>:'':
              Lado=='IZQUIERDO'?imageno_izq?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen2?Ho2:'Subiendo..' }</sub></IonText>:'':
              Lado=='ATRAS'?imageno_atr?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen3?Ho3:'Subiendo..' }</sub></IonText>:'':
              Lado=='DERECHO'?imageno_der?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen4?Ho4:'Subiendo..' }</sub></IonText>:'':
              Lado=='FRENTE'?imageno_fre?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen5?Ho5:'Subiendo..' }</sub></IonText>:'':''}
           

            </IonLabel>
            
            <IonButton type='button' size="default" color={
              Lado=='INTERNO'?Imagen1?Imagen1==2?'danger':'success':'danger':
              Lado=='IZQUIERDO'?Imagen2?Imagen2==2?'danger':'success':'danger':
              Lado=='ATRAS'?Imagen3?Imagen3==2?'danger':'success':'danger':
              Lado=='DERECHO'?Imagen4?Imagen4==2?'danger':'success':'danger':
              Lado=='FRENTE'?Imagen5?Imagen5==2?'danger':'success':'danger':''
              } slot='end' onClick={()=>takePictureo(Lado)}>
              <IonIcon icon={camera} />
              </IonButton>

              

          </IonItem>
          :''}
           {Lado=='ATRAS'?
        <>
        <IonItem >
            <IonThumbnail slot="start">
            {imagenaext?
            <IonImg src={Imagen12==2?extintor:imagenaext}></IonImg>:
            <IonImg src={extintor}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>EXTINTOR
            <br />
              {imagenaext?<IonText color={Imagen12==2?'danger':'success'}><sub>{Imagen12?Ho12:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen12?Imagen12==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('EXTINTOR')}><IonIcon icon={Imagen12?Imagen12==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
        </>
        :''}
          {Acta=='DEVOLUCION'?
          <IonItem>
           
            <IonLabel>Ver imagen de entrega</IonLabel> 
            <button onClick={() => VerEntrega()}>Abrir</button>
          </IonItem>:''}
        {
       /*  Lado=='DERECHO'?
        <iframe src={"https://app.aoacolombia.com/Control/operativo/views/appmovil/acta.php?id="+Cita+"&lado=derecho&acta="+Acta} width={'100%'} height={'250px'}></iframe>
        :
        Lado=='ATRAS'?
        <iframe src={"https://app.aoacolombia.com/Control/operativo/views/appmovil/acta.php?id="+Cita+"&lado=atras&acta="+Acta} width={'100%'} height={'280px'}></iframe>
        :
        Lado=='IZQUIERDO'?
        <iframe src={"https://app.aoacolombia.com/Control/operativo/views/appmovil/acta.php?id="+Cita+"&lado=izquierdo&acta="+Acta} width={'100%'} height={'250px'}></iframe>
        :
        Lado=='FRENTE'?
        <iframe src={"https://app.aoacolombia.com/Control/operativo/views/appmovil/acta.php?id="+Cita+"&lado=frente&acta="+Acta} width={'100%'} height={'280px'}></iframe>
        :
        Lado=='SUPERIOR'?
        <iframe src={"https://app.aoacolombia.com/Control/operativo/views/appmovil/acta.php?id="+Cita+"&lado=superior&acta="+Acta} width={'100%'} height={'280px'}></iframe>
        : */
        Lado=='INTERNO'?
        <IonGrid>
        <IonItemDivider>
          <IonLabel>
            NIVEL DE GASOLINA
          </IonLabel>
        </IonItemDivider>
        <IonRadioGroup  value={selectedValue} onIonChange={handleRadioChange}>
        <IonItem  className='item-background-color'>
          <IonLabel>100%</IonLabel>
          <IonRadio slot="end" value="100" ></IonRadio>
        </IonItem>

        <IonItem className='item-background-color2'>
          <IonLabel>75%</IonLabel>
          <IonRadio slot="end" value="75" ></IonRadio>
        </IonItem>

        <IonItem className='item-background-color3'>
          <IonLabel>50%</IonLabel>
          <IonRadio slot="end" value="50"></IonRadio>
        </IonItem>

        <IonItem className='item-background-color4'>
          <IonLabel>25%</IonLabel>
          <IonRadio slot="end" value="25"></IonRadio>
        </IonItem>
      </IonRadioGroup>
      <IonItemDivider>
      <IonLabel>
            ODOMETRO {Acta}
          </IonLabel>
      </IonItemDivider>
      
  
      <div className="" id="collapseExample">
          <div className="card card-body">
          
          <IonInput className='form-control'  placeholder="Kilometraje del odometro" id='km' name='km' value={Km}
           onIonChange={(e) => setKm(e.detail.value!)}
               onBlur={ValidarOdometro}/>
       </div>
       </div>
    
        </IonGrid>
        :
       
        <>
         {LoadingCanvas==1?
        <IonItem>
        
        <canvas ref={canvasRef}></canvas>
        
        </IonItem>
        :
        <IonItem>
        <IonLabel>Cargando Croquis...</IonLabel>
        <IonSpinner name="dots"></IonSpinner>
      </IonItem>}


         <button onClick={()=>LimpiarCanvas()}>Limpiar Croquis</button> 
         <IonItem>
         <IonTextarea
                placeholder="Digite alguna observacion del croquis"
                autoGrow={true}
                value={Obs_croquis} onIonChange={(e) => setObs_croquis(e.detail.value!)}
          ></IonTextarea>
          </IonItem>
          <br></br>
        </>
       /*  onIonChange={(e) => setKm(e.detail.value!)} */
      }
       {Lado=='SUPERIOR'?
          <>
          {Acta=='DEVOLUCION'?
          <IonItem>
          <IonLabel>Estado en que queda el vehiculo:</IonLabel>
          <IonSelect value={Estado} onIonChange={(e) => setEstado(e.detail.value!)}>
  
            <IonSelectOption value="4">En mantenimiento</IonSelectOption>
            <IonSelectOption value="5">Fuera de servicio</IonSelectOption>
            <IonSelectOption value="8">Alistamiento</IonSelectOption>
            <IonSelectOption value="2">En parqueadero</IonSelectOption>
          </IonSelect>
        </IonItem>:''}
           <IonItem>
            <IonLabel position="stacked">observaciones:</IonLabel>
            <IonTextarea
            className='form-control'
                placeholder="Digite alguna observacion"
                autoGrow={true}
                value={Observaciones} onIonChange={(e) => setObskm(e.detail.value!)}
          ></IonTextarea>
          </IonItem>
          </>
          :''}
        
      <IonItemDivider>
          <IonLabel>
            VEHICULO {Lado} {Acta}<br />
            Seleccione los items que si tenga
          </IonLabel>
          <IonLabel slot='end'>
           Si.?
          </IonLabel>
        </IonItemDivider>
        {Loading==1?
        <IonList>
          {Lista.map((item,i) => (
            item.parte==Lado?
              <IonItem key={item.id}>
                <IonLabel>
                    {item.nombre_parte}
                    {Acta=='DEVOLUCION'?<h5>Entregado : {item.respuesta_entrega} </h5>:''}
                    
                </IonLabel>
       
                <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked,item.nombre_parte,item.id)} /> 
                    
                
              
          </IonItem>
          :''
          ))}
         
      
        </IonList>
        :
         <IonItem>
            <IonLabel>Cargando Preguntas..</IonLabel>
            <IonSpinner name="dots"></IonSpinner>
         </IonItem>
       }
       {Lado=='FRENTE'?
       <IonItem>
            <IonThumbnail slot="start">
            {imagenad1?
            <IonImg src={Imagen10==2?adicional:imagenad1}></IonImg>:
            <IonImg src={adicional}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Foto del Motor
            <br />
              {imagenad1?<IonText color={Imagen10==2?'danger':'success'}><sub>{Imagen10?Ho10:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen10?Imagen10==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('ADC1')}><IonIcon icon={Imagen10?Imagen10==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
         :''}
         {Lado=='ATRAS'?
<IonItem>
            <IonThumbnail slot="start">
            {imagenad2?
            <IonImg src={Imagen11==2?adicional:imagenad2}></IonImg>:
            <IonImg src={adicional}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Foto de Accesorios
            <br />
              {imagenad2?<IonText color={Imagen11==2?'danger':'success'}><sub>{Imagen11?Ho11:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen11?Imagen11==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('ADC2')}><IonIcon icon={Imagen11?Imagen11==2?warning:checkmark:camera} /></IonButton>
          </IonItem> :''}
        {Lado=='INTERNO'?
        <>
        <IonItem >
            <IonThumbnail slot="start">
            {imagencf?
            <IonImg src={Imagen6==2?cedula:imagencf}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>CEDULA FRENTE
            <br />
              {imagencf?<IonText color={Imagen6==2?'danger':'success'}><sub>{Imagen6?Ho6:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen6?Imagen6==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('CEDULA')}><IonIcon icon={Imagen6?Imagen6==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagencr?
            <IonImg src={Imagen7==2?cedula:imagencr}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>CEDULA REVERSO
            <br />
              {imagencr?<IonText color={Imagen7==2?'danger':'success'}><sub>{Imagen7?Ho7:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen7?Imagen7==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('CEDULA2')}><IonIcon icon={Imagen7?Imagen7==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagenlf?
            <IonImg src={Imagen8==2?licencia:imagenlf}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>LICENCIA FRENTE
            <br />
              {imagenlf?<IonText color={Imagen8==2?'danger':'success'}><sub>{Imagen8?Ho8:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen8?Imagen8==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('LICENCIA')}><IonIcon icon={Imagen8?Imagen8==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenlr?
            <IonImg src={Imagen9==2?licencia:imagenlr}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>LICENCIA REVERSO
            <br />
              {imagenlr?<IonText color={Imagen9==2?'danger':'success'}><sub>{Imagen9?Ho9:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen9?Imagen9==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('LICENCIA2')}><IonIcon icon={Imagen9?Imagen9==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
           
          <IonItem>
            <IonThumbnail slot="start">
            {imagenadi3?
            <IonImg src={Imagen13==2?licencia:imagenadi3}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>L. FRENTE TH
            <br />
              {imagenadi3?<IonText color={Imagen13==2?'danger':'success'}><sub>{Imagen13?Ho13:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen13?Imagen13==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('LICENCIA3')}><IonIcon icon={Imagen13?Imagen13==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenadi4?
            <IonImg src={Imagen14==2?licencia:imagenadi4}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>L. REVERSO TH
            <br />
              {imagenadi4?<IonText color={Imagen14==2?'danger':'success'}><sub>{Imagen14?Ho14:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen14?Imagen14==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('LICENCIA4')}><IonIcon icon={Imagen14?Imagen14==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagenaut?
            <IonImg src={Imagen15==2?adicional:imagenaut}></IonImg>:
            <IonImg src={adicional}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>AUTORIZACION
            <br />
              {imagenaut?<IonText color={Imagen15==2?'danger':'success'}><sub>{Imagen15?Ho15:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen15?Imagen15==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('AUTORIZACION')}><IonIcon icon={Imagen15?Imagen15==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenpol?
            <IonImg src={Imagen16==2?licencia:imagenpol}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>POLIZA
            <br />
              {imagenpol?<IonText color={Imagen16==2?'danger':'success'}><sub>{Imagen16?Ho16:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen16?Imagen16==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('POLIZA')}><IonIcon icon={Imagen16?Imagen16==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagencom?
            <IonImg src={Imagen17==2?licencia:imagencom}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>C CAMARA
            <br />
              {imagencom?<IonText color={Imagen17==2?'danger':'success'}><sub>{Imagen17?Ho17:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen17?Imagen17==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('CAMARA')}><IonIcon icon={Imagen17?Imagen17==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagental?
            <IonImg src={Imagen18==2?licencia:imagental}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>ORDEN INGRESO
            <br />
              {imagental?<IonText color={Imagen18==2?'danger':'success'}><sub>{Imagen18?Ho18:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen18?Imagen18==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo('ORDEN')}><IonIcon icon={Imagen18?Imagen18==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          
          
        </>
        :''}
        
       
        <IonToolbar className='ion-text-center'>
        <IonButton  onClick={retroceder}>
            Atras
        <IonIcon slot="start" icon={arrowBack}></IonIcon>
      </IonButton>
        <IonButton onClick={Lado=='SUPERIOR'?validar:pasar} disabled={Next}>
        {Lado=='SUPERIOR'?Cargando==0?'Guardar Acta':<><IonSpinner color="light"></IonSpinner>Cargando ...</>:'Siguiente'}
        <IonIcon slot="end" icon={Lado=='SUPERIOR'?save:arrowForward} ></IonIcon>
      </IonButton>
        </IonToolbar>
       
        <IonFab slot="fixed" vertical="bottom" horizontal="start" onClick={Principal}>
          <IonFabButton>
            <IonIcon icon={home}></IonIcon>
          </IonFabButton>
          
        </IonFab>
        </IonContent>

     
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <img
        src={
          Lado=='INTERNO'?Img1?Img1:'':
          Lado=='IZQUIERDO'?Img4?Img4:'':
          Lado=='ATRAS'?Img6?Img6:'':
          Lado=='DERECHO'?Img5?Img5:'':
          Lado=='FRENTE'?Img3?Img3:'':''
    }
        alt="Imagen"
        className="zoomable-image"
      />
      <iframe src={
            Lado=='INTERNO'?Img1?Img1:'':
            Lado=='IZQUIERDO'?Img4?Img4:'':
            Lado=='ATRAS'?Img6?Img6:'':
            Lado=='DERECHO'?Img5?Img5:'':
            Lado=='FRENTE'?Img3?Img3:'':''
      }  width={'100%'} height={'800px'}></iframe>
  <button onClick={() => setShowModal(false)}>Cerrar Imagen</button>
</IonModal>

<IonModal isOpen={showModal2} onDidDismiss={() => setShowModal2(false)} className="ion-padding fondohome">
{ListaObservaciones.map((item,i) => (
<IonItem>
       <IonItemDivider>
          <IonLabel>
            {item.pregunta}
          </IonLabel>
        </IonItemDivider>
          <IonLabel position="stacked">Haga una breve observación del item seleccionado:</IonLabel>
           <IonTextarea
              placeholder="Digite alguna observacion"
               autoGrow={true}
               rows={4}
               onIonChange={(e) => PasarObservaciones(e.detail.value!,item.id)}
               onBlur={handleTextareaBlur}
        >{item.obs_entrega}</IonTextarea> 
  {/*        <textarea
         className='form-control'
              placeholder="Digite alguna observacion"
              id={"obs"+item.id}
        ></textarea> */}
         <input type="hidden" id={"id"+item.id} value={item.id} />
  </IonItem>
))}
  <button className='btn btn-success' onClick={() => PasarMarcar()}>Guardar Observacion</button>
</IonModal>

    </IonPage>
  
  );
};

export default Actas;
