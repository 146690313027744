import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonIcon,IonSpinner, IonCheckbox, useIonViewWillEnter } from '@ionic/react';
import Swal from 'sweetalert2'
import './Login2.css';
import { useState } from 'react';
import { arrowForward, barChart, barcode, keyOutline, keypadOutline, keySharp, locationSharp, personCircle } from 'ionicons/icons';
import Conexio from '../services/Index';
import GeolocationButton from "../components/GeolocationButton";
import axios from 'axios';


const Login: React.FC = () => {
  const [presentAlert] = useIonAlert();
  var long: any = window.localStorage.getItem('lon');

  const [user, setUsuario] = useState<any>(window.localStorage.getItem('us'));
  const [clave, setClave] = useState<any>(window.localStorage.getItem('cl'));
  const [recordar, setRecordar] = useState<any>('0');
  const [Cargando, setCargando] = useState<any>("0");
  const [Gps, setGps] = useState<any>(long);
  let uri = Conexio; 
  useIonViewWillEnter(() => {
    if(document.cookie){
      
      ValidarCookies();
      setRecordar('1');
     }
  });
  async function Entrada(){
    if(recordar=='0'){
      //document.cookie = "login=72280699;";
      setRecordar('1');
    }else{
      document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "clave=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setRecordar('0');
    }
    
    //console.log("Modulo en Construcción"+recordar);
  }
  async function GPS(){
    window.location.href = '/';
  }
  async function ValidarCookies() {
    var lat: any = window.localStorage.getItem('lat');
    var lon: any = window.localStorage.getItem('lon');

    var us: any = window.localStorage.getItem('us');
    var cl: any = window.localStorage.getItem('cl');

    const cookies = document.cookie.split("; ");
    const usuario = cookies[0].split("=");
    const pass = cookies[1].split("=");
    console.log("Modulo en Construcción"+usuario[1]);
    console.log("Modulo en Construcción"+pass[1]);
   setUsuario(usuario[1]);
    setClave(pass[1]);
    var user = usuario[1];
   var clave = pass[1];

    return false;
    const formData = new FormData();
    formData.append("usuario", user);
    formData.append("clave", clave);
    formData.append("lat", lat);
    formData.append("lon", lon);
    setCargando(1);
    const response = await fetch(uri+'operario/login', { method: "POST", body: formData });
    const result = await response.json();
    console.log(result);
    if(result.State==false){
        
        presentAlert({
          header: 'Alert',
          subHeader: 'Important message',
          message: result.Msj,
          buttons: ['OK'],
        })
         window.localStorage.clear();
         setCargando(0);
    }else{
      
      presentAlert({
        header: 'AOA',
        subHeader: 'Hola',
        message: result.Msj,
      })
     
      const {usuario, nombre, apellido, token , id,oficina} = result.datos;
      const nombreoficina = result.nombreofi;
      window.localStorage.setItem('key_t',token);
      window.localStorage.setItem('name_u',nombre+' '+apellido);
      window.localStorage.setItem('key',id);
      window.localStorage.setItem('o',oficina);
      window.localStorage.setItem('oficina',nombreoficina);
      //history.push(`/listings`);
      if(recordar=='1'){
        document.cookie = "user="+user+"; ";
        document.cookie = "clave="+clave+"; ";
      }
    
      window.location.href = '/home';

    }
    
    //clearInputs();
  }
  async function Validar() {
    var lat: any = window.localStorage.getItem('lat');
    var lon: any = window.localStorage.getItem('lon');


    const formData = new FormData();
    formData.append("usuario", user);
    formData.append("clave", clave);
    formData.append("lat", lat);
    formData.append("lon", lon);
    setCargando(1);
    const response = await fetch(uri+'operario/login', { method: "POST", body: formData });
    const result = await response.json();
    console.log(result);
    if(result.State==false){
        
        presentAlert({
          header: 'Alert',
          subHeader: 'Important message',
          message: result.Msj,
          buttons: ['OK'],
        })
         window.localStorage.clear();
         setCargando(0);
    }else{
      
      presentAlert({
        header: 'AOA',
        subHeader: 'Hola',
        message: result.Msj,
      })
      const nombreoficina = result.nombreofi;
      const {usuario, nombre, apellido, token , id,oficina} = result.datos;
      window.localStorage.setItem('key_t',token);
      window.localStorage.setItem('name_u',nombre+' '+apellido);
      window.localStorage.setItem('key',id);
      window.localStorage.setItem('o',oficina);
      window.localStorage.setItem('oficina',nombreoficina);
      window.localStorage.setItem('us',user);
      window.localStorage.setItem('cl',clave);
      const foto = result.foto;
      window.localStorage.setItem('foto',foto);
      //history.push(`/listings`);
      if(recordar=='1'){
        document.cookie = "user="+user+"; ";
        document.cookie = "clave="+clave+"; ";
      }
    
      window.location.href = '/home';

    }
    
    //clearInputs();
  }
  const handleChange = (event:any) => {
    setUsuario(event.target.value);
  };
  const handleChangeclave = (event:any) => {
    setClave(event.target.value);
  };
  return (
    <IonPage>
     

        <IonContent fullscreen className='ion-padding fondo'>
        <GeolocationButton />
          <br />
            <div className="centered-image-container">
                 <IonImg src={require('../assets/images/logosin.png')}></IonImg>
                
            </div>
            <div className="centered-image-container">
              
                 <IonImg src={require('../assets/images/semuevecontigo.png')}></IonImg>
            </div>
 
        <h3 className="ion-text-center" style={{ color: '#FFFFFF' }}>
          Bienvenidos a Control APP 
          </h3>

        <h5 className="ion-text-center"  style={{ color: '#FFFFFF' }}>Version 3.5.3</h5>
        <br />

         <form></form>
         <br />
         {
         Gps==null?'':<>
         <div className='centered-image-container'>
         <div className="input-wrapper">
         
            <input type="text" className='input' placeholder='Usuario' id='usuario' value={user} onChange={handleChange} autoComplete='off'></input>
            
            <IonImg   className="input-icon" src={require('../assets/images/icouser.png')}></IonImg>
            </div>
         </div>
         <br />
         <div className='centered-image-container'>
         <div className="input-wrapper">
            <input type="password" className='input2' placeholder='Contraseña' id='clave' value={clave} onChange={handleChangeclave} ></input>
            <IonImg   className="input-icon" src={require('../assets/images/icopass.png')}></IonImg>
         </div>
         </div>
         <br />
         <div className='centered-image-container'>
     
          <IonCheckbox slot="start" value={recordar} checked={recordar=='0'?false:true} onClick={Entrada}></IonCheckbox>
          <label className="ion-text-center">  Recordar Contraseña</label>
   
         </div>
         </>}
         <br />
         {
         Gps==null? <label  style={{ color: '#FFFFFF',paddingLeft:10 }}> Debes de activar el Gps para iniciar sesión</label>:

         <div className='centered-image-container'>
             <button type='button' className='botonv2' onClick={Validar} >{Cargando==0?'Iniciar Sesión':<IonSpinner color="light"></IonSpinner> }</button>
         </div>
       
         }
          <br />
          <br />

         {Gps==null?
         <>
            <div className='centered-image-container'>
         <button type='button' className='botonv2' onClick={GPS} >Activar GPS</button>
     </div>
         </>
         
          :<>
            
           </>
         }
        </IonContent>
       
  
    </IonPage>
  );
};

export default Login;
