import {
  IonContent,
  useIonAlert,
  IonHeader,
  IonPage,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonMenu,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import "./Index.css?v=1.1";
import { useState } from "react";
import {
  add,
  calendar,
  eye,
  search,
  people,
  document,
  logOut,
  manOutline,
  bookOutline,
} from "ionicons/icons";
import { userInfo } from "os";
import GeolocationButton from "../components/GeolocationButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();
  let n = window.localStorage.getItem("name_u");
  let ofi = window.localStorage.getItem("oficina");
  let foto = window.localStorage.getItem("foto");
  const [nombre, seNombre] = useState(n);
  const [oficina, setOficina] = useState(ofi);
  const [fotos, setFoto] = useState(foto);

  let uri = "https://pot.aoacolombia.com/api/";

  async function Entregas() {
    window.location.href = "/citas";
  }
  async function Devoluciones() {
    window.location.href = "/devoluciones";
  }
  async function Firmas() {
    window.location.href = "/firmadas";
  }
  async function alistamiento1() {
    window.location.href = "/alistamiento";
  }
  async function alistamiento2() {
    window.location.href = "/alistamientod";
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function verimagen() {
    window.location.href = '/verimagen';
}
  async function Salir() {
    //localStorage.clear();
  /*   window.document.cookie =
      "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.document.cookie =
      "clave=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; */
    window.location.href = "/";
  }
  async function funza() {
    window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
  }
  async function bogota() {
    window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
  }
  function Listaentrega() {
    window.location.href = "/devoluciones";
  }
  return (
    <>
      <IonPage id="main-content ">
        <IonContent fullscreen className="ion-padding fondohome">
          <nav className="navbar navbar-expand-sm navbar-dark">
            <div className="container-fluid">
       
                <IonImg
                  className="rounded-pill"
                  style={{ width: 80 }}
                  src={require("../assets/images/logomarca.png")}
                ></IonImg>
              
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de Devoluciones
                  </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="javascript:void(0)"  onClick={Perfil}>
                      Editar Perfil
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                      Consultar Tarifa de Funza
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="javascript:void(0)" onClick={bogota} >
                      Consultar Tarifa Bogota
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link"  onClick={Salir} href="javascript:void(0)">
                      Salir
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="text-center p-3">
          <img className="bg-white rounded-circle"
                   src={fotos==''?require("../assets/images/icouser.png"):fotos}
                   style={{width: 50,height:50}}
                  alt="Avatar"
                />
            <br />
            <label>Hola {nombre}</label>
            <br />
            <label>{oficina}</label>
            <br />
            <h1>Control-App</h1>
          </div>
          <div className="p-3">
          <div className="row text-center">
          
          <div className="col p-3 text-black"  onClick={Entregas}>
            <div className="card">
              <div className="card-body">
                <img
                   src={require("../assets/images/calendar2.gif")}
                   style={{width: 50}}
                  alt="Avatar"
                />
                <br />
                <label>Citas</label>
              </div>
            </div>
          </div>
          <div className="col p-3 text-dark"  onClick={Devoluciones}>
            <div className="card">
              <div className="card-body">
                <img
                  src={require("../assets/images/calendar2.gif")}
                  style={{width: 50}}
                  alt="Avatar"
                />
                <br />
                  <label>Devoluciones</label>
              </div>
            </div>
          </div>
     
        </div>
        <div className="row text-center">
          
          <div className="col p-3 text-black"  onClick={alistamiento1}>
            <div className="card">
              <div className="card-body">
                <img
                   src={require("../assets/images/checklista.png")}
                   style={{width: 50}}
                  alt="Avatar"
                />
                <br />
                <label>Alistamiento Entrega</label>
              </div>
            </div>
          </div>
          <div className="col p-3 text-dark"  onClick={alistamiento2}>
            <div className="card">
              <div className="card-body">
                <img
                  src={require("../assets/images/checklista.png")}
                  style={{width: 50}}
                  alt="Avatar"
                />
                <br />
                  <label>Alistamiento Devolución</label>
              </div>
            </div>
          </div>
     
        </div>
        <div className="row text-center">
        
        <div className="col p-3 text-black"  onClick={Firmas}>
          <div className="card">
            <div className="card-body">
              <img
                src={require("../assets/images/mail.png")}
                style={{width: 50}}
                alt="Avatar"
              />
              <br />
              <label>Reenviar Actas</label>
            </div>
          </div>
        </div>
        <div className="col p-3 text-dark">
          <div className="card">
            <div className="card-body">
              <img
                src={require("../assets/images/huellero.png")}
                style={{width: 50}}
                alt="Avatar"
              />
              <br />
                <label>Salida</label>
            </div>
          </div>
        </div>
   
         </div>
         <div className="card">
         <div className="card-header">
                  Noticias
            
                </div>
            <div className="card-body">
        
                
                <div className="card-body toast-body  text-dark">
                  Se le informa al personal que a la hora de entregar el vehiculo debe de tener en cuenta que el cliente tenga su pase de conducir. 
                </div>
          </div>
          </div>
          </div>
          

          

         
        </IonContent>

      {/*   <IonFooter>
          <IonToolbar className="ion-text-center">
            <IonTitle>Tecnología AOA 2022</IonTitle>
          </IonToolbar>
        </IonFooter> */}
      </IonPage>
    </>
  );
};

export default Home;
