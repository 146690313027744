import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, useIonLoading, IonSelect, IonSelectOption, IonSpinner  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, mapSharp, trash } from 'ionicons/icons';
import axios from 'axios';
import Modal from './ModalDev';
import Conexio from '../services/Index';
import GeolocationButton from '../components/GeolocationButton';

const Home: React.FC = () => {
  const [presentAlert] = useIonAlert();
  let cita = window.localStorage.getItem("cita");
  const [ncita, seCita] = useState(cita);
  const [isiniestro, setSin] = useState("");
  const [Siniestro, setSiniestro] = useState("");
  const [iaseguradora, setAseg] = useState("");
  const [iasegurado, setAse] = useState("");
  const [ideclarante, setDec] = useState("");
  const [iplaca, setPla] = useState("");
  const [ioficina, setOfi] = useState("");
  const [ivehiculo, setVeh] = useState("");
  const [iagendado, setAge] = useState("");
  const [idias, setDia] = useState("");
  const [isin, setIdSin] = useState("");
  const [ioperario, setOperario] = useState("");
  const [NombreOperario, setNameOperario] = useState("");
  const [showModal2, setShowModal2] = useState(false);

  const [kmi, setKmi] = useState("");
  const [tpq, setTpq] = useState("0");
  const [kmd, setKmd] = useState("0");
  const [Observaciones, setObskm] = useState("");

  const [flota, setFlota] = useState("");
  const [Ukm, setUltKm] = useState("");
  const [Obs, setObs] = useState("");
  const [Estado, setEstado] = useState("");
  const [Fase, setFase] = useState("");

  const [Ubicacion, setUbicacion] = useState('');
  const [UbicacionKi, setUbicacionKi] = useState('');
  const [UbicacionKf, setUbicacionKf] = useState('');
  const [Domicilio, setDomicilio] = useState("");
  const [kmd2, setKmd2] = useState("0");
  const [EstadoDom, setEstadoDom] = useState("");
  const [ObservacionesDom, setObsDom] = useState("");
  const [Cargando, setCargando] = useState<any>("0");

  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;
  //let uri = 'http://127.0.0.1:8000/api/';
  //http://127.0.0.1:8000 

  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }


  async function Entregas() {
      window.location.href = '/devoluciones';
  }
  async function TomarFotos() {
    window.location.href = '/foto_dev';
}
async function VerImagenes() {
  window.location.href = '/imagenes';
}
function DetalleCita(){
  var orden = window.localStorage.getItem('cita');
  var token = window.localStorage.getItem('key_t');
 
  axios({
    method: "get",
    url: uri + "appmovil/ver/"+orden,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
         let d = res.data.citas;
         let v = res.data.vehiculo;
         let u = res.data.ubicacion;
          //console.log(res.data);
          setSiniestro(d.sini); 
          setSin(d.devolucion_fase3); 
          setAse(d.asegurado_nombre); 
          setDec(d.declarante_nombre); 
          setPla(d.placa); 
          setOfi(d.oficina); 
          setVeh(d.placaasig); 
          setAge(d.agendada_por); 
          setDia(d.dias_servicio); 
          setFlota(d.flota); 
          setUltKm(v.ultimokm); 
          setObs(d.obs_devolucion); 
          setNameOperario(d.nombreoperariodev)
          setOperario(d.operario_domiciliod)
          setEstado(d.estadod)
          setFase(d.devolucion_fase1)

          setDomicilio(d.dir_domiciliod)
          setUbicacion(u.estado);
          setUbicacionKi(u.odometro_final);
          setUbicacionKf(u.odometro_inicial);

          window.localStorage.setItem('placa',d.placaasig);
         // document.getElementById("obs").innerHTML = d.obs_devolucion; devolucion_fase1
          window.localStorage.setItem('ukm',v.ultimokm);
          window.localStorage.setItem('UBC',d.dir_domiciliod);
          window.localStorage.setItem('acta','DEVOLUCION');
          var asi = d.operario_domicilio;
          var est = d.estadod;

          var btn = '<button class="btn btn-primary btn-block" onclick="asignar()">Asignar Operacio</button>';
          if(asi == 0){
            //document.getElementById("boton_asignar").innerHTML = btn;
          }else{
            var btn2 = 'La cita de entrega esta concluida <br><a href="" class="btn btn-danger btn-block">Toma de Imagenes</a>';
            if(est == 'P'){
              var btn2 = '<button class="btn btn-info btn-block"   data-toggle="modal" data-target="#modal-km" role="button">Registrar Observaciones</button>';
            }
           
            //document.getElementById("boton_asignar").innerHTML ='<div class="text-center">Operario:'+ d.nombreoperario+' <br>'+btn2+'</div>';
          }
          var dom = d.dir_domicilio;
          if(dom!=''){
            //document.getElementById('desplazamiento').style.display = 'block';
          }
          
        }
      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });
}

function save_registro_2(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');
  var lat = window.localStorage.getItem('lat');
  var lon = window.localStorage.getItem('lon');

  if(kmd2==''){
    alert("Debes de digitar el kilOmetraje final del domicilio");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmd2)) {
     alert("Debe escribir el kilometraje final valido igual o mayor que "+Ukm+". ");
     return false;
  }
  if(EstadoDom==''){
    alert("Debes de seleccionar el estado");
    return false;
  }
  if(ObservacionesDom==''){
    alert("Debes de digitar alguna observacion.");
    return false;
  }
  setCargando(1);
  //loading();
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd2,tpq:tpq,observaciones:ObservacionesDom,kilometro:Ukm , estado:EstadoDom,lat:lat,lon:lon};
  axios.post(uri+'appmovil/savedevolucion_2', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Kilometraje Final Domicilio',
          message: res.data.msj,
          buttons: ['OK'],
        })
        //DetalleCita();
        window.location.href = '/devoluciones';
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}

async function Principal() {
  window.location.href = '/home';
}
function Asignar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  loading();
  axios({
    method: "get",
    url: uri + "appmovil/asignar_devolucion/"+orden+"/"+operario,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.Msj,
            buttons: ['OK'],
          })
          //DetalleCita();
          window.localStorage.setItem('acta','DEVOLUCION');
          window.localStorage.setItem('odo',Ukm);
          window.location.href = '/conductor';
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function Quitar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  loading();
  axios({
    method: "get",
    url: uri + "appmovil/desasignar_devolucion/"+orden+"/"+operario,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.Msj,
            buttons: ['OK'],
          })
          DetalleCita();
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
async function Entrega() {
  window.localStorage.setItem('acta','DEVOLUCION');
  window.localStorage.setItem('odo',Ukm);
  window.location.href = '/actaDev';

}
function save_registro(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');

  if(kmi==''){
    alert("Debes de digitar el kilimetraje inicial");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmi)) {
     alert("Debe escribir un kilometraje inicial valido igual o mayor que el ultimo registrado. No puede ser menor ni mayor que 2 kilometros mas del ultimo registrado en la tabla de control.");
     return false;
  }
  if(Observaciones==''){
    alert("Debes de escribir alguna observacion ");
    return false;
  }
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd,tpq:tpq,observaciones:Observaciones,kilometro:Ukm };
  axios.post(uri+'appmovil/saveentrega', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Datos registrado',
          message: res.data.msj,
          buttons: ['OK'],
        })
        DetalleCita();
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
function Salir() {
  localStorage.clear();
  window.location.href = "/";
}
function Listaentrega() {
  localStorage.clear();
  window.location.href = "/citas";
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
function loading(){
  present({
    message: 'Enviando...',
    duration: 3000,
    cssClass: 'custom-loading'
  })
}
useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
const [present] = useIonLoading();
  return (
    <IonPage>
     
     <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           <IonImg
                className="rounded-pill"
                onClick={Entregas}
                src={require("../assets/images/backwhite.png")}
              ></IonImg>
            
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
      <GeolocationButton />
      <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/info.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Cita Devolución. No.{ncita}</b>
                
                </div>
                <div className="toast-body  text-dark">
                <IonRow >
                 <IonCol><IonIcon icon={informationCircle} /><b> Siniestro Numero</b></IonCol>
                 <IonCol>{Siniestro?Siniestro:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={homeOutline} /> <b> Aseguradora</b></IonCol>
                 <IonCol>{flota?flota:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={person} /> <b>Nombre Asegurado</b></IonCol>
                 <IonCol>{iasegurado?iasegurado:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={person} /> <b>Nombre Declarante</b></IonCol>
                 <IonCol>{ideclarante?ideclarante:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
              <IonRow>
                 <IonCol><IonIcon icon={carOutline} /><b> Placa Siniestrada</b></IonCol>
                 <IonCol>{iplaca?iplaca:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
              </IonRow>
                </div>
              </div>
            </div>
       
          </div>
          <br />
          <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/info.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b>INFORMACION DE LA CITA</b>
                
                </div>
                <div className="toast-body  text-dark">
                    <IonRow>
                      <IonCol><IonIcon icon={homeOutline} /><b> Oficina</b></IonCol>
                      <IonCol>{ioficina?ioficina:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={carOutline} /><b> Vehiculo a entregar</b></IonCol>
                      <IonCol>{ivehiculo?ivehiculo:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={person} /><b> Agendada por</b></IonCol>
                      <IonCol>{iagendado?iagendado:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={todayOutline} /><b> Dias de servicio</b></IonCol>
                      <IonCol>{idias?idias:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={speedometer} /><b>Ultimo Km </b></IonCol>
                      <IonCol>{Ukm?Ukm:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    {Domicilio!=''?
                    <IonRow>
                      <IonCol><IonIcon icon={mapSharp} /><b>Domicilio </b></IonCol>
                      <IonCol>{Domicilio?Domicilio:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>:''
                    }

{
            
            ioperario!="0"?
            <>
            <IonLabel  color="danger"><IonIcon icon={person} />OPERARIO ASIGNADO: {NombreOperario}</IonLabel>
            <IonButton className='header' onClick={() =>
              presentAlert({
                header: 'Estas seguro de desasignar el operario?',
                buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'alert-button-cancel',
                    handler: () => {
                      setHandlerMessage('Proceso cancelado');
                    },
                  },
                  {
                    text: 'Desligar',
                    role: 'confirm',
                    cssClass: 'alert-button-confirm',
                    handler: () => {
                      setHandlerMessage('Operario desasignado');
                      Quitar();
                    },
                  },
                ],
                onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
              })}>
            <IonIcon slot="start" icon={trash}></IonIcon>
            Quitar Operario
          </IonButton>
          </>
            :
                <IonButton expand='block' className='header' onClick={() =>
          presentAlert({
            header: 'Estas seguro de asignar el operario?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'alert-button-cancel',
                handler: () => {
                  setHandlerMessage('Proceso cancelado');
                },
              },
              {
                text: 'Asignar',
                role: 'confirm',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  setHandlerMessage('Operario asignado');
                  Asignar();
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
          })} ><IonIcon icon={person} />  ASIGNAR OPERARIO </IonButton>}

         {
          ivehiculo?
          Estado=="P"?
             ioperario!="0"?
             <>
                      {/*  <Modal /> */}
                       <IonButton id="savekm" expand="block" className='header' onClick={Entrega}>
                           DEVOLUCION
                       </IonButton> </>
                          :''
                          :isiniestro=='0'?
                                      <IonButton id="open-fotos" expand="block" className='header' onClick={TomarFotos}> Tomar fotos</IonButton>
                                    :
                                    <>
                                      <IonItem>
                                          <IonLabel position="stacked">Km Fin del domicilio:</IonLabel>
                                          <IonInput  placeholder="KM" value={kmd2} onIonChange={(e) => setKmd2(e.detail.value!)}/>
                                      </IonItem>
                                      <IonItem>
                                        <IonLabel>Estado en que queda el vehiculo:</IonLabel>
                                        <IonSelect value={EstadoDom} onIonChange={(e) => setEstadoDom(e.detail.value!)}>
                                        
                              
                                          <IonSelectOption value="5">Fuera de servicio</IonSelectOption>
                                          <IonSelectOption value="8">Alistamiento</IonSelectOption>
                                       
                                        </IonSelect>
                                      </IonItem>
                                      <IonItem>
                                      <IonLabel position="stacked">observaciones:</IonLabel>
                                      <IonTextarea
                                          placeholder="Digite alguna observacion"
                                          autoGrow={true}
                                          value={ObservacionesDom} onIonChange={(e) => setObsDom(e.detail.value!)}
                                    ></IonTextarea>
                                    </IonItem>
                                    {Cargando=="0"?
                                        <IonButton id="savekm" expand="block" className='header' onClick={save_registro_2}>
                                          Guardar Kilometraje.
                                        </IonButton>:
                                        <IonButton expand="block" className='color' ><IonSpinner color="light"></IonSpinner> Guardando.. </IonButton>}
                                    </>
                   :'Cargando..'                 
                                    
                                    }



            {/*  <IonButton id="open-fotos" expand="block" className='header' onClick={VerImagenes}> Ver Imagenes de entrega {Fase}</IonButton>
         */}
 <p>{handlerMessage}</p>
                 </div>
              </div>
            </div>
       
          </div>
       
      </IonContent>
      <IonFooter className="ion-text-center">
        <IonButton className="boton" onClick={Principal}>
          <IonIcon icon={home} />
        </IonButton>
      </IonFooter>

    </IonPage>
  );
};

export default Home;
