
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonImg, IonIcon } from '@ionic/react';
import React, { useState } from 'react';

import qr1 from '../assets/qr/1.png';
import qr2 from '../assets/qr/1.png';
import qr3 from '../assets/qr/1.png';
import qr4 from '../assets/qr/1.png';
import qr5 from '../assets/qr/1.png';
import qr6 from '../assets/qr/1.png';
import qr7 from '../assets/qr/1.png';
import qr8 from '../assets/qr/1.png';
import qr9 from '../assets/qr/1.png';
import qr10 from '../assets/qr/1.png';
import qr11 from '../assets/qr/1.png';
import qr12 from '../assets/qr/1.png';
import { informationCircle, qrCodeSharp } from 'ionicons/icons';


interface PintarCanvasProps {
  oficina: string;
}

const Modalqr: React.FC<PintarCanvasProps> = ({ oficina}) => {
  let o = window.localStorage.getItem("o");
  const [isOpen, setIsOpen] = useState(false);
  const [Ofic, setOfic] = useState(o);
  return (
    
      <div>
        <IonButton expand="block" onClick={() => setIsOpen(true)}>
        <IonIcon color='danger' icon={qrCodeSharp}></IonIcon>
          Mostrar Codigo Qr
        </IonButton>
        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>AOA</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Cerrar</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <p>
              Para que el usuario pueda hacer el arribo debe de escanear este QR
            </p>
            <IonImg src={'https://pot.aoacolombia.com/images/qr/qr_code'+o+'.png'} />
          </IonContent>
        </IonModal>
      </div>
  );
}

export default Modalqr;