import { IonContent, useIonAlert, IonHeader, IonPage, IonFooter, IonTitle, IonToolbar, IonButton, IonImg, IonItem, IonLabel, IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, IonSpinner, IonItemSliding, IonRadio, IonRadioGroup, IonItemDivider, IonCheckbox, IonFab, IonFabButton, IonText, IonThumbnail, IonSelect, IonSelectOption, IonAccordion, IonAccordionGroup, IonNote } from '@ionic/react';
import './Style.css';
import { useState, useRef , useEffect} from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { warning, calendar, eye, search, people, document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, save, cellular, mail, keySharp, checkmark, camera, time, star, chevronDownCircle, videocam, phoneLandscape, phonePortrait, cameraReverseSharp, imageOutline, eyedrop, pencil, logoApple, trash, reload, logoIonic, carSport, listCircleOutline, waterOutline, layers, partlySunny, fileTray, flag } from 'ionicons/icons';
import axios from 'axios';
import Croquis from './ActaDevBk';
import Conexio from '../services/Index';
import MiCanvas from './Canvas';
import MiCanvas2 from './CanvasEntrega';
import TomarFoto from './TomarFotosEntrega';

import frontal from '../assets/images/frontal.png';
import derecha from '../assets/images/derecha.png';
import izquierda from '../assets/images/izquierda.png';
import posterior from '../assets/images/posterior.png';
import motor from '../assets/images/motor.png';
import maletero from '../assets/images/maletero.png';
import odometro from '../assets/images/odometro.png';
import cedula from '../assets/images/cedula.png';
import extintor from '../assets/images/extintor.png';
import licencia from '../assets/images/licencia.png';
import contrato from '../assets/images/contrato.png';
import ChildComponent from './pruebas';

const Actas: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  let fechaactual = new Date().toLocaleString().split(",");
  let f = fechaactual[0].split("/");
  let h = fechaactual[1].split("/");
  let hora1 = h[0];
  const [Loading, setLoading] = useState<any>(0);
  const [Siniestro, setSiniestro] = useState("Cargando");
  const [IdSiniestro, setIdSiniestro] = useState("Cargando");

  const [presentAlert] = useIonAlert();
  var cita = window.localStorage.getItem('cita');
  const [Lista, setLista] = useState<any[]>([]);
  const [ListaObservaciones, setObservaciones] = useState<any[]>([]);


  const [Observaciones, setObskm] = useState("");
  const [Estado, setEstado] = useState("");
  //const [checkboxValues, setCheckboxValues] = useState([false, false, false]);
  // carga la imagen

  const [textareaValue, setTextareaValue] = useState('');
  const [PasarId, setPasarId] = useState('');


  //fin  del almcenamiento de imagenes
  const [Cargando, setCargando] = useState<any>("0");
  const [Cargandof, setCargandof] = useState<any>("0");
 
  const [Cita, setCita] = useState<any>(cita);
  const [Nivel, setNivel] = useState<any>("0");
  const [Placa, setPlaca] = useState<any>("");
  const [Km, setKm] = useState<any>("");
  const [Acta, setActa] = useState<any>(window.localStorage.getItem('acta'));
  const [UltKm, setUltkm] = useState<any>(window.localStorage.getItem('odo'));
  const [UBC, setUbc] = useState(window.localStorage.getItem("UBC"));



  //Obseraciones por item
  const [ObsNombre, setObsNombre] = useState<any>("");
  const [ObsId, setObsId] = useState<any>("");
  const [ObsSel, setObsSel] = useState<any>("");
  const [Obs, setObs] = useState<any>("");
  const [Odometro, setOdometro] = useState<any>("");
  //::::::::::::::::::::::::::::::::::::::::::::codigo nuevo:::::::::::::::::::::::::::::::::::::::::::::::::
  const [Lado, setLado] = useState<any>("");
  const [checkboxValues, setCheckboxValues] = useState<any[]>([]);
  const [observations, setObservations] = useState<any[]>([]);
  const [Croquis11, setCroquis1] = useState<any>("");
  const [Croquis2, setCroquis2] = useState<any>("");
  const [Croquis3, setCroquis3] = useState<any>("");
  const [Croquis4, setCroquis4] = useState<any>("");
  const [Croquis5, setCroquis5] = useState<any>("");

  const [CroquisGuardado1, setCroquisGuardado1] = useState<any>("");
  const [CroquisGuardado2, setCroquisGuardado2] = useState<any>("");
  const [CroquisGuardado3, setCroquisGuardado3] = useState<any>("");
  const [CroquisGuardado4, setCroquisGuardado4] = useState<any>("");
  const [CroquisGuardado5, setCroquisGuardado5] = useState<any>("");

  const [ImgCed, setImgCed] = useState<any>("");
  const [ImgCed2, setImgCed2] = useState<any>("");
  const [ImgLic, setImgLic] = useState<any>("");
  const [ImgLic2, setImgLic2] = useState<any>("");
  const [ImgLic3, setImgLic3] = useState<any>("");
  const [ImgLic4, setImgLic4] = useState<any>("");
  const [ImgAut, setImgAut] = useState<any>("");
  const [ImgPol, setImgPol] = useState<any>("");
  const [ImgCom, setImgCom] = useState<any>("");
  const [ImgOrd, setImgOrd] = useState<any>("");

  const [ImgOdo, setImgOdo] = useState<any>("");
  const [ImgIzq, setImgIzq] = useState<any>("");
  const [ImgAtr, setImgAtr] = useState<any>("");
  const [ImgDer, setImgDer] = useState<any>("");
  const [ImgFre, setImgFre] = useState<any>("");
  const [ImgExt, setImgExt] = useState<any>("");
  const [ImgAcc, setImgAcc] = useState<any>("");
  const [ImgMot, setImgMot] = useState<any>("");
  //contadores de completado
  const [ContInterno, setContInterno] = useState<number>(0);

  const [selectedValue, setSelectedValue] = useState<string>('');
  const handleRadioChange = (event: CustomEvent) => {
    setSelectedValue(event.detail.value);
    MarcarGasolina(event.detail.value);
  };
  const MarcarGasolina = async (id: number) => {
    var cita = window.localStorage.getItem('cita');
    setNivel(id);
    const datos = { cita: cita, nivel: id, acta: Acta };
    axios.post(uri + 'appmovil/respuestasGas', datos)
      .then(function (res) {
        //alert(res.data.msj);
        console.log(res.data);
      })
      .catch(function (err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none'
      });
  }
  async function ValidarOdometro(){

    console.log('Guardando km:', Km + 'ultimo'+UltKm);
    if(Km==''){
      return false;
    }
    if(parseInt(Km) < parseInt(UltKm)){
      alert("El kilometraje digitado "+Km+" es menor al actual "+UltKm);
      setKm('');
      return false;
    }
    var diferencia = parseInt(Km) - parseInt(UltKm);
    var limite = 100;
    if(diferencia > 800){
      alert("¡¡ADVERTENCIA!!\n El kilometraje digitado supera al ultimo registrado "+UltKm);
    }
    //GuardarObs();
    
  }
 //::::::::::::::::::::::::fin variables canvas::::::::::::::::::::::::::::::::::::::::

  //::::::::::::::::::::::::seleccionar checke::::::::::::::::::::::::::::::::::::::
  const handleCheckboxChange = (index: number, isChecked: boolean, nombre: string, id: number) => {
    const newCheckboxValues = [...checkboxValues];
    newCheckboxValues[index] = isChecked;
    setCheckboxValues(newCheckboxValues);
    console.log(newCheckboxValues[index]);
    let selecionado = newCheckboxValues[index];
    var o = '';
    if (selecionado == true) {
      setObsNombre(nombre);
      setObsId(id);
      setObsSel(selecionado);
      //setShowModal2(true)
    } else {
      setObs("");
    }
    //setNext(true);
    Marcar(nombre, id, selecionado, o);

  };
  //:::::::::::::::::::::::::::guardamos el checbox seleccionado
  const Marcar = async (n: string, id: number, selecionado: boolean, obs: string) => {
    var cita = window.localStorage.getItem('cita');

    //setShowModal2(false);
    const datos = { cita: cita, nombre: n, id: id, acta: Acta, selecionado: selecionado, obs: obs };
    axios.post(uri + 'appmovil/respuestas', datos)
      .then(function (res) {
        //alert(res.data.msj);
        console.log(res.data);
        setObs("");
        //setNext(false);
      })
      .catch(function (err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function () {
        //setNext(false);
        //loading.style.display = 'none'
      });
  }
  //guardamos las observaciones
  async function handleTextareaBlur() {

    console.log(PasarId + 'Guardando:', textareaValue);
    GuardarObs();

  }
  async function PasarObservaciones(obs: string, id: string) {
    //console.log("obs "+id+":"+obs);
    setTextareaValue(obs);
    setPasarId(id);
  }
  function GuardarObs() {

    var cita = window.localStorage.getItem('cita');
    if (textareaValue != "") {
      const datos = { id: PasarId, obs: textareaValue, acta: Acta };
      axios.post(uri + 'appmovil/guardarobs', datos)
        .then(function (res) {
          //alert(res.data.msj);
          console.log(res.data);
          //setTextareaValue('');
          //setPasarId('');
        })
        .catch(function (err) {
          //Swal.fire('Error');
          console.log(err);
        })
        .then(function () {
          //loading.style.display = 'none'
        });
    } else {
      console.log("no se guardo las obs");
    }


  }
  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;

  function DetalleCita() {
    var usuario = window.localStorage.getItem('key');
    var token = window.localStorage.getItem('key_t');
    var cita = window.localStorage.getItem('cita');
    const datos = { cita: cita };
    axios.post(uri + 'appmovil/infoactaEntrega', datos)
      .then(function (res) {
        //alert(res.data.msj);
        console.log(res.data);
        var d = res.data.lista;
        var s = res.data.siniestro;
        var a = res.data.acta;
        var c = res.data.cita;
        /* if(a.odometro_final!=''){
          setKm(a.odometro_final);
        } */

        setCroquisGuardado1(a.imagen_izquierdo_ent);
        setCroquisGuardado2(a.imagen_atras_ent);
        setCroquisGuardado3(a.imagen_derecho_ent);
        setCroquisGuardado4(a.imagen_frente_ent);
        setCroquisGuardado5(a.imagen_superior_ent);

        setLoading(1);
        setSiniestro(s.numero)
        setIdSiniestro(s.id)
        setPlaca(c.placa);
        cargarimagen(s);
          var f = res.data;
          
          setCroquis1(f.izquierdo);
          setCroquis2(f.atras);
          setCroquis3(f.derecho);
          setCroquis4(f.frente);
          setCroquis5(f.superior);
          setLista(d);
        //RECORRE LOS CKECBOS Y LOS SELECCIONA
        const resultado = d.map((elemento: any, i: number) => {
          setCheckboxValues((prevCheckboxValues) => {
            const updatedCheckboxValues = [...prevCheckboxValues];
            updatedCheckboxValues[i] = elemento.respuesta_entrega == 'No' ? false : true;
            return updatedCheckboxValues;
          });
        });


      })
      .catch(function (err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function () {
        //loading.style.display = 'none'
      });
  }
  function cargarimagen(s:any){
    let aws = 'https://app.aoacolombia.com/Control/operativo/img/fotocargada.png';
    if(s.img_odo_salida_f!=""){
      setImgOdo(aws);
    }
    if(s.fotovh1_f!=""){
      setImgFre(aws);
    }
    if(s.fotovh2_f!=""){
      setImgIzq(aws);
    }
    if(s.fotovh3_f!=""){
      setImgDer(aws);
    }
    if(s.fotovh4_f!=""){
      setImgAtr(aws);
    }
    if(s.eadicional1_f!=""){
      setImgAcc(aws);
    }
    if(s.eadicional2_f!=""){
      setImgMot(aws);
    }
    if(s.img_extintor_ent_f!=""){
      setImgExt(aws);
    }
    //documentos 
    if(s.img_cedula_f!=""){
      setImgCed(aws);
    }
    if(s.img_pase_f!=""){
      setImgCed2(aws);
    }
    
    if(s.adicional1_f!=""){
      setImgLic(aws);
    }
    if(s.adicional2_f!=""){
      setImgLic2(aws);
    }
    
      if(s.adicional3_f!=""){
        setImgLic3(aws);
      }
      if(s.adicional4_f!=""){
        setImgLic4(aws);
      }
      if(s.img_carta_autorizacion_f!=""){
        setImgAut(aws);
      }
      if(s.img_fotocopia_poliza_f!=""){
        setImgPol(aws);
      }
      if(s.img_camara_comercio_f!=""){
        setImgCom(aws);
      }
      if(s.img_orden_ingreso_taller_f!=""){
        setImgOrd(aws);
      }
  
  }
 
  async function pasar(LADO:String){
    //guardamos el croquis al darle click boton
          setLado(LADO);
          //PintarCanvas(LADO);
         
  }
  //fin del codigo canvas::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  async function Entregas() {
    if (Acta == 'ENTREGA') {
      window.location.href = '/detalle';
    } else {
      window.location.href = '/detalle_dev';
    }

  }


  function Salir() {
    //localStorage.clear();
    window.location.href = "/";
  }
  function Listaentrega() {
    window.location.href = "/citas";
  }
  function Listadev() {
    window.location.href = "/devoluciones";
  }
  async function bogota() {
    window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
  }
  async function Perfil() {
    window.location.href = "/perfil";
  }
  async function funza() {
    window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
  }
  function GuardarActa() {
  
    var nick = window.localStorage.getItem('name_u');
    var token = window.localStorage.getItem('key_t');
    var ope = window.localStorage.getItem('key');
    var lat = window.localStorage.getItem('lat');
    var lon = window.localStorage.getItem('lon');
  
  console.log(Km)
  var cita = window.localStorage.getItem('cita');
  
  if(Km==0 || Km==''){
    alert("Debes de digitar el valor del odometro");
    return false;
  }
  if(parseInt(UltKm) > parseInt(Km)) {
    alert("Debes escribir un kilometraje  valido igual o mayor a "+UltKm+". ");
    return false;
  }
  if(Observaciones==''){
    alert("Debes de digitar alguna observacion");
    return false;
  }
    setCargando(1);
    const datos = { cita: cita, km: Km, acta: Acta, usuario: nick, observaciones: Observaciones, lat: lat, lon: lon, Estado: Estado };
    axios.post(uri + 'appmovil/saveodometro', datos)
      .then(function (res) {
        //alert(res.data.msj);
        alert(res.data.msj);
        if (res.data.estado == 'ok') {
     
            window.location.href = '/firmadas';
         

        }


        console.log(res.data);
        setCargando(0);
      })
      .catch(function (err) {
        //Swal.fire('Error');
        alert('Ocurrio un error, comunicate con tecnologia');
        setCargando(0);
        console.log(err);
      })
      .then(function () {
        setCargando(0);
        //loading.style.display = 'none'
      });

  }

  useEffect(() => {
    DetalleCita();
  }, []);
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">

            <IonImg
              className="rounded-pill"
              onClick={Entregas}
              src={require("../assets/images/backwhite.png")}
            ></IonImg>
            ACTA DE {Acta}

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listadev}>
                    Lista de devoluciones
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)#" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        
        <IonItem className='placa'>
         
                  <IonLabel>{Placa}</IonLabel>
                 
                </IonItem>
                <br></br>
        <IonItem>
        <IonIcon color='primary' icon={informationCircle}></IonIcon>
          <label>Id: {IdSiniestro}</label>
        </IonItem>
        <IonItem>
        <IonIcon color='primary' icon={informationCircle}></IonIcon>
          <label>Numero: {Siniestro}</label>
        
        </IonItem>
        <br></br>
        {Loading == 1 ?
        <>
        <IonAccordionGroup >
        <IonAccordion value="cero">
            <IonItem slot="header"  color="rose">
            <IonIcon color='info' icon={document}></IonIcon>
              <IonLabel> . Toma de Documentos</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="CEDULA" cita={Cita} foto={ImgCed} side="Cedula Frente" icono={cedula}/>
            <TomarFoto ladito="CEDULA2" cita={Cita} foto={ImgCed2} side="Cedula reverso" icono={cedula}/>
            <TomarFoto ladito="LICENCIA" cita={Cita} foto={ImgLic} side="Licencia Frente" icono={licencia}/>
            <TomarFoto ladito="LICENCIA2" cita={Cita} foto={ImgLic2} side="Licencia Reverso" icono={licencia}/>
            <TomarFoto ladito="CEDULA-FRENTE" cita={Cita} foto={ImgLic3} side="Garantia Tercero" icono={cedula}/>
            <TomarFoto ladito="CEDULA-ATRAS" cita={Cita} foto={ImgLic4} side="Garantia Tercero" icono={cedula}/>
            <TomarFoto ladito="AUTORIZACION" cita={Cita} foto={ImgAut} side="Carta Autorizacion" icono={contrato}/>
            <TomarFoto ladito="POLIZA" cita={Cita} foto={ImgPol} side="Documento Poliza" icono={contrato}/>
            <TomarFoto ladito="CAMARA" cita={Cita} foto={ImgCom} side="Camara Comercio" icono={contrato}/>
            <TomarFoto ladito="ORDEN" cita={Cita} foto={ImgOrd} side="Orden Ingreso Taller" icono={contrato}/>
              <IonItemDivider>
                
              </IonItemDivider>
            
            </div>

          </IonAccordion>
          <br></br>
          <IonAccordion value="first">
            <IonItem slot="header"  color="rose">
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Interno</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="INTERNO" cita={Cita} foto={ImgOdo} side="Foto Odometro" icono={odometro}/>
            <IonItemDivider>
                <IonLabel>
                  NIVEL DE GASOLINA
                </IonLabel>
              </IonItemDivider>
              <IonRadioGroup value={selectedValue} onIonChange={handleRadioChange}>
                <IonItem className='item-background-color'>
                <IonIcon  icon={waterOutline}></IonIcon>
                  <IonLabel>100%</IonLabel>
                  <IonRadio slot="end" value="100" ></IonRadio>
                </IonItem>

                <IonItem className='item-background-color2'>
                <IonIcon  icon={waterOutline}></IonIcon>
                  <IonLabel>75%</IonLabel>
                  <IonRadio slot="end" value="75" ></IonRadio>
                </IonItem>

                <IonItem className='item-background-color3'>
                <IonIcon  icon={waterOutline}></IonIcon>
                  <IonLabel>50%</IonLabel>
                  <IonRadio slot="end" value="50"></IonRadio>
                </IonItem>

                <IonItem  className='item-background-color4'>
                <IonIcon  icon={waterOutline}></IonIcon>
                  <IonLabel>. 25%</IonLabel>
                  <IonRadio slot="end" value="25"></IonRadio>
                </IonItem>
              </IonRadioGroup>
              <IonItemDivider>
                 <IonIcon  icon={speedometer}></IonIcon>
                <IonLabel>
                  ODOMETRO {Acta}
                </IonLabel>
              </IonItemDivider>
              <div className="" id="collapseExample">
                <div className="card card-body">

                  <IonInput className='form-control ' placeholder="Kilometraje del odometro" id='km' name='km' value={Km}
                    onIonChange={(e) => setKm(e.detail.value!)}
                    onBlur={ValidarOdometro} />
                </div>
              </div>
              <IonItemDivider>
                <IonLabel>
                  VEHICULO INTERNO
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
              {Loading == 1 ?
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'INTERNO' ?
                      <>
                     
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                           {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                :
                <IonItem>
                  <IonLabel>Cargando Preguntas..</IonLabel>
                  <IonSpinner name="dots"></IonSpinner>
                </IonItem>
              }
            </div>

          </IonAccordion>
          
          <IonAccordion value="second">
            <IonItem slot="header" color="light"  onClick={() => pasar('IZQUIERDO')}>
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Izquierdo</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="IZQUIERDO" cita={Cita} foto={ImgIzq} side="Foto lado Izq" icono={izquierda}/>
            {CroquisGuardado1==''?'':<div style={{'color':'green','fontSize':'12px'}}>El croquis ya se encuentra cargado. si deseas editarlo puedes hacerlo</div>}
            
            {Loading == 1 ?
             <MiCanvas ladito="IZQUIERDO" cita={Cita} foto={Croquis11} side="imagen_izquierdo_ent"/>
            :
              <IonItem>
                <IonLabel>Cargando Croquis..</IonLabel>
                <IonSpinner name="dots"></IonSpinner>
              </IonItem>
            }
           
            <IonItemDivider>
                <IonLabel>
                  VEHICULO IZQUIERDO
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
              {Loading == 1 ?
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'IZQUIERDO' ?
                      <>
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                            {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                :
                <IonItem>
                  <IonLabel>Cargando Preguntas..</IonLabel>
                  <IonSpinner name="dots"></IonSpinner>
                </IonItem>
              }
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem slot="header" color="light">
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Atras</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="ATRAS" cita={Cita} foto={ImgAtr} side="Foto lado Atras" icono={posterior}/>
            <TomarFoto ladito="ACCESORIOS" cita={Cita} foto={ImgAcc} side="Foto de Accesorios" icono={maletero}/>
            <TomarFoto ladito="EXTINTOR" cita={Cita} foto={ImgExt} side="Foto del extintor" icono={extintor}/>
            {CroquisGuardado2==''?'':<div style={{'color':'green','fontSize':'12px'}}>El croquis ya se encuentra cargado. si deseas editarlo puedes hacerlo</div>}
            {Loading == 1 ?
             <MiCanvas ladito="ATRAS" cita={Cita} foto={Croquis2} side="imagen_atras_ent"/>
            :
              <IonItem>
                <IonLabel>Cargando Croquis..</IonLabel>
                <IonSpinner name="dots"></IonSpinner>
              </IonItem>
            }
            <IonItemDivider>
                <IonLabel>
                  VEHICULO ATRAS
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
              {Loading == 1 ?
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'ATRAS' ?
                      <>
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                            {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                :
                <IonItem>
                  <IonLabel>Cargando Preguntas..</IonLabel>
                  <IonSpinner name="dots"></IonSpinner>
                </IonItem>
              }
            </div>
          </IonAccordion>
          <IonAccordion value="cuatro">
            <IonItem slot="header" color="light" onClick={() => pasar('DERECHO')}>
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Derecho</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="DERECHO" cita={Cita} foto={ImgDer} side="Foto lado Derecho" icono={derecha}/>
            {CroquisGuardado3==''?'':<div style={{'color':'green','fontSize':'12px'}}>El croquis ya se encuentra cargado. si deseas editarlo puedes hacerlo</div>}
            {Loading == 1 ?
             <MiCanvas ladito="DERECHO" cita={Cita} foto={Croquis3} side="imagen_derecho_ent"/>
            :
              <IonItem>
                <IonLabel>Cargando Croquis..</IonLabel>
                <IonSpinner name="dots"></IonSpinner>
              </IonItem>
            }
            <IonItemDivider>
                <IonLabel>
                  VEHICULO DERECHO
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
              {Loading == 1 ?
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'DERECHO' ?
                      <>
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                            {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                :
                <IonItem>
                  <IonLabel>Cargando Preguntas..</IonLabel>
                  <IonSpinner name="dots"></IonSpinner>
                </IonItem>
              }
            </div>
          </IonAccordion>
          <IonAccordion value="cinco">
            <IonItem slot="header" color="light" onClick={() => pasar('FRENTE')}>
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Frente</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            <TomarFoto ladito="FRENTE" cita={Cita} foto={ImgFre} side="Foto de Frente" icono={frontal}/>
            <TomarFoto ladito="MOTOR" cita={Cita} foto={ImgMot} side="Foto del Motor" icono={motor}/>
            {CroquisGuardado4==''?'':<div style={{'color':'green','fontSize':'12px'}}>El croquis ya se encuentra cargado. si deseas editarlo puedes hacerlo</div>}
            {Loading == 1 ?
             <MiCanvas ladito="FRENTE" cita={Cita} foto={Croquis4} side="imagen_frente_ent"/>
            :
              <IonItem>
                <IonLabel>Cargando Croquis..</IonLabel>
                <IonSpinner name="dots"></IonSpinner>
              </IonItem>
            }
            <IonItemDivider>
                <IonLabel>
                  VEHICULO FRENTE
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
              {Loading == 1 ?
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'FRENTE' ?
                      <>
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                            {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                :
                <IonItem>
                  <IonLabel>Cargando Preguntas..</IonLabel>
                  <IonSpinner name="dots"></IonSpinner>
                </IonItem>
              }
            </div>
          </IonAccordion>
          <IonAccordion value="seis">
            <IonItem slot="header" color="light" onClick={() => pasar('SUPERIOR')}>
            <IonIcon color='danger' icon={carSport}></IonIcon>
              <IonLabel> . Lado Superior</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
            {CroquisGuardado5==''?'':<div style={{'color':'green','fontSize':'12px'}}>El croquis ya se encuentra cargado. si deseas editarlo puedes hacerlo</div>}
            {Loading == 1 ?
             <MiCanvas ladito="SUPERIOR" cita={Cita} foto={Croquis5} side="imagen_superior_ent"/>
            :
              <IonItem>
                <IonLabel>Cargando Croquis..</IonLabel>
                <IonSpinner name="dots"></IonSpinner>
              </IonItem>
            }
            <IonItemDivider>
                <IonLabel>
                  VEHICULO SUPERIOR
                </IonLabel>
                <IonLabel slot='end'>
                  Si..?
                </IonLabel>
              </IonItemDivider>
           
                <IonList>
                  {Lista.map((item, i) => (
                    item.parte == 'SUPERIOR' ?
                      <>
                        <IonItem key={item.id}>
                        <IonIcon  icon={flag}></IonIcon>
                          <IonLabel>
                            {item.nombre_parte}
                            {Acta == 'DEVOLUCION' ? <h5>Entregado : {item.respuesta_entrega} </h5> : ''}

                          </IonLabel>

                          <IonCheckbox checked={checkboxValues[i]} onIonChange={(e) => handleCheckboxChange(i, e.detail.checked, item.nombre_parte, item.id)} id="open-modal" />
                          {/*  <IonCheckbox checked={Acta=='ENTREGA'?item.respuesta_entrega=='Si'?true:false:item.respuesta_devolucion=='Si'?true:false} slot="end" value={'1'} name={"pre"+item.id} id={"pre"+item.id} onClick={()=>Marcar(item.nombre_parte,item.id)}></IonCheckbox> */}
                        </IonItem>
                        {checkboxValues[i] === false ? (
                          <IonInput
                            type="text"
                            className="form-control"
                            name="observacion"
                            id={"observacion" + item.id}
                            placeholder={item.obs_entrega ? item.obs_entrega : "Digite alguna observacion"}

                            onIonChange={(e) => PasarObservaciones(e.detail.value!, item.id)}
                            onBlur={handleTextareaBlur}
                          />
                        ) : null}
                      </>
                      : ''
                  ))}


                </IonList>
                
                
              <IonItem>
              <IonLabel position="stacked">observaciones en general:</IonLabel>
              <IonTextarea 
                placeholder="Digite alguna observacion"
                
                autoGrow={true}
                value={Observaciones} onIonChange={(e) => setObskm(e.detail.value!)}
              ></IonTextarea>
            </IonItem>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
        <br />
       
           
             <IonButton expand="block" onClick={GuardarActa}>
  
              {Cargando == 0 ? 'Guardar Acta' : <><IonSpinner color="light"></IonSpinner>Cargando ...</>}
                 <IonIcon slot="end" icon={save}></IonIcon>
             </IonButton>
            
        
        </>
        :
        <IonItem>
          Cargando Datos de la cita....
          <IonSpinner color="secondary"></IonSpinner>
        </IonItem>
        }
      </IonContent>
    </IonPage>
  );
};

export default Actas;
