import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,IonImg, IonItem, IonLabel, IonList, IonThumbnail, IonIcon, IonText,IonSpinner,useIonLoading, useIonViewWillEnter  } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import '../theme/variables.css';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useState } from 'react';
import { arrowBack, bagCheck, camera, checkmark, homeOutline, save, trashSharp,warning } from 'ionicons/icons';
import frontal from '../assets/images/frontal.png';
import derecha from '../assets/images/derecha.png';
import izquierda from '../assets/images/izquierda.png';
import posterior from '../assets/images/posterior.png';
import tarjeta from '../assets/images/tarjeta.png';
import licencia from '../assets/images/licencia.png';
import odometro from '../assets/images/odometro.png';
import cedula from '../assets/images/cedula.png';
import adicional from '../assets/images/veh.jpg';
import axios from 'axios';
import Conexio from '../services/Index';
import GeolocationButton from "../components/GeolocationButton";

const Home: React.FC = () => {
  let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let h = fechaactual[1].split("/");
let hora1 = h[0];



let hoy =f[2].padStart(2, "0") +"-"+f[1].padStart(2, "0")+"-"+f[0].padStart(2, "0");
console.log(h[0]);
  var placa = window.localStorage.getItem('placa');

  var cita = window.localStorage.getItem('cita');
/*   var loc_odo = window.localStorage.getItem('odo_'+cita);
  var loc_fre = window.localStorage.getItem('fre_'+cita);
  var loc_atr = window.localStorage.getItem('atr_'+cita);
  var loc_izq = window.localStorage.getItem('izq_'+cita);
  var loc_der = window.localStorage.getItem('der_'+cita);
  var loc_ced = window.localStorage.getItem('ced_'+cita);
  var loc_cedr = window.localStorage.getItem('cedr_'+cita);
  var loc_lic = window.localStorage.getItem('lic_'+cita);
  var loc_licr = window.localStorage.getItem('licr_'+cita); */

  const [imageno, setImageno] = useState<any>("");
  const [imagenf, setImagenf] = useState<any>("");
  const [imagend, setImagend] = useState<any>("");
  const [imageni, setImageni] = useState<any>("");
  const [imagenp, setImagenp] = useState<any>("");
  const [imagencf, setImagencf] = useState<any>("");
  const [imagencr, setImagencr] = useState<any>("");
  const [imagenlf, setImagenlf] = useState<any>("");
  const [imagenlr, setImagenlr] = useState<any>("");

  const [imagenad1, setImagenad1] = useState<any>("");
  const [imagenad2, setImagenad2] = useState<any>("");

  const [Placa, setPlaca] = useState<any>(placa);

  const [Imagen1, setImagen1] = useState<any>("");
  const [Imagen2, setImagen2] = useState<any>("");
  const [Imagen3, setImagen3] = useState<any>("");
  const [Imagen4, setImagen4] = useState<any>("");
  const [Imagen5, setImagen5] = useState<any>("");
  const [Imagen6, setImagen6] = useState<any>("");
  const [Imagen7, setImagen7] = useState<any>("");
  const [Imagen8, setImagen8] = useState<any>("");
  const [Imagen9, setImagen9] = useState<any>("");
  const [Imagen10, setImagen10] = useState<any>("");
  const [Imagen11, setImagen11] = useState<any>("");


  var loc_h1 = window.localStorage.getItem('ho1_'+cita);
  var loc_h2 = window.localStorage.getItem('ho2_'+cita);
  var loc_h3 = window.localStorage.getItem('ho3_'+cita);
  var loc_h4 = window.localStorage.getItem('ho4_'+cita);
  var loc_h5 = window.localStorage.getItem('ho5_'+cita);
  var loc_h6 = window.localStorage.getItem('ho6_'+cita);
  var loc_h7 = window.localStorage.getItem('ho7_'+cita);
  var loc_h8 = window.localStorage.getItem('ho8_'+cita);
  var loc_h9 = window.localStorage.getItem('ho9_'+cita);
  var loc_h10 = window.localStorage.getItem('ho10_'+cita);
  var loc_h11 = window.localStorage.getItem('ho11_'+cita);

  const [Ho1, setHo1] = useState<any>(loc_h1?loc_h1:"");
  const [Ho2, setHo2] = useState<any>(loc_h2?loc_h2:"");
  const [Ho3, setHo3] = useState<any>(loc_h3?loc_h3:"");
  const [Ho4, setHo4] = useState<any>(loc_h4?loc_h4:"");
  const [Ho5, setHo5] = useState<any>(loc_h5?loc_h5:"");
  const [Ho6, setHo6] = useState<any>(loc_h6?loc_h6:"");
  const [Ho7, setHo7] = useState<any>(loc_h7?loc_h7:"");
  const [Ho8, setHo8] = useState<any>(loc_h8?loc_h8:"");
  const [Ho9, setHo9] = useState<any>(loc_h9?loc_h9:"");
  const [Ho10, setHo10] = useState<any>(loc_h10?loc_h10:"");
  const [Ho11, setHo11] = useState<any>(loc_h11?loc_h11:"");

  const [Odometro, setOdometro] = useState<any>("");
  const [Frente, setFrente] = useState<any>("");
  const [Atras, setAtras] = useState<any>("");
  const [Izquierdo, setIzquierdo] = useState<any>("");
  const [Derecho, setDerecho] = useState<any>("");
  const [Licencia, setLicencia] = useState<any>("");
  const [LicenciaR, setLicenciaR] = useState<any>("");
  const [Cedula, setCedula] = useState<any>("");
  const [CedulaR, setCedulaR] = useState<any>("");
  const [Adicional1, setAdicional1] = useState<any>("");
  const [Adicional2, setAdicional2] = useState<any>("");

  const [Nombreboton, setBoton] = useState<any>("0");
  const [Cargando, setCargando] = useState<any>("0");
  const [Resultado, setResultado] = useState<any>("0");



  let uri = Conexio;
  //let uri = 'https://pot.aoacolombia.com/api/';
  async function Entregas() {
    window.location.href = '/detalle';
}
const Base1 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setOdometro(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base2 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setFrente(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base3 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setIzquierdo(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base4 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setAtras(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base5 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setDerecho(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base6 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setCedula(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base7 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setCedulaR(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base8 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setLicencia(base64data); 
      resolve(base64data);
      
    }
  });
}
const Base9 = async (i:string) => {
  const data = await fetch(i);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setLicenciaR(base64data); 
      resolve(base64data);
      
    }
  });
}

function UploadFoto(campo:string,imagen:string){
//  setCargando(1);
    if(campo == 'odometro'){
      setHo1('Subiendo');
    }
    if(campo == 'frente'){
      setHo2('Subiendo');
    }
    if(campo == 'izquierdo'){
      setHo3('Subiendo');
    }
    if(campo == 'atras'){
      setHo4('Subiendo');
    }
    if(campo == 'derecho'){
      setHo5('Subiendo');
    }
    if(campo == 'cedula'){
      setHo6('Subiendo');
    }
    if(campo == 'cedular'){
      setHo7('Subiendo');
    }
    if(campo == 'licencia'){
      setHo8('Subiendo');
    }
    if(campo == 'licenciar'){
      setHo9('Subiendo');
    }
    if(campo == 'Adicional1'){
      setHo10('Subiendo');
    }
    if(campo == 'Adicional2'){
      setHo11('Subiendo');
    }
  var orden = window.localStorage.getItem('cita');
  const datos = {
    campo: campo,
    imagen:imagen,
     Cita:orden,
     tipo:'Entrega',
    };

  axios.post(uri+'appmovil/imagenes', datos)
      .then(function(res) {
        setResultado(res.data);
        console.log(res.data);
        if(campo == 'odometro'){
          setImagen1(1);
          setHo1(hora1);
        }
        if(campo == 'frente'){
          setImagen2(1);
          setHo2(hora1);
        }
        if(campo == 'izquierdo'){
          setImagen3(1);
          setHo3(hora1);
        }
        if(campo == 'atras'){
          setImagen4(1);
          setHo4(hora1);
        }
        if(campo == 'derecho'){
          setImagen5(1);
          setHo5(hora1);
        }
        if(campo == 'cedula'){
          setImagen6(1);
          setHo6(hora1);
        }
        if(campo == 'cedular'){
          setImagen7(1);
          setHo7(hora1);
        }
        if(campo == 'licencia'){
          setImagen8(1);
          setHo8(hora1);
        }
        if(campo == 'licenciar'){
          setImagen9(1);
          setHo9(hora1);
        }
        if(campo == 'Adicional1'){
          setImagen10(1);
          setHo10(hora1);
        }
        if(campo == 'Adicional2'){
          setImagen11(1);
          setHo11(hora1);
        }
        console.log(res.data);
      })
      .catch(function(err) {
        console.log('error james sin conexion');
        //opcion de error
        
        if(campo == 'odometro'){
          setImagen1(2);
          setHo1('Intentalo Nuevamente');
        }
        if(campo == 'frente'){
          setImagen2(2);
          setHo2('Intentalo Nuevamente');
        }
        if(campo == 'izquierdo'){
          setImagen3(2);
          setHo3('Intentalo Nuevamente');
        }
        if(campo == 'atras'){
          setImagen4(2);
          setHo4('Intentalo Nuevamente');
        }
        if(campo == 'derecho'){
          setImagen5(2);
          setHo5('Intentalo Nuevamente');
        }
        if(campo == 'cedula'){
          setImagen6(2);
          setHo6('Intentalo Nuevamente');
        }
        if(campo == 'cedular'){
          setImagen7(2);
          setHo7('Intentalo Nuevamente');
        }
        if(campo == 'licencia'){
          setImagen8(2);
          setHo8('Intentalo Nuevamente');
        }
        if(campo == 'licenciar'){
          setImagen9(2);
          setHo9('Intentalo Nuevamente');
        }
        if(campo == 'Adicional1'){
          setImagen10(2);
          setHo10('Intentalo Nuevamente');
        }
        if(campo == 'Adicional2'){
          setImagen11(2);
          setHo11('Intentalo Nuevamente');
        }
      })
      .then(function() {
        
        console.log('error james sn');
      });

}

  const takePictureo = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });

    var imageUrl:any = image.webPath;
    setImageno(imageUrl);
    const data = await fetch(imageUrl);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;  
      setOdometro(base64data); 
      resolve(base64data);
      console.log(base64data);
      var base : any = base64data;
     
      UploadFoto('odometro',base);
     // window.localStorage.setItem('odo_'+cita,base);
      window.localStorage.setItem('ho1_'+cita,hora1);
    }
  });
    
  };
  const takePicturef = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenf(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setFrente(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
   
        UploadFoto('frente',base);
       // window.localStorage.setItem('fre_'+cita,base);
        window.localStorage.setItem('ho2_'+cita,hora1);
      }
    });

  };
  const takePictured = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagend(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setDerecho(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

        UploadFoto('derecho',base);
       // window.localStorage.setItem('der_'+cita,base);
        window.localStorage.setItem('ho5_'+cita,hora1);
      }
    });

  };
  const takePicturei = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImageni(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setIzquierdo(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
   
        UploadFoto('izquierdo',base);
       // window.localStorage.setItem('izq_'+cita,base);
        window.localStorage.setItem('ho3_'+cita,hora1);
      }
    });

  };
  const takePicturep = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenp(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setAtras(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

         UploadFoto('atras',base);
       // window.localStorage.setItem('atr_'+cita,base);
        window.localStorage.setItem('ho4_'+cita,hora1);
      }
    });

  };
  const takePicturecf = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagencf(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setCedula(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;
  
         UploadFoto('cedula',base);
       // window.localStorage.setItem('ced_'+cita,base);
        window.localStorage.setItem('ho6_'+cita,hora1);
      }
    });

  };
  const takePicturecr = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagencr(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setCedulaR(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

        UploadFoto('cedular',base);
       // window.localStorage.setItem('cedr_'+cita,base);
        window.localStorage.setItem('ho7_'+cita,hora1);
      }
    });

  };
  const takePicturelf = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenlf(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setLicencia(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

       //// window.localStorage.setItem('lic_'+cita,base);
       UploadFoto('licencia',base);
        window.localStorage.setItem('ho8_'+cita,hora1);
      }
    });

  };
  const takePicturelr = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenlr(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setLicenciaR(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

        UploadFoto('licenciar',base);
       //window.localStorage.setItem('licr_'+cita,base);
        window.localStorage.setItem('ho9_'+cita,hora1);
      }
    });

  };

  const takePictureAd1 = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenad1(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setAdicional1(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

        UploadFoto('Adicional1',base);
       //window.localStorage.setItem('licr_'+cita,base);
        window.localStorage.setItem('ho10_'+cita,hora1);
      }
    });

  };

  const takePictureAd2 = async () => {
    const image = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    var imageUrl:any = image.webPath;
    setImagenad2(imageUrl);
    const data = await fetch(imageUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;  
        setAdicional2(base64data); 
        resolve(base64data);
        console.log(base64data);
        var base : any = base64data;

        UploadFoto('Adicional2',base);
       //window.localStorage.setItem('licr_'+cita,base);
        window.localStorage.setItem('ho11_'+cita,hora1);
      }
    });

  };

  function LimpiarStorage(){

    var cita = window.localStorage.getItem('cita');
    localStorage.removeItem('odo_'+cita);
    localStorage.removeItem('fre_'+cita);
    localStorage.removeItem('atr_'+cita);
    localStorage.removeItem('der_'+cita);
    localStorage.removeItem('izq_'+cita);
    localStorage.removeItem('ced_'+cita);
    localStorage.removeItem('cedr_'+cita);
    localStorage.removeItem('lic_'+cita);
    localStorage.removeItem('licr_'+cita);

    localStorage.removeItem('ho1_'+cita);
    localStorage.removeItem('ho2_'+cita);
    localStorage.removeItem('ho3_'+cita);
    localStorage.removeItem('ho4_'+cita);
    localStorage.removeItem('ho5_'+cita);
    localStorage.removeItem('ho6_'+cita);
    localStorage.removeItem('ho7_'+cita);
    localStorage.removeItem('ho8_'+cita);
    localStorage.removeItem('ho9_'+cita);
    setCargando('0');
    setBoton('0');
  }
  function Validar(){
    var orden = window.localStorage.getItem('cita');
    present({
      message: 'Validando...',
      duration: 4000,
      cssClass: 'custom-loading'
    })
   axios.get(uri+'appmovil/validarimagenesentrega/'+orden)
       .then(function(res) {
        if(res.data.estado == 'success'){
          setBoton("1");
        }
        
         alert(res.data.msj);
         
        
       })
       .catch(function(err) {
         //Swal.fire('Error');
         console.log(err);
       })
       .then(function() {
         //loading.style.display = 'none';
       });
  }

  function ValidarBk(){
    if (imageno==''){
       alert("Debes de tomar la foto del odometro");
       return false;
    }
    //Base1(imageno).then();

     if (imagenf==''){
        alert("Debes de tomar la foto del frente vehiculo");
          return false;
      }
      //Base2(imagenf).then();

      if (imageni==''){
        alert("Debes de tomar la foto de lado izquierdo del vehiculo");
        return false;
      }
      //Base3(imageni).then();

      if (imagenp==''){
        alert("Debes de tomar la foto del lado atras del vehiculo");
        return false;
      }
      //Base4(imagenp).then();

      if (imagend==''){
        alert("Debes de tomar la foto del lado derecho del vehiculo");
        return false;
      }
      //Base5(imagend).then();
 
 /*      if (imagencf==''){
        alert("Debes de tomar la foto de la cedula frente");
        return false;
      } */
      //Base6(imagencr).then();

     /*  if (imagencr==''){
        alert("Debes de tomar la foto de la cedula del reverso");
        return false;
      } */
      //Base7(imagenlf).then();
      
     /*  if (imagenlf==''){
        alert("Debes de tomar la foto de la licencia ");
        return false;
      } */
      //Base8(imagenlf).then();

     /*  if (imagenlr==''){
        alert("Debes de tomar la foto de la licencia del reverso");
        return false;
      } */
      //Base9(imagenlr).then(); 

    setBoton("1");
  }
  function savefoto(){
    setCargando(1);
    var orden = window.localStorage.getItem('cita');
    var nick = window.localStorage.getItem('name_u');
    var ope = window.localStorage.getItem('key');
    var lat = window.localStorage.getItem('lat');
    var lon = window.localStorage.getItem('lon');
  

    const datos = {
       Cita:orden,
       lat:lat,
       lon:lon,
       Nusuario:nick,
      };
    axios.post(uri+'appmovil/GuardarImagen', datos)
        .then(function(res) {
          alert(res.data.msj);

          if(res.data.estado =='success'){
            setResultado(res.data);
            LimpiarStorage();
            window.localStorage.removeItem('cita');
            window.location.href = "/citas";
            console.log(res.data);
          }else{
            setCargando(0);
          }
          
        })
        .catch(function(err) {
          //Swal.fire('Error');
          console.log(err);
        })
        .then(function() {
          //loading.style.display = 'none';
        });
  
  }
  
  const [present] = useIonLoading();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className='header'>
        <IonButton  className='boton' onClick={Entregas} slot="start"><IonIcon icon={arrowBack} /></IonButton>
          <IonTitle>Fotos de entrega {Placa}</IonTitle>
          <IonButton  className='boton' onClick={LimpiarStorage} slot="end"><IonIcon icon={trashSharp} /></IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >
        
        <IonContent fullscreen className='ion-padding body'>
        <GeolocationButton />
          <form id='formulario'>
     
        <IonList>
          <IonItem>
            <IonThumbnail slot="start">
            {imageno?
            <IonImg src={Imagen1==2?odometro:imageno}></IonImg>:
            <IonImg src={odometro}></IonImg>
            }
            
              
            </IonThumbnail>
            <IonLabel>
              ODOMETRO
              <br />
              {imageno?<IonText color={Imagen1==2?'danger':'success'}><sub>{Imagen1?Ho1:'Subiendo..'}</sub></IonText>:''}
              
            </IonLabel>
            
            <IonButton size="default" color={Imagen1?Imagen1==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureo()}><IonIcon icon={Imagen1?Imagen1==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagenf?
            <IonImg src={Imagen2==2?frontal:imagenf}></IonImg>:
            <IonImg src={frontal}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>FRENTE
            <br />
              {imagenf?<IonText color={Imagen2==2?'danger':'success'}><sub>{Imagen2?Ho2:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen2?Imagen2==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturef()}><IonIcon icon={Imagen2?Imagen2==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imageni?
            <IonImg src={Imagen3==2?izquierda:imageni}></IonImg>:
            <IonImg src={izquierda}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>IZQUIERDA
            <br />
              {imageni?<IonText color={Imagen3==2?'danger':'success'}><sub>{Imagen3?Ho3:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen3?Imagen3==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturei()}><IonIcon icon={Imagen3?Imagen3==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

          <IonItem>
            <IonThumbnail slot="start">
            {imagenp?
            <IonImg src={Imagen4==2?posterior:imagenp}></IonImg>:
            <IonImg src={posterior}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>ATRAS
            <br />
              {imagenp?<IonText color={Imagen4==2?'danger':'success'}><sub>{Imagen4?Ho4:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen4?Imagen4==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturep()}><IonIcon icon={Imagen4?Imagen4==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagend?
            <IonImg src={Imagen5==2?derecha:imagend}></IonImg>:
            <IonImg src={derecha}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>DERECHA
            <br />
              {imagend?<IonText color={Imagen5==2?'danger':'success'}><sub>{Imagen5?Ho5:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen5?Imagen5==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictured()}><IonIcon icon={Imagen5?Imagen5==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagencf?
            <IonImg src={Imagen6==2?cedula:imagencf}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>CEDULA FRENTE
            <br />
              {imagencf?<IonText color={Imagen6==2?'danger':'success'}><sub>{Imagen6?Ho6:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>

            <IonButton size="default" color={Imagen6?Imagen6==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturecf()}><IonIcon icon={Imagen6?Imagen6==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagencr?
            <IonImg src={Imagen7==2?cedula:imagencr}></IonImg>:
            <IonImg src={cedula}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>CEDULA REVERSO
            <br />
              {imagencr?<IonText color={Imagen7==2?'danger':'success'}><sub>{Imagen7?Ho7:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen7?Imagen7==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturecr()}><IonIcon icon={Imagen7?Imagen7==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagenlf?
            <IonImg src={Imagen8==2?licencia:imagenlf}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>LICENCIA FRENTE
            <br />
              {imagenlf?<IonText color={Imagen8==2?'danger':'success'}><sub>{Imagen8?Ho8:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen8?Imagen8==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturelf()}><IonIcon icon={Imagen8?Imagen8==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem >
            <IonThumbnail slot="start">
            {imagenlr?
            <IonImg src={Imagen9==2?licencia:imagenlr}></IonImg>:
            <IonImg src={licencia}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>LICENCIA REVERSO
            <br />
              {imagenlr?<IonText color={Imagen9==2?'danger':'success'}><sub>{Imagen9?Ho9:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen9?Imagen9==2?'danger':'success':'danger'} slot='end' onClick={()=>takePicturelr()}><IonIcon icon={Imagen9?Imagen9==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagenad1?
            <IonImg src={Imagen10==2?adicional:imagenad1}></IonImg>:
            <IonImg src={adicional}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Foto Adicional 1
            <br />
              {imagenad1?<IonText color={Imagen10==2?'danger':'success'}><sub>{Imagen10?Ho10:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
            <IonButton size="default" color={Imagen10?Imagen10==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureAd1()}><IonIcon icon={Imagen10?Imagen10==2?warning:checkmark:camera} /></IonButton>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
            {imagenad2?
            <IonImg src={Imagen11==2?adicional:imagenad2}></IonImg>:
            <IonImg src={adicional}></IonImg>
            }
            </IonThumbnail>
            <IonLabel>Foto Adicional 2
            <br />
              {imagenad2?<IonText color={Imagen11==2?'danger':'success'}><sub>{Imagen11?Ho11:'Subiendo..'}</sub></IonText>:''}
            </IonLabel>
           
            <IonButton size="default" color={Imagen11?Imagen11==2?'danger':'success':'danger'} slot='end' onClick={()=>takePictureAd2()}><IonIcon icon={Imagen11?Imagen11==2?warning:checkmark:camera} /></IonButton>
          </IonItem>

        </IonList>    
        {Nombreboton=="0"?
        <IonButton type='button'  className='header' expand="block" onClick={Validar}><IonIcon icon={checkmark} /> Validar Imagenes</IonButton>:
        Cargando=="0"?
          <IonButton type='button'  className='boton' expand="block" onClick={savefoto}><IonIcon icon={save} /> Concluir Entrega</IonButton>:
          <IonButton type='button'  className='boton' expand="block" ><IonSpinner color="light"></IonSpinner> Cargando Imagenes...</IonButton>
        }
     
        </form>      
       
         
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Home;
