import { IonContent,useIonAlert, IonHeader, IonPage,IonFooter, IonTitle, IonToolbar,IonButton,IonImg,IonItem,IonLabel,IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon,IonRippleEffect, useIonViewWillEnter, IonCol, IonRow, IonModal, IonList, IonTextarea, IonAvatar, IonButtons, IonSkeletonText, useIonLoading, IonSpinner  } from '@ionic/react';
import './Detalle.css';
import { useState,useRef } from 'react';
import { add, calendar, eye, search,people,document, home, person, homeOutline, information, informationCircle, carOutline, timeOutline, todayOutline, informationCircleOutline, playBackCircle, arrowForward, arrowBack, personCircle, car, speedometer, trash } from 'ionicons/icons';
import axios from 'axios';
import Modal from './Modal';
import Conexio from '../services/Index';

const Home: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentAlert] = useIonAlert();
  let cita = window.localStorage.getItem("cita");
  const [ncita, seCita] = useState(cita);
  const [isiniestro, setSin] = useState("");
  const [iaseguradora, setAseg] = useState("");
  const [iasegurado, setAse] = useState("");
  const [ideclarante, setDec] = useState("");
  const [iplaca, setPla] = useState("");
  const [ioficina, setOfi] = useState("");
  const [ivehiculo, setVeh] = useState("");
  const [iagendado, setAge] = useState("");
  const [idias, setDia] = useState("");
  const [isin, setIdSin] = useState("");
  const [ioperario, setOperario] = useState("");
  const [NombreOperario, setNameOperario] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [Cargando, setCargando] = useState<any>("0");

  const [kmi, setKmi] = useState("");
  const [tpq, setTpq] = useState("0");
  const [kmd, setKmd] = useState("0");
  const [Observaciones, setObskm] = useState("");

  const [flota, setFlota] = useState("");
  const [Ukm, setUltKm] = useState("");
  const [Obs, setObs] = useState("");
  const [Estado, setEstado] = useState("");
  const [Domicilio, setDomicilio] = useState("");
  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  const [Ubicacion, setUbicacion] = useState('');
  const [UbicacionKi, setUbicacionKi] = useState('');
  const [UbicacionKf, setUbicacionKf] = useState('');

  const [kmd1, setKmd1] = useState("0");
  const [kmd2, setKmd2] = useState("0");


  var tk = window.localStorage.getItem('key_t');
  let uri = Conexio;
  //let uri = 'http://127.0.0.1:8000/api/';
  //http://127.0.0.1:8000 

  
  function dismiss() {
    modal.current?.dismiss();
  }


  async function Entregas() {
      window.location.href = '/citas';
  }
  async function TomarFotos() {
    window.location.href = '/foto';
}
function DetalleCita(){
  var orden = window.localStorage.getItem('cita');
  var token = window.localStorage.getItem('key_t');
  axios({
    method: "get",
    url: uri + "appmovil/ver/"+orden,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
         let d = res.data.citas;
         let v = res.data.vehiculo;
         let u = res.data.ubicacion;
          //console.log(res.data);
          setUbicacion(u.estado);
          setUbicacionKi(u.odometro_final);
          setUbicacionKf(u.odometro_inicial);

          setSin(d.sini); 
          setAse(d.asegurado_nombre); 
          setDec(d.declarante_nombre); 
          setPla(d.placa); 
          setOfi(d.oficina); 
          setVeh(d.placaasig); 
          setAge(d.agendada_por); 
          setDia(d.dias_servicio); 
          setFlota(d.flota); 
          setUltKm(v.ultimokm); 
          setObs(d.obs_devolucion); 
          setNameOperario(d.nombreoperario)
          setOperario(d.operario_domicilio)
          setEstado(d.estadocita)
          setDomicilio(d.dir_domicilio)
          window.localStorage.setItem('placa',d.placaasig);
         // document.getElementById("obs").innerHTML = d.obs_devolucion;
          
          var asi = d.operario_domicilio;
          var est = d.estadocita;

          var btn = '<button class="btn btn-primary btn-block" onclick="asignar()">Asignar Operacio</button>';
          if(asi == 0){
            //document.getElementById("boton_asignar").innerHTML = btn;
          }else{
            var btn2 = 'La cita de entrega esta concluida <br><a href="" class="btn btn-danger btn-block">Toma de Imagenes</a>';
            if(est == 'P'){
              var btn2 = '<button class="btn btn-info btn-block"   data-toggle="modal" data-target="#modal-km" role="button">Registrar Observaciones</button>';
            }
           
            //document.getElementById("boton_asignar").innerHTML ='<div class="text-center">Operario:'+ d.nombreoperario+' <br>'+btn2+'</div>';
          }
          var dom = d.dir_domicilio;
          if(dom!=''){
            //document.getElementById('desplazamiento').style.display = 'block';
          }
          
        }
      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });
}
async function Principal() {
  window.location.href = '/home';
}
async function Entrega() {
  window.localStorage.setItem('acta','ENTREGA');
  window.localStorage.setItem('odo',Ukm);
  window.location.href = '/conductor';

}
function Asignar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  loading();
  axios({
    method: "get",
    url: uri + "appmovil/asignar/"+orden+"/"+operario,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.Msj,
            buttons: ['OK'],
          })
         
          window.localStorage.setItem('acta','ENTREGA');
          window.localStorage.setItem('odo',Ukm);
          if(Domicilio==''){
            window.location.href = '/conductor';
          }else{
            DetalleCita();
          }
          //
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function Quitar(){

  var orden = window.localStorage.getItem('cita');
  var operario = window.localStorage.getItem('key');
  var token = window.localStorage.getItem('key_t');
  loading();
  axios({
    method: "get",
    url: uri + "appmovil/desasignar/"+orden+"/"+operario,
    headers: { Authorization: token }
  })
      .then(function(res) {
        if(res.status==200) {
          console.log(res.data); 
          presentAlert({
            header: 'Exito',
            message: res.data.Msj,
            buttons: ['OK'],
          })
          DetalleCita();
        }

      })
      .catch(function(err) {
        console.log(err);
      })
      .then(function() {
      });

}
function save_registro(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');
  var lat = window.localStorage.getItem('lat');
  var lon = window.localStorage.getItem('lon');

  if(kmi==''){
    alert("Debes de digitar el kilimetraje inicial");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmi)) {
     alert("Debe escribir un kilometraje inicial valido igual o mayor que el ultimo registrado. No puede ser menor ni mayor que 2 kilometros mas del ultimo registrado en la tabla de control.");
     return false;
  }
  if(Observaciones==''){
    alert("Debes de escribir alguna observacion ");
    return false;
  }
  //loading();
  setCargando(1);
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd,tpq:tpq,observaciones:Observaciones,kilometro:Ukm ,lat:lat,lon:lon};
  axios.post(uri+'appmovil/saveentrega', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Datos registrado',
          message: res.data.msj,
          buttons: ['OK'],
        })
        //DetalleCita();
        window.location.href = '/foto';
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
// agregar domicilio inicial
function save_registro_1(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');
  var lat = window.localStorage.getItem('lat');
  var lon = window.localStorage.getItem('lon');

  if(kmd1==''){
    alert("Debes de digitar el kilimetraje inicial");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmd1)) {
    alert("Debe escribir un kilometraje inicial valido igual o mayor que "+Ukm+". ");
     return false;
  }
  
  loading();
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd1,tpq:tpq,observaciones:Observaciones,kilometro:Ukm ,lat:lat,lon:lon};
  axios.post(uri+'appmovil/saveentrega_1', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Kilometraje Inicial Domicilio',
          message: res.data.msj,
          buttons: ['OK'],
        })
        //DetalleCita();
        window.location.href = '/detalle';
        //window.location = 'atenciones.html';
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
// agregar domicilio inicial
function save_registro_2(){

  var orden = window.localStorage.getItem('cita');
  var nick = window.localStorage.getItem('name_u');
  var ope = window.localStorage.getItem('key');
  var lat = window.localStorage.getItem('lat');
  var lon = window.localStorage.getItem('lon');
  console.log(kmd2);
  if(kmd2==''){
    alert("Debes de digitar el kilOmetraje inicial");
    return false;
  }
  if(parseInt(Ukm) > parseInt(kmd2)) {
     alert("Debe escribir el kilometraje final valido igual o mayor que "+Ukm+". ");
     return false;
  }
  
  loading();
  const datos = { idcita: orden, nick : nick, user:nick, kmi:kmi,kmd:kmd2,tpq:tpq,observaciones:Observaciones,kilometro:Ukm ,lat:lat,lon:lon};
  axios.post(uri+'appmovil/saveentrega_2', datos)
      .then(function(res) {
        //alert(res.data.msj);
        console.log(res.data);
        //Swal.fire(res.data.msj, '', 'success')
        presentAlert({
          header: 'Kilometraje Final Domicilio',
          message: res.data.msj,
          buttons: ['OK'],
        })
        //DetalleCita();
        window.location.href = '/conductor';
       
      })
      .catch(function(err) {
        //Swal.fire('Error');
        console.log(err);
      })
      .then(function() {
        //loading.style.display = 'none';
      });

}
function Salir() {
  localStorage.clear();
  window.location.href = "/";
}
function Listaentrega() {
  window.location.href = "/citas";
}
async function bogota() {
  window.location.href = "https://www.ventanillamovilidad.com.co/tarifas";
}
async function Perfil() {
  window.location.href = "/perfil";
}
async function funza() {
  window.location.href = "https://emtra.com.co/tarifas-transito-funza/";
}
function loading(){
  present({
    message: 'Enviando...',
    duration: 5000,
    cssClass: 'custom-loading'
  })
}
useIonViewWillEnter(() => {
  DetalleCita();

  if (!tk) {
    window.location.href = "/";
  }
  
});
const [present] = useIonLoading();
  return (
    <IonPage>
     
     <IonContent fullscreen className="ion-padding fondohome">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container-fluid">
           
              <IonImg
                className="rounded-pill"
                onClick={Entregas}
                src={require("../assets/images/backwhite.png")}
              ></IonImg>
              
           
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Listaentrega}>
                    Lista de entregas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={Perfil}>
                    Editar Perfil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={funza}>
                    Consultar Tarifa de Funza
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)" onClick={bogota}>
                    Consultar Tarifa Bogota
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={Salir} href="javascript:void(0)">
                    Salir
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <br></br>
        <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/info.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b> Cita Entrega. No.{ncita}</b>
                
                </div>
                <div className="toast-body  text-dark">
                  <IonRow >
                    <IonCol><IonIcon icon={informationCircle} /><b> Siniestro Numero</b></IonCol>
                    <IonCol>{isiniestro?isiniestro:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={homeOutline} /> <b> Aseguradora</b></IonCol>
                    <IonCol>{flota?flota:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={person} /> <b>Nombre Asegurado</b></IonCol>
                    <IonCol>{iasegurado?iasegurado:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={person} /> <b>Nombre Declarante</b></IonCol>
                    <IonCol>{ideclarante?ideclarante:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol><IonIcon icon={carOutline} /><b> Placa Siniestrada</b></IonCol>
                    <IonCol>{iplaca?iplaca:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                  </IonRow>
                </div>
              </div>
            </div>
       
          </div>
          <br />
          <div className="card">
            <div className="">
              <div className="">
                <div className="card-header">
                <img
                src={require("../assets/images/info.png")}
                style={{width: 50}}
                alt="Avatar"
              />
                <b>INFORMACION DE LA CITA 
                  </b>
                
                </div>
                <div className="toast-body  text-dark">
                    <IonRow>
                      <IonCol><IonIcon icon={homeOutline} /><b> Oficina</b></IonCol>
                      <IonCol>{ioficina?ioficina:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={carOutline} /><b> Vehiculo a entregar</b></IonCol>
                      <IonCol>{ivehiculo?ivehiculo:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={person} /><b> Agendada por</b></IonCol>
                      <IonCol>{iagendado?iagendado:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={todayOutline} /><b> Dias de servicio</b></IonCol>
                      <IonCol>{idias?idias:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol><IonIcon icon={speedometer} /><b>Ultimo Km </b></IonCol>
                      <IonCol>{Ukm?Ukm:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>
                    {Domicilio!=''?
                    <IonRow>
                      <IonCol><IonIcon icon={speedometer} /><b>Domiciilio </b></IonCol>
                      <IonCol>{Domicilio?Domicilio:<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonCol>
                    </IonRow>:''
                    }

{
            
            ioperario!="0"?
            <IonLabel  color="danger"><IonIcon icon={person} />OPERARIO ASIGNADO: {NombreOperario ?<>
           
            {NombreOperario}
            <IonButton className='header' onClick={() =>
          presentAlert({
            header: 'Estas seguro de desasignar el operario?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'alert-button-cancel',
                handler: () => {
                  setHandlerMessage('Proceso cancelado');
                },
              },
              {
                text: 'Desligar',
                role: 'confirm',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  setHandlerMessage('Operario desasignado');
                  Quitar();
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
          })}>
        <IonIcon slot="start" icon={trash}></IonIcon>
        Quitar Operario
      </IonButton>
          
      
            
            
          </> :<IonSkeletonText animated={true} style={{ 'width': '80%' }}></IonSkeletonText>}</IonLabel>
            
            :
                <IonButton expand='block' className='header' onClick={() =>
          presentAlert({
            header: 'Estas seguro de asignar el operario?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'alert-button-cancel',
                handler: () => {
                  setHandlerMessage('Proceso cancelado');
                },
              },
              {
                text: 'Asignar',
                role: 'confirm',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  setHandlerMessage('Operario asignado');
                  Asignar();
                },
              },
            ],
            onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
          })} ><IonIcon icon={person} />  Asignar usuario </IonButton>}

         {
          Estado=="P"?
              ioperario!="0"?''
                          :'':isiniestro?<IonButton id="open-fotos" expand="block" className='header' onClick={TomarFotos}> Tomar fotos</IonButton>:''}
        
      
      {Estado=="P"?
      ioperario!="0"?
      <>
      
        {Domicilio!=''?
                        Ubicacion=='2'?
                                <>
                                    <IonItem>
                                      <IonLabel position="stacked">Km inicial del domicilio:</IonLabel>
                                      <IonInput  placeholder="KM" value={kmd1} onIonChange={(e) => setKmd1(e.detail.value!)}/>
                                  </IonItem>
                                    <IonButton id="savekm" expand="block" className='header' onClick={save_registro_1}>
                                      Guardar Kilometraje.
                                  </IonButton>
                                </>:
                                Ubicacion=='96'?
                                UbicacionKi==UbicacionKf?
                                    <>
                                      <IonItem>
                                          <IonLabel position="stacked">Km Fin del domicilio:</IonLabel>
                                          <IonInput  placeholder="KM" value={kmd2} onIonChange={(e) => setKmd2(e.detail.value!)}/>
                                      </IonItem>
                                        <IonButton id="savekm" expand="block" className='header' onClick={save_registro_2}>
                                          Guardar Kilometraje.
                                        </IonButton>
                                    </>
                                    :
                                    <>
                                    {/* <IonButton id="open-modal" expand="block" className='header'>
                                        Kilometraje Servicio
                                    </IonButton> */}
                                      <IonButton id="savekm" expand="block" className='header' onClick={Entrega}>
                                      ENTREGA 
                                     </IonButton> 
                                  </>
                                    :<IonItem><IonTextarea color="danger">El estado del vehiculo debe estar en estado PARQUEADERO , ID: {Ubicacion}</IonTextarea></IonItem>

                          :
                       <>   
                       {Ubicacion=='2'?
                       <>
                         {/*  <IonButton id="open-modal" expand="block" className='header'>
                                  Agregar Kilometraje 2
                            </IonButton> */}
                            <IonButton id="savekm" expand="block" className='header' onClick={Entrega}>
                              ENTREGA
                          </IonButton>
                       </>
                         :<IonItem><IonTextarea  color="danger" >El estado del vehiculo debe estar en estado PARQUEADERO , ID: {Ubicacion}</IonTextarea></IonItem>}
                        
                    </>
         
        } 
                                 
        <IonModal id="example-modal" ref={modal} trigger="open-modal">
          <IonContent>
            <IonToolbar>
              <IonTitle>Agregar Kilometraje {Domicilio}</IonTitle>
              <IonButtons slot="end">
                <IonButton color="light" onClick={() => dismiss()}>
                  x
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonList>
            <IonItem>
          <IonLabel position="stacked">Kilometraje inicial del Servicio:</IonLabel>
          <IonInput  placeholder="KM" value={kmi} onIonChange={(e) => setKmi(e.detail.value!)}/>
        </IonItem>
{/*         {Domicilio==''?'':
        <IonItem>
          <IonLabel position="stacked">Kilometraje previo al desplazamiento del domicilio:</IonLabel>
          <IonInput  placeholder="KM" value={kmd} onIonChange={(e) => setKmd(e.detail.value!)}/>
        </IonItem>
        }
        {Domicilio==''?'':
        <IonItem>
          <IonLabel position="stacked">Transito en parqueadero:</IonLabel>
          <IonInput  placeholder="KM" value={tpq} onIonChange={(e) => setTpq(e.detail.value!)}/>
        </IonItem>
        } */}
        <IonItem>
          <IonLabel position="stacked">observaciones:</IonLabel>
          <IonTextarea
              placeholder="Digite alguna observacion"
               autoGrow={true}
               value={Observaciones} onIonChange={(e) => setObskm(e.detail.value!)}
        ></IonTextarea>
        </IonItem>
        {Cargando=="0"?
        <IonButton id="savekm" expand="block" className='header' onClick={save_registro}>
          Guardar Kilometraje.
        </IonButton>:<IonButton expand="block" className='color' ><IonSpinner color="light"></IonSpinner> Guardando.. </IonButton>}
      {/*   <IonButton id="savekm" expand="block" className='header' onClick={loading}>
          test
        </IonButton> */}
            </IonList>
          </IonContent>
        </IonModal>
        </>:"":""}
        <p>{handlerMessage}</p>
                </div>
              </div>
            </div>
       
          </div>
  
      </IonContent>
       <IonFooter className="ion-text-center">
       
        <IonButton  className='boton' onClick={Principal} ><IonIcon icon={home} /></IonButton>
     
      </IonFooter>
     

    </IonPage>
  );
};

export default Home;
