import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonNote, IonSpinner, IonTextarea } from '@ionic/react';
import { logoApple, reload, save, trash } from 'ionicons/icons';
import axios from 'axios';
import Conexio from '../services/Index';

interface PintarCanvasProps {
  ladito: string;
  cita:string;
  foto:string;
  side:string;
}

const PintarCanvas: React.FC<PintarCanvasProps> = ({ ladito , cita,foto,side}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const isDrawing = useRef(false);
    const lastX = useRef(0);
    const lastY = useRef(0);
    let uri = Conexio;
    const [imageDataURL, setImageDataURL] = useState<string | null>(null);
    const [Croquis, setCroquis] = useState<any>(foto);
    const [CroquisTemp, setCroquisTemp] = useState<any>("");
    const [Obs_croquis, setObs_croquis] = useState<any>("");
    const [Guardado, setGuardado] = useState<any>("danger");
    const [Cargandof, setCargandof] = useState<any>("0");
    const [EstadoObs, setEstadoObs] = useState<any>("0");
    
      const PintarCanvas = (LL:String) =>{
        const canvas = canvasRef.current;
     
        console.log(':::::::::::::::::'+LL+'::::::::::::::::::::::');
        //console.log(Croquis1);
        if (canvas) {
          const ctx = canvas.getContext('2d');
    
          if (ctx) {
              
            // Ajustamos el tamaño del canvas al tamaño de la pantalla
            const img = new Image();

            img.src = Croquis;
           
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
            }; 
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
    
            // Agregamos eventos de ratón y eventos táctiles
            canvas.addEventListener('mousedown', startDrawing);
            canvas.addEventListener('mousemove', draw);
            canvas.addEventListener('mouseup', stopDrawing);
            canvas.addEventListener('mouseout', stopDrawing);
    
            canvas.addEventListener('touchstart', startDrawing);
            canvas.addEventListener('touchmove', draw);
            canvas.addEventListener('touchend', stopDrawing);
          }
        }
    
        return () => {
          if (canvas) {
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseout', stopDrawing);
    
            canvas.removeEventListener('touchstart', startDrawing);
            canvas.removeEventListener('touchmove', draw);
            canvas.removeEventListener('touchend', stopDrawing);
          }
        
        };
      }
      const PintarCanvasTemp = (LL:String) =>{
        const canvas = canvasRef.current;
     
        console.log(':::::::::::::::::'+LL+'::::::::::::::::::::::');
        //console.log(Croquis1);
        if (canvas) {
          const ctx = canvas.getContext('2d');
    
          if (ctx) {
              
            // Ajustamos el tamaño del canvas al tamaño de la pantalla
            const img = new Image();

            img.src = CroquisTemp;
           
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
            }; 
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
    
            // Agregamos eventos de ratón y eventos táctiles
            canvas.addEventListener('mousedown', startDrawing);
            canvas.addEventListener('mousemove', draw);
            canvas.addEventListener('mouseup', stopDrawing);
            canvas.addEventListener('mouseout', stopDrawing);
    
            canvas.addEventListener('touchstart', startDrawing);
            canvas.addEventListener('touchmove', draw);
            canvas.addEventListener('touchend', stopDrawing);
          }
        }
    
        return () => {
          if (canvas) {
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseout', stopDrawing);
    
            canvas.removeEventListener('touchstart', startDrawing);
            canvas.removeEventListener('touchmove', draw);
            canvas.removeEventListener('touchend', stopDrawing);
          }
        
        };
      }
      const startDrawing = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        isDrawing.current = true;
    
        const [x, y] = getCoordinates(e);
        
        [lastX.current, lastY.current] = [x, y];
      };
    
      const draw = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        if (!isDrawing.current) return;
    
        const canvas = canvasRef.current;
        if (!canvas) return;
    
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        var acta = window.localStorage.getItem('acta');
        ctx.strokeStyle = acta=='ENTREGA'?'blue':'red'; // Color de la línea
        ctx.lineWidth = 3;       // Grosor de la línea
        ctx.lineCap = 'round';   // Extremo redondeado de la línea
    
        const [x, y] = getCoordinates(e);
    
        ctx.beginPath();
        ctx.moveTo(lastX.current, lastY.current);
        ctx.lineTo(x, y);
        ctx.stroke();
    
        [lastX.current, lastY.current] = [x, y];
      };
    
      const stopDrawing = () => {
        isDrawing.current = false;
        // Obtener la imagen en base64 cuando se detiene el dibujo
        const canvas = canvasRef.current;
        if (canvas) {
          const imageDataURL = canvas.toDataURL('image/png');
          setImageDataURL(imageDataURL);
          console.log(imageDataURL);
          GuardarImgCroquis(imageDataURL);
        }
      };
    
      const getCoordinates = (e: MouseEvent | TouchEvent): [number, number] => {
        let x = 0;
        let y = 0;
    
        const canvas = canvasRef.current;
        if (!canvas) return [x, y];
    
        const rect = canvas.getBoundingClientRect();
    
        if (e instanceof MouseEvent) {
          x = e.clientX - rect.left;
          y = e.clientY - rect.top;
        } else if (e instanceof TouchEvent) {
          x = e.touches[0].clientX - rect.left;
          y = e.touches[0].clientY - rect.top;
        }
    
        return [x, y];
      };
      const clearCanvas = () => {
        setCroquisTemp("");
        setObs_croquis("");
        BorrarCroquis();
        
      };
      const GuardarImgCroquis = (base:string) => {
        var acta = window.localStorage.getItem('acta');
   
        /* if(Obs_croquis==""){
          alert("Debes de digitar alguna observacion para la novedad del croquis");
          return false;
        } */
        if(CroquisTemp==base){
           return false;
        }
        const datos = { cita: cita, campo : side, imagen:base,acta:acta, obs:Obs_croquis };
        setCargandof(1);
        console.log(datos);
        axios.post(uri+'appmovil/GuardarImagenActa', datos)
            .then(function(res) {
  
                    setGuardado("success");
                    setCroquisTemp(imageDataURL);
                    setCargandof(0);
                    if(Obs_croquis==''){
                      setEstadoObs(1);
                    }else{
                      setEstadoObs(2);
                    }
            })
            .catch(function(err) {
              console.log(err);
              setCargandof(0);
              setGuardado("warning");
            })
            .then(function() {
              //Swal.close();
            }); 
      }
      const GuardarCroquis = () => {
        var acta = window.localStorage.getItem('acta');
        var img = imageDataURL;
        /* if(Obs_croquis==""){
          alert("Debes de digitar alguna observacion para la novedad del croquis");
          return false;
        } */
        setEstadoObs(0);
        const datos = { cita: cita, campo : side, imagen:imageDataURL,acta:acta, obs:Obs_croquis };
        setCargandof(1);
        console.log(datos);
        axios.post(uri+'appmovil/GuardarImagenActa', datos)
            .then(function(res) {
  
                    setGuardado("success");
                    setCroquisTemp(imageDataURL);
                    setCargandof(0);
                    if(Obs_croquis==''){
                      setEstadoObs(1);
                    }else{
                      setEstadoObs(2);
                    }
            
            })
            .catch(function(err) {
              console.log(err);
              setCargandof(0);
              setGuardado("warning");
            })
            .then(function() {
              //Swal.close();
            }); 
      }
      const BorrarCroquis = () => {
        var acta = window.localStorage.getItem('acta');
        var img = "";
        setCroquisTemp("");
        const datos = { cita: cita, campo : side, imagen:img,acta:acta, obs:'' };
        console.log(datos);
        axios.post(uri+'appmovil/LimpiarImagenActa', datos)
            .then(function(res) {
                    PintarCanvas(ladito);
                    setGuardado("danger");
                    setEstadoObs(0);
            
            })
            .catch(function(err) {
              console.log(err);
            })
            .then(function() {
              //Swal.close();
            }); 
      }
      function validar(){
        console.log('datos pasados');
        if(imageDataURL!=null){
          GuardarCroquis();
        }
      }
      async function handleTextareaBlur() {

        if(imageDataURL!=null){
          GuardarCroquis();
        }
    
      }
      useEffect(() => {
        PintarCanvas(ladito);
        setCroquis(foto);
      }, []);
  return (
    <div>

    <IonItem>
                  <canvas ref={canvasRef}></canvas>
             </IonItem>
             <IonTextarea style={{ border: '1px solid #ccc', color:'black' }}
                placeholder="Digite alguna observacion del croquis"
                className="form-control"
                autoGrow={true}
                value={Obs_croquis}
                onIonChange={(e) => setObs_croquis(e.detail.value!)}
                onBlur={handleTextareaBlur}

          ></IonTextarea>
          {EstadoObs!=0?
          <>
          <div style={{'color':EstadoObs==1?'red':'green','fontSize':'12px'}}>{EstadoObs==1?'Debes de digitar alguna observacion':'Croquis completado'}</div>
          </>:
          ''}
           
             <IonButton size="small" color='danger' onClick={clearCanvas}>
                      <IonIcon slot="icon-only"  ios={logoApple} md={trash}></IonIcon>
                   </IonButton>
                   <IonButton size="small" color={Guardado}  onClick={validar}>
                     {Cargandof==0?
                      <IonIcon slot="icon-only" ios={logoApple} md={save} ></IonIcon>
                      :
                      <><IonSpinner color="light"></IonSpinner>Cargando ...</>}
                   </IonButton>
                   <IonButton size="small"  color="dark" onClick={ CroquisTemp==""?() => PintarCanvas(ladito):() => PintarCanvasTemp(ladito)}>
                    
                      <IonIcon slot="icon-only" ios={logoApple} md={reload}></IonIcon>
                   </IonButton>
                   
                  
                   {/* <IonNote color="medium" class="ion-margin-horizontal">
                       NOTA: antes de pasar a otro lado debes de guardar el croquis
                  </IonNote> */}
    </div>
    
  );
}

export default PintarCanvas;